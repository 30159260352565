type DetailPeriodOptions = {
  evaluations: {
    eval_name: string,
    class_nbr: number,
    student_grade: string,
    order: number
  }[],
  scheludes: Array<{
    class_nbr: number,
    summary: Array<{
      day_name: string,
      time_start: string,
      time_end: string,
      facility_id: string,
      address: string,
    }>
  }>
}

export default class DetailPeriod {
  evaluations: {
    eval_name: string,
    class_nbr: number,
    student_grade: string,
    order: number
  }[]
  scheludes: Array<{
    class_nbr: number,
    summary: Array<{
      day_name: string,
      time_start: string,
      time_end: string,
      facility_id: string,
      address: string,
    }>
  }>

  constructor(options: DetailPeriodOptions) {
    this.evaluations = options.evaluations
    this.scheludes = options.scheludes
  }

  get_evaluations_by_class_nbr(class_nbr: number) {
    return this.evaluations.filter(ev => ev.class_nbr === class_nbr)
  }

  get_summary_scheludes_by_class_nbr(class_nbr: number) {
    const scheludes = this.scheludes.filter(sch => sch.class_nbr === class_nbr)
    const summaries: {
      day_name: string,
      time_start: string,
      time_end: string,
      facility_id: string,
      address: string,
    }[] = []
    scheludes.forEach(sch => {
      sch.summary.forEach(sum => summaries.push(sum))
    })
    return summaries
  }
}
// @flow
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#2a3247",
    height: "80px",
    color: theme.palette.common.white,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#363f54",
    "&:before": {
      content: "''",
      display: "block",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: 5,
    },
  },
}));

export type NavbarItemProps = {
  active?: boolean;
  label: string;
  icon: ReactNode;
  to: string;
};

export default function NavbarItem({
  active = false,
  label,
  icon,
  to,
}: NavbarItemProps) {
  const classes = useStyles({ active });
  return (
    <Tooltip title={label} placement="right" arrow>
      <ButtonBase
        className={classes.wrapper}
        activeClassName={classes.active}
        to={to}
        component={NavLink}
      >
        {icon}
      </ButtonBase>
    </Tooltip>
  );
}

import React, { ReactNode, useEffect, useState, useMemo } from "react";
import Session from "_domain/session/entities/SessionEntity";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Table, { TableProps } from "components/Table";

import { motion } from "framer-motion";
import useCases from "_domain";
import CampaignChannel from "_domain/campaign/entities/CampaignChannel";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { CheckBox } from "@material-ui/icons";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    contentGeneral: {
      alignContent: "center",
      width: "85%"
    },
    content: {
        padding: "25px 100px",
    },
    table: {
        backgroundColor: "#f9fbff",
        marginTop: 16,
        borderRadius: 8,
        boxShadow: theme.shadows[1],
        overflow: "hidden",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: 16,
      height: "100%",
    },
    selector: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridGap: 8,
      alignItems: "center",
    },
}));

export default function FormHabilitar(props: { session: Session; }) {
    const session = props.session;
    const classes = useStyles();
    const channelSelected = window.localStorage.getItem("sess_campaignmnt_canal") || "";
    const [isTrue, setIsTrue] = React.useState(false);
    const {
        register,
        control,
        watch,
        setValue,
    } = useForm({
        mode: "onChange",
    });

    
    function filterTypeOptions() {
        return session.forms.inbound.attention_type.options;
    }

    function filterTypeOptions2() {
        var array1 = ["a", "b"];

        return session.forms.inbound.channel.options;
    }
    
    function filterTypeOptionsDeshab() {
        var arrayChannelTypeAttention = [{label:""}];
        session.forms.inbound.channel.options.map((option) => {
            option.type_attention.map((typeAttention) => {
                arrayChannelTypeAttention.push({label: typeAttention});
            })
        });

        const arrayTypeAttention = session.forms.inbound.attention_type.options.filter(
            (typeAttention) => {
                return typeAttention.filter_subcategory === true;
            }
        );
        
        var encontrado = false;

        var typeAttentionFinal=[{label:"", value:"", filter_subcategory: false}];
        arrayTypeAttention.map((type) => {
            encontrado = false;
            arrayChannelTypeAttention.map((channelType) => {
                if (type.value === channelType.label) {
                    encontrado = true;
                    return type;
                }
                
            });
            if (!encontrado) {
                typeAttentionFinal.push(type);
            }
        });
        typeAttentionFinal. splice(0,1);
        return typeAttentionFinal;
    }
    
    const attention_type_options = useMemo(() => {
        const options = filterTypeOptionsDeshab();
        return options;
    }, []);
    
    async function saveChannel(channel: CampaignChannel) {
        let resultado = await useCases.save_channel.execute(channel);
        if (!resultado) {
            alert("Error registrando Channel");
        }
        return resultado;
    }

    async function fn_habilitarTypeAttention(item: { label: string; value: string; filter_subcategory: boolean }) {

        session.forms.inbound.channel.options.find(
            (ch) => {
                if (ch.value === channelSelected) {
                    ch.type_attention.push(item.value);
                }
            }
        );
        const jsonChannel = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.channel.options) + ',"multiple": false,"searchable": false}}';
        const objChannel = new CampaignChannel(JSON.parse(jsonChannel));
        const resp = await saveChannel(objChannel);
        if (resp) {
            alert("Se habilitó con éxito la campaña: " + item.label)
        }
    }

    async function fn_asignar_session(ev: any, item: { label: string; value: string; filter_subcategory: boolean }) {
        session.forms.inbound.channel.options.find(
            (ch) => {
                if (ch.value === channelSelected) {
                    if (ev) {
                        ch.type_attention.push(item.value);
                    } else {
                        ch.type_attention.splice(ch.type_attention.indexOf(item.value),1)
                    }
                }
            }
        );
    }

    async function fn_asignar_save() {
        const jsonChannel = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.channel.options) + ',"multiple": false,"searchable": false}}';
        const objChannel = new CampaignChannel(JSON.parse(jsonChannel));
        const resp = await saveChannel(objChannel);
        if (resp) {
            alert("Se asignaron las campañas seleccionadas");
        }
        
        setValue("txt_h_regresar", "1", true);
    }

    
    const registrado = watch("txt_h_regresar")
    if (registrado === "1") return <Redirect to="/dashboard/inbound/campaignMnt" />;
    
    const tableProps: TableProps = {
        data: attention_type_options.map((option) => ({"label": option.label})),
        headers: [
            {
                label: "Nombre Campaña",
                accessor: "label",
                renderCell: (index, value) => (
                    <div className={classes.selector}>
                        <input
                            name="ckb_subcategory"
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                                fn_asignar_session(e.target.checked, attention_type_options[index]);

                            }}
                        />
                        <Typography variant="body2">{value}</Typography>
                    </div>
                ),
            },
            /*{ 
                label: "Habilitar",
                renderCell: (index) => {
                    return (
                        <input
                            name="ckb_subcategory"
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                                fn_asignar_session(e.target.checked, attention_type_options[index]);

                            }}
                        />
                    );
                },
            },*/
        ],
    };

    return (
        <motion.div initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
            <div className={classes.contentGeneral}>
                <div className={classes.content}>
                    <div>
                        <Typography variant="h4" align="center" style={{ marginBottom: 24 }}> Campañas Sin Asignar </Typography>
                    </div>

                    <div className={classes.table}>

                        <div>
                            <Table data-cy="table_type_attention" {...tableProps} />
                        </div>
                    </div>

                    <div className={classes.wrapper}>
                        <Button
                            size="large"
                            id="submit_login"
                            variant="contained"
                            color="primary"
                            onClick={() => {fn_asignar_save()}}
                            >
                            Asignar
                        </Button>
                    </div>

                </div>
            </div>
            <TextField type="hidden" name="txt_h_regresar" placeholder="" inputRef={register({ required: true })} value="0" />
        </motion.div>
    );
}
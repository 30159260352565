type CampaignOptions = {
    properties: Propertie;
};

type Propertie = {
    options: Array<Option>;
    multiple: boolean;
    searchable: boolean;
}

type Option = {
    label: string,
    value: string,
    filter_subcategory: boolean,
}

export default class CampaignTypeAttention {
    properties: Propertie;
    constructor(options: CampaignOptions) {
        this.properties = options.properties;
    }
}
import Session from "../entities/SessionEntity";
import UseCase from "../../_shared/UseCase";
import SessionRepository from "../infrastructure/SessionRepository";

export default class ChangeChannelSelectedUseCase {
  sessionRepository: SessionRepository;
  constructor(sessionRepository: SessionRepository) {
    this.sessionRepository = sessionRepository;
  }
  execute(session: Session, value: string) {
    this.sessionRepository.changeChannelSelected(session, value);
  }
}

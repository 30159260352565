import React from "react"

type ErrorBoundaryProps = {
  fallbackComponent: JSX.Element;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz de repuesto
      return this.props.fallbackComponent;
    }

    return this.props.children;
  }
}
import fetcher from "_domain/_shared/fetcher";
import AlertTagRepository from "./AlertTagRepository";

export default class AlertTagRepositoryApi implements AlertTagRepository {
  async postAlerts(alertsFile: File): Promise<Boolean> {
    let formData = new FormData();
    formData.append("file", alertsFile, "prueva.csv");
    formData.append("destination", "outbound_student");
    formData.append("process", "true");

    const response = await fetcher("admin/document_upload_file/", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    });
    const data = await response.json();
    if (response.ok) {
      return true;
      //throw new Error(`Prueba error`);
    } else {
      let msgErr = "";
      for (const property in data.errors) {
        msgErr = `${msgErr} ${data.errors[property]}`;
      }
      throw new Error(`${msgErr}`);
    }
  }
}

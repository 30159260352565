import User from "../../user/entities/User";
import { FormType } from "./FormType";

type Options = {
  user: User;
  token: string;
  forms: FormType;
  channelSelected: string;
};

export default class Session {
  user: User;
  token: string;
  forms: FormType;
  channelSelected: string;

  constructor({ user, token, forms, channelSelected }: Options) {
    this.user = user;
    this.token = token;
    this.forms = forms;
    this.channelSelected = channelSelected;
  }

  get channelOptionsAvailable() {
    const options = this.forms.inbound.channel.options;
    if (this.user.channel === "presencial") {
      // solo presencial
      return options.filter((option) => option.value === "Presencial");
    }
    if (this.user.channel === "call") {
      // todo menos presencial
      return options.filter((option) => option.value !== "Presencial");
    }
    // Todos los canales
    return options;
  }

  get defaultChannel() {
    const options = this.channelOptionsAvailable;
    if (options.find((option) => option.value === this.channelSelected)) {
      return this.channelSelected;
    }
    return this.channelOptionsAvailable[0].value;
  }

  get isSupervisor() {
    return !!this.user.groups.find((g) => g === "Supervisor");
  }

  get isAgenteInbound() {
    return !!this.user.groups.find((g) => g === "Asesor-inbound");
  }
}

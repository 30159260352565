import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSession } from "hooks/use-auth";
import useCases from "_domain";
import TypificationDrawerAnonimous from "./TypificationDrawerAnonimous";
import { motion } from "framer-motion";
import ModalResponse from "components/ModalResponse";
import Session from "_domain/session/entities/SessionEntity";
import Spinner from "components/Spinner";
import CloudPet from "components/CloudPet";
import { CcIcon } from "@cafeta/components-react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    height: "100%",
  },
  content: {
    width: 500,
  },
  card: {},
  cardButton: {
    paddingTop: 16,
    textAlign: "center",
  },
}));

type FormType = {
  student_code: string;
  channel: string;
};

export default function SearchView(props: { session: Session }) {
  const { session } = props;
  const { logout } = useSession();
  const classes = useStyles();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
    reset,
    watch,
  } = useForm<FormType>({
    validateCriteriaMode: "all",
    defaultValues: { student_code: "", channel: session.defaultChannel },
  });

  const [cloudPetState, setCloudPetState] = useState<
    "waiting" | "laugh" | "smile" | "poker"
  >("waiting");

  const [openDrawer, setOpenDrawer] = useState(false);
  const [typificationStatus, setTypificationStatus] = useState<
    "success" | "error" | null
  >(null);

  const watchChannel = watch("channel");

  useEffect(() => {
    useCases.change_channel_selected.execute(session, watchChannel);
  }, [watchChannel]);

  async function searchStudent(code: string) {
    return useCases.search_student
      .execute(code)
      .then((id) => history.push(`/dashboard/inbound/student/${id}`))
      .catch((e) => {
        if (e.name === "AuthorizationError") return logout();
        setError("student_code", "notFound", e.message);
      });
  }

  async function onSubmit(data: FormType) {
    await searchStudent(data.student_code);
  }

  function onSubmitedTypificationAnonimous(status: "success" | "error") {
    if (status === "success") {
      setTypificationStatus("success");
      reset();
    }
    if (status === "error") {
      setTypificationStatus("error");
    }
    setOpenDrawer(false);
  }

  const actionOnErrorTypification = {
    label: "Intentar otra vez",
    onClick: () => {
      setTypificationStatus(null);
      setOpenDrawer(true);
    },
  };

  function resetErrorForm() {
    setCloudPetState("smile");
    reset();
  }

  return (
    <div className={classes.wrapper}>
      <Card style={{ backgroundColor: "white" }}>
        <CardContent style={{ padding: 40 }}>
          <form
            id="search_form"
            className={classes.content}
            onSubmit={handleSubmit(onSubmit)}
            onFocus={() => setCloudPetState("smile")}
            onBlur={() => setCloudPetState("waiting")}
            onChange={() => setCloudPetState("laugh")}
          >
            <div style={{ textAlign: "center", marginBottom: 40 }}>
              <CloudPet
                state={Boolean(errors.student_code) ? "poker" : cloudPetState}
              />
            </div>

            <Typography
              variant="h3"
              align="center"
              style={{ marginBottom: 24 }}
            >
              Buscar por código de alumno o DNI
            </Typography>
            <div
              id="radio_buttons_area"
              style={{
                display:
                  session.channelOptionsAvailable.length <= 1
                    ? "none"
                    : "block",
                marginBottom: 24,
              }}
            >
              <Typography variant="h4" style={{ marginBottom: 16 }}>
                ¿Que harás hoy?
              </Typography>
              <RadioGroup row defaultValue={session.defaultChannel}>
                {session.channelOptionsAvailable.map(
                  (option: { value: string; label: string }, index: number) => (
                    <FormControlLabel
                      value={option.value}
                      control={
                        <Radio
                          inputRef={register}
                          disabled={formState.isSubmitting}
                          name="channel"
                          color="primary"
                        />
                      }
                      label={option.label}
                    />
                  )
                )}
              </RadioGroup>
            </div>
            <TextField
              autoComplete="off"
              placeholder="U17213387"
              disabled={formState.isSubmitting}
              inputRef={register({
                required: { value: true, message: "Debe de ingresar un valor" },
                pattern: {
                  value: /^[a-zA-Z0-9]*$/,
                  message: "No debe de contener caracteres especiales",
                },
                minLength: {
                  value: 7,
                  message: "Ingrese un mínimo de 7 caracteres.",
                },
              })}
              name="student_code"
              error={Boolean(errors.student_code)}
              InputProps={{
                endAdornment: !Boolean(errors.student_code) ? (
                  <InputAdornment position="end">
                    <IconButton
                      data-cy="search_button"
                      disabled={formState.isSubmitting}
                      color="primary"
                      onClick={handleSubmit(onSubmit)}
                      size="small"
                    >
                      {formState.isSubmitting ? (
                        <Spinner color="disabled" />
                      ) : (
                        <CcIcon name="search" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton
                      data-cy="clear_error_button"
                      onClick={() => resetErrorForm()}
                      size="small"
                    >
                      <Close color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 9,
              }}
            />
            {errors.student_code && (
              <Typography
                id="error_validation"
                variant="body2"
                color="error"
                style={{ marginTop: 8 }}
              >
                {errors.student_code.types?.pattern ||
                  errors.student_code.types?.minLength ||
                  errors.student_code.message}
              </Typography>
            )}
            {errors.student_code?.message ===
              "El código o DNI ingresado no existe" && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
              >
                <Typography
                  id="error_2018"
                  variant="body2"
                  align="center"
                  style={{ marginTop: 16 }}
                >
                  Si se trata de un alumno matriculado antes del 2018 (AS400)
                </Typography>
                <div className={classes.cardButton}>
                  <Button
                    id="register"
                    onClick={() => setOpenDrawer(true)}
                    variant="contained"
                    color="primary"
                  >
                    Regístralo aquí
                  </Button>
                </div>
              </motion.div>
            )}
          </form>
        </CardContent>
      </Card>
      <TypificationDrawerAnonimous
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        session={session}
        onSubmited={onSubmitedTypificationAnonimous}
      />
      <ModalResponse
        variant="error"
        action={actionOnErrorTypification}
        open={typificationStatus === "error"}
        title="La atención no logró registrarse correctamente."
        onClose={() => setTypificationStatus(null)}
      />
      <ModalResponse
        variant="success"
        open={typificationStatus === "success"}
        title="La atención se registró exitosamente."
        onClose={() => setTypificationStatus(null)}
      />
    </div>
  );
}

import CampaignRepository from "./CampaignRepository";
import Campaign from "../entities/Campaign";
import CampaignSubCategory from "../entities/CampaignSubCategory";
import CampaignTypeAttention from "../entities/CampaignTypeAttention";
import CampaignChannel from "../entities/CampaignChannel";
import CampaignCategory from "../entities/CampaignCategory";
import fetcher from "_domain/_shared/fetcher";
import { features } from "process";

export default class CampaignRepositoryApi implements CampaignRepository {
    async save(options: Campaign) {
        const response = await fetcher("campaign/save/", {
            method: "POST",
            body: JSON.stringify(options),
        });

        if (!response.ok) throw new Error("Error al registrar campaña");

        const data = await response.json();

        const campaignData = data.data;
        const newCampaign = new Campaign({
            type_id: campaignData.type_id,
            name: campaignData.name,
            title: campaignData.title,
            initial_speech: campaignData.initial_speech,
            show_initial_speech: campaignData.show_initial_speech,
            final_speech: campaignData.final_speech,
            show_final_speech: campaignData.show_final_speech,
            started_at: campaignData.started_at,
            finished_at: campaignData.finished_at,
            status: campaignData.status,
            is_template: campaignData.is_template,
            url: campaignData.url,
            sections: campaignData.sections,
        });

        return newCampaign;
    }

    async saveSubcategory(subCategory: CampaignSubCategory) {
        const response = await fetcher("admin/form_field/5/", {
            method: "PATCH",
            body: JSON.stringify(subCategory),
        });

        if (response.ok) {
            return true;
        } else {
            throw new Error("Error al registrar Subcategory");
        }
    }

    async saveTypeAttention(typeAttention: CampaignTypeAttention) {
        const response = await fetcher("admin/form_field/3/", {
            method: "PATCH",
            body: JSON.stringify(typeAttention),
        });

        if (response.ok) {
            return true;
        } else {
            return false;
            //throw new Error("Error al registrar TypeAttention");
        }
    }

    async saveChannel(channel: CampaignChannel) {
        const response = await fetcher("admin/form_field/7/", {
            method: "PATCH",
            body: JSON.stringify(channel),
        });

        if (response.ok) {
            return true;
        } else {
            return false;
            //throw new Error("Error al registrar Channel");
        }
    }

    async getCategory() {
        const response = await fetcher("admin/form_field/4/");
        if (!response.ok) throw new Error("No se pudo obtener los documentos");
        let data = await response.json();
        data = data.data.properties;
        const jsonCategory = '{"properties": ' + JSON.stringify(data) + '}';
        const objCategory = new CampaignCategory(JSON.parse(jsonCategory));
        return objCategory;
    }
}
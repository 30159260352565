import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon"
import {SvgIconProps} from "@material-ui/core"
import {
  whatsapp,
  home,
  arrow_up_alt,
  arrow_down_alt,
  user_circle,
} from "./IconsPath";

export default function Icon(props: { name: string } & SvgIconProps) {
  const { name, ...rest } = props;
  let path;
  switch (name) {
    case "whatsapp":
      path = whatsapp;
      break;
    case "arrow_up_alt":
      path = arrow_up_alt;
      break;
    case "arrow_down_alt":
      path = arrow_down_alt;
      break;
    case "user_circle":
      path = user_circle;
      break;
    default:
      path = home;
  }

  return <SvgIcon {...rest}>{path}</SvgIcon>;
}

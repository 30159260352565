import StudentRepository from "../infrastructure/StudentRepository";
import { TypificationFormAnonymous } from "../entities/TypificationFormAnonymous";

export default class SendTypificationAnonymous {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(data: TypificationFormAnonymous): Promise<void> {
    await this.studentRepository.postTypificationAnonymous(data);
  }
}

import AlertTagRepository from "../infrastructure/AlertTagRepository";

export default class PostAlertUseCase {
  alertRepository: AlertTagRepository;

  constructor(alertRepository: AlertTagRepository) {
    this.alertRepository = alertRepository;
  }

  async execute(alertsFile: File) {
    return await this.alertRepository.postAlerts(alertsFile);
  }
}

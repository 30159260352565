import StudentRepository from "../infrastructure/StudentRepository";
import Student from "../entities/Student";

export default class GetStudentUseCase {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(id: string) {
    const student: Student = await this.studentRepository.getStudent(id);
    return student;
  }
}

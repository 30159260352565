import AlertRepositoryApi from "./../infrastructure/AlertRepositoryApi";
import GetSemaphoreUseCase from "./GetSemaphoreUseCase";
import GetSemaphoreEvolutionUseCase from "./GetSemaphoreEvolutionUseCase";
import GetDashboardHeadersUseCase from "./GetDashboardHeadersUseCase";

const alertRepositoryApi = new AlertRepositoryApi();

const alertDashboardUseCases = {
  getSemaphore: () => new GetSemaphoreUseCase(alertRepositoryApi),
  getSemaphoreEvolution: () =>
    new GetSemaphoreEvolutionUseCase(alertRepositoryApi),
  getDashboardHeaders: () => new GetDashboardHeadersUseCase(alertRepositoryApi),
};

export default alertDashboardUseCases;

import React, { useEffect, useState, useContext, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import useCases from "_domain";
import Student from "_domain/student/entities/Student";
import { useSession } from "hooks/use-auth";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TipificationDrawer, {
  FormData,
} from "app/inbound/visor-360/TipificationDrawer";
import PersonalInfo from "./PersonalInfo";
import Section from "components/Section";
import Documents from "./documents";
import DocumentFilter from "_domain/student/entities/DocumentFilter";
import DocumentEntity from "_domain/student/entities/Document";
import Records from "./Records";
import Typification from "_domain/student/entities/Typification";
import Alerts from "./Alerts";
import DetailedInfo from "./DetailedInfo";
import SkeletonVisor from "components/SkeletonVisor";
import { ErrorPage } from "../index";

import DashboardHeaders from "_domain/alert_dashboard/entities/DashboardHeaders";
import ReporteDesercion from "./ReporteDesercion";

const useStyles = makeStyles((theme) => ({}));

export default function Visor360() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { session } = useSession();
  const [student, setStudent] = useState<Student>();
  const [dashboard_headers, setDashboardHeaders] = useState<DashboardHeaders>();
  const [openTipification, setOpenTipification] = useState(false);
  const [openModalAlert, setOpenModalAlert] = useState(true);

  async function onSubmitTipification(data: FormData) {
    if (student)
      await useCases.send_typification.execute(student, {
        channel: data.channel,
        observations: data.observations,
        subcategory: data.subcategory?.value || "none",
        typification_type: data.attention_type,
        category: data.subcategory?.category || "none",
        result_call: data.result_call,
      });
  }

  useEffect(() => {
    if (typeof id !== "string") throw new Error("No se definió un ID correcto");
    useCases.get_student
      .execute(id)
      .then((student) => setStudent(student))
      .catch(() => {
        setError(true);
      });
  }, [id]);

  useEffect(() => {
    if (typeof id !== "string") throw new Error("No se definió un ID correcto");
    useCases.get_dashboardHeaders
      .execute(id)
      .then((headers) => setDashboardHeaders(headers))
      .catch(() => {
        setError(true);
      });
  }, [id]);

  const [documents, setDocuments] = useState<DocumentEntity[] | null>(null);
  const [documentFilters, setDocumentFilters] = useState<
    DocumentFilter[] | null
  >(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    Promise.all([
      useCases.get_documents.execute(),
      useCases.get_filters.execute(),
    ]).then(([documents, filters]) => {
      setDocuments(documents);
      setDocumentFilters(filters);
    });
  }, []);

  const [typifications, setTypifications] = useState<Typification[] | null>(
    null
  );

  useEffect(() => {
    if (typeof id !== "string") throw new Error("No se definió un ID correcto");
    useCases.get_typifications
      .execute(id, session?.user.id!)
      .then((data) => setTypifications(data));
  }, [id]);

  // TODO: Provisional hasta validad que session nunca sea null
  if (!session) return null;

  if (error) return <ErrorPage />;
  if (!student) return <SkeletonVisor />;

  return (
    <div>
      <TipificationDrawer
        session={session}
        onSubmit={onSubmitTipification}
        open={openTipification}
        tryAgain={() => setOpenTipification(true)}
        onClose={() => setOpenTipification(false)}
      />

      <Alerts
        student={student}
        openModal={openModalAlert}
        onCloseModal={() => setOpenModalAlert(false)}
      />

      <Section first>
        <div>
          {dashboard_headers && (
            <PersonalInfo
              session={session}
              student={student}
              dashboard_headers={dashboard_headers}
              onClickOpenTypification={() => setOpenTipification(true)}
              onClickShowAlert={() => setOpenModalAlert(true)}
            />
          )}
        </div>
      </Section>

      <Section>
        {dashboard_headers && (
          <ReporteDesercion
            student={student}
            dashboard_headers={dashboard_headers}
          />
        )}
      </Section>

      <Section>
        <DetailedInfo student={student} />
      </Section>

      <Divider />

      <Section>
        {documents && documentFilters && (
          <Documents
            documents={documents}
            filters={documentFilters}
            defaultCampus={student.campus.value}
            defaultGrade={student.grade.value}
          />
        )}
      </Section>
      <Section>
        <Typography style={{ marginBottom: 32 }} variant="h4">
          Historial
        </Typography>
        {typifications && <Records records={typifications} />}
      </Section>
    </div>
  );
}

type SemaphoreOptions = {
    data: Array<SemaphoreAlert>;
  };
  
  export type SemaphoreAlert = {
    id_indicator: number;
    indicator: string;
    colour: string;
    value: string;
    week: number;
  }
  
  export default class Semaphore {
    data: Array<SemaphoreAlert>;
    constructor(data: SemaphoreOptions) {
      this.data = data.data;
    }
  }
import CampaignRepository from "./../infrastructure/CampaignRepository";
import CampaignTypeAttention from "./../entities/CampaignTypeAttention";

export default class SaveTypeAttentionUseCase {

    campaignRepository: CampaignRepository;

    constructor(campaignRepository: CampaignRepository) {
        this.campaignRepository = campaignRepository;
    }

    async execute(data: CampaignTypeAttention) {
        const typeAttention = await this.campaignRepository.saveTypeAttention(data);
        return typeAttention;
    }
}
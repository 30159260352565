import { dateToDDMMAAAA } from "_domain/_shared/date";

export type FinancialItemOptions = {
  amount: number;
  description: string;
  discount: number;
  amoutn_to_pay: number;
  due_date?: Date;
  upd_date: Date | null;
  type: string;
  mora: number;
  total_amount: number;
};

export default class FinancialItem {
  amount: number;
  description: string;
  discount: number;
  amoutn_to_pay: number;
  due_date?: Date;
  upd_date: Date | null;
  type: string;
  mora: number;
  total_amount: number;
  constructor(opt: FinancialItemOptions) {
    this.amount = opt.amount;
    this.description = opt.description;
    this.discount = opt.discount;
    this.amoutn_to_pay = opt.amoutn_to_pay;
    this.due_date = opt.due_date;
    this.type = opt.type;
    this.mora = opt.mora;
    this.upd_date = opt.upd_date;
    this.total_amount = opt.total_amount;
  }

  isPrincipal() {
    return this.type.slice(-2) === "02";
  }

  get due_date_formated() {
    return this.due_date ? dateToDDMMAAAA(this.due_date) : "";
  }

  get is_pending() {
    return this.amoutn_to_pay > 0;
  }

  get object() {
    return {
      amount: "S/ " + this.amount.toFixed(2),
      description: this.description,
      discount: this.discount ? "S/ " + this.discount.toFixed(2) : "-",
      amoutn_to_pay: "S/ " + this.amoutn_to_pay.toFixed(2),
      due_date: this.due_date_formated,
      upd_date: this.upd_date ? dateToDDMMAAAA(this.upd_date) : "",
      type: this.type,
      mora: this.mora ? "S/ " + this.mora.toFixed(2) : "-",
      total_amount: "S/ " + this.total_amount.toFixed(2),
    };
  }
}

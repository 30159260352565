import React, { ReactNode, useEffect, useState, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { Checkbox, TextField } from "@material-ui/core";
import InputContainer from "components/InputContainer";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Controller } from "react-hook-form";
import Select from "@material-ui/core/Select";
import { Dialog } from "@material-ui/core";
import Session from "_domain/session/entities/SessionEntity";
import { motion } from "framer-motion";
import Table, { TableProps } from "components/Table";
import request_alert from "assets/images/request_alert.png";

import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import useCases from "_domain";

import CampaignTypeAttention, { } from "_domain/campaign/entities/CampaignTypeAttention";
import CampaignChannel, { } from "_domain/campaign/entities/CampaignChannel";
import CampaignSubCategory from "_domain/campaign/entities/CampaignSubCategory";
import CampaignCategory from "_domain/campaign/entities/CampaignCategory";
import { Label } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  contentGeneral: {
    alignContent: "center",
    width: "85%"
  },
  content: {
    padding: "25px 100px",
  },
  channelSelector: {
    display: "grid",
    gridTemplateColumns: "200px auto",
    gridGap: 8,
    alignItems: "center",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto auto auto auto",
    alignItems: "flex-end",
    gridGap: 8,
  },
  table: {
    backgroundColor: "#f9fbff",
    marginTop: 16,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    overflow: "hidden",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    height: "100%",
  },
  wrapperModal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 1,
    height: "100%",
  },
  detailHeader: {
    padding: 24,
    minWidth: 300,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "flex-start",
  },
  academic_alert: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#748e98",
    padding: "100px",
    "& img": {
      marginTop: 40,
      height: 80,
    },
  },
  form: {
    //width: "400px",
  },
  selector: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: 8,
    alignItems: "center",
  },
}));


export default function FormAttention(props: { session: Session; }) {
  const match = useRouteMatch();
  const session = props.session;
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
    reset,
    clearError,
    setValue,
    watch,
    control,
  } = useForm({
    mode: "onChange",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [openFrmSubcategory, setOpenFrmSubcategory] = useState<boolean>(false);
  var attentionTypeSelected = window.localStorage.getItem("sess_campaignmnt_type_attention");
  const operacion = window.localStorage.getItem("sess_campaignmnt_operacion") || "";
  var channelSelected = window.localStorage.getItem("sess_campaignmnt_canal") || "";

  function getCategories() {
    var options = [{label: "", value: ""}];
    useCases.get_category.execute().then((obj) => {
      obj.properties.options.map((cat) => {
        options.push({
          label: cat.label,
          value: cat.value,
        });
      });
    });
    options.splice(0,1);
    return options;
  }
  
  const categories = useMemo(() => {
    const options = getCategories();
    return options;
  }, []);

  const removeAccents = (str: any) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll(" ", "-");
  } 

  async function updateCampaign(typeAttention: CampaignTypeAttention, subCategory: CampaignSubCategory) {
    let resultado = await useCases.save_typeAttention.execute(typeAttention);

    if (!resultado) {
      alert("Error registrando TypeAttention");
    } else {
      resultado = await useCases.save_subcategory.execute(subCategory);
      if (!resultado) {
        alert("Error registrando Subcategoria");
      }
    }
    return resultado;
  }

  async function saveCampaign(typeAttention: CampaignTypeAttention, subCategory: CampaignSubCategory, channel: CampaignChannel) {
    let resultado = await useCases.save_typeAttention.execute(typeAttention);

    if (!resultado) {
      alert("Error registrando TypeAttention");
    } else {
      resultado = await useCases.save_subcategory.execute(subCategory);
      if (!resultado) {
        alert("Error registrando Subcategoria");
      } else {
        resultado = await useCases.save_channel.execute(channel);

        if (!resultado) {
          alert("Error registrando Channel");
        }
      }
    }
    return resultado;
  }

  function isExisteNombre(valueAttention: String) {
    const existente = session.forms.inbound.attention_type.options.find(
      (ta) => ta.value === valueAttention
    );
    if (existente === undefined) {
      return false;
    } else {
      return true;
    }
  }

  async function update(data: any) {
    const valueAttention = removeAccents(data.labelAttention);

    const isExiste = isExisteNombre(valueAttention);

    if (isExiste && !(attentionTypeSelected === valueAttention)) {
      alert("el nombre: " + data.labelAttention + " ya existe");
    } else {

      session.forms.inbound.attention_type.options.find(
        (at) => {
          if(at.value === attentionTypeSelected) {
            at.label = data.labelAttention;
          }
        }
      );

      //-- JSON para registrar TypeAttention
      const jsonTypeAttention = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.attention_type.options) + ',"multiple": false,"searchable": false}}';
      const objTypeAttention = new CampaignTypeAttention(JSON.parse(jsonTypeAttention));

      //-- JSON para registrar SubCategory
      const jsonSubcategory = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.subcategory.options) + ',"multiple": false,"searchable": true}}';
      const objSubcategory = new CampaignSubCategory(JSON.parse(jsonSubcategory));

      const resp = await updateCampaign(objTypeAttention, objSubcategory);
      if (resp) {
        alert("se registro la campaña con exito");
        setValue("registrado", "1", true);
      }
    }
  }

  async function save(data: any) {
    const valueAttention = removeAccents(data.labelAttention);

    const isExiste = isExisteNombre(valueAttention);
    if (isExiste) {
      alert("el nombre: " + data.labelAttention + " ya existe");
    } else {

      var filter_subcat = false;
      if (channelSelected === "whatsapp-out") {
        filter_subcat = true;
      }

      session.forms.inbound.attention_type.options.push({
        label: data.labelAttention,
        value: valueAttention,
        filter_subcategory: filter_subcat,
      });


      const channel = session.forms.inbound.channel.options.find(
        (c) => c.value === channelSelected
      );
      channel?.type_attention.push(valueAttention);

      //-- JSON para registrar TypeAttention
      const jsonTypeAttention = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.attention_type.options) + ',"multiple": false,"searchable": false}}';
      const objTypeAttention = new CampaignTypeAttention(JSON.parse(jsonTypeAttention));

      //-- JSON para registrar SubCategory
      const jsonSubcategory = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.subcategory.options) + ',"multiple": false,"searchable": true}}';
      const objSubcategory = new CampaignSubCategory(JSON.parse(jsonSubcategory));

      //-- JSON para registrar Channel
      const jsonChannel = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.channel.options) + ',"multiple": false,"searchable": false}}';
      const objChannel = new CampaignChannel(JSON.parse(jsonChannel));

      const resp = await saveCampaign(objTypeAttention, objSubcategory, objChannel);
      if (resp) {
        alert("se registro la campaña con exito");
        setValue("registrado", "1", true);
      }
    }
  }

  async function onSubmit(data: any) {
    if (operacion === 'C') {
      save(data);
    }
    if (operacion === 'U') {
      update(data);
    }
  }

  function renderOptions(
    options: Array<{ label: string; value: string }>,
    placeholder?: string
  ) {
    return options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }


  const category_options = useMemo(() => {
    const options = [
      { value: 'contacto-efectivo', label: 'Contacto Efectivo' },
      { value: 'contacto-no-efectivo', label: 'Contacto No Efectivo' },
      { value: 'no-contactado', label: 'No Contactado' }
    ]
    return options;
  }, []);

  
  function getLabelTypeAttention() {
    return session.forms.inbound.attention_type.options.find((ta) =>
      ta.value === attentionTypeSelected
    )?.label;
  }

  const channelSelectedForm = watch("channel") || channelSelected;
  const subcategory_options = useMemo(() => {
    if (channelSelectedForm === "whatsapp-out") {
      return session.forms.inbound.subcategory.options.filter(
        (subcat) => subcat.type_attention === attentionTypeSelected
      );
    } else {
      return session.forms.inbound.subcategory.options.filter(
        (subcat) => !subcat.type_attention
      );
    }
  }, [channelSelectedForm]);


  async function fn_eliminarSubCategory(evt: any, item: { label: string; value: string; category: string; type_attention: string | undefined }) {
    if (evt) {
      session.forms.inbound.subcategory.options.splice(session.forms.inbound.subcategory.options.indexOf(item),1);
    } else {
      session.forms.inbound.subcategory.options.push(item);
    }
  }

  async function fn_eliminar() {
    var resp = window.confirm("está seguro que quiere eliminar este Motivo de Atención");
    if (resp) {
      const jsonSubcategory = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.subcategory.options) + ',"multiple": false,"searchable": true}}';
      const objSubcategory = new CampaignSubCategory(JSON.parse(jsonSubcategory));
      resp = await useCases.save_subcategory.execute(objSubcategory);
      if (!resp) {
         alert("Error al eliminar Motivo de Atención");
      }
    }
    
    window.localStorage.setItem("sess_campaignmnt_canal_temp", channelSelected);
    setValue("channel", "otro", true);
  }

  if (!(window.localStorage.getItem("sess_campaignmnt_canal_temp") === null)) {
      setValue("channel", window.localStorage.getItem("sess_campaignmnt_canal_temp"), true);
      window.localStorage.removeItem("sess_campaignmnt_canal_temp");
  }

  const tableProps: TableProps = {
    data: subcategory_options.map((option) => ({ "label": option.label, "category": option.category })),
    headers: [
      {
        label: "Motivo de atencion",
        accessor: "label",
        renderCell: (index, value) => (
          <div className={classes.selector}>
            <input
                name="ckb_eliminar"
                type="checkbox"
                key={index}
                defaultChecked={false}
                onChange={(e) => {
                  fn_eliminarSubCategory(e.target.checked, subcategory_options[index]);

                }}
            />
            <Typography variant="body2">{value}</Typography>
          </div>
        ),
      },
      //{ label: "Motivo de atencion", accessor: "label" },
      { label: "Tipo de Contacto", accessor: "category" },
    ],
  };

  function renderTable(tableProps: TableProps) {
    return (
      <Table data-cy="table_subcategory" maxHeight={500} {...tableProps} />
    );
  }

  async function validarValueSubCats(arrayLabels: Array<string>, p_typeAttention: string)  {
    var res = true;
    await arrayLabels.map(
      (lab) => {
        session.forms.inbound.subcategory.options.map(
          (subCat) => {
            if (subCat.label === lab && subCat.type_attention === p_typeAttention) {
              alert("Ya existe un Motivo de Atentión con el nombre: " + lab);
              res = false;
            }
          }
        )
      }
    );
    return res;
  }

  async function onSubmitSubcategory(data: any) {
    const categorySelected = watch("cbx_category");
    const label_subcategory = data.txt_nameSubcategory;

    if (categorySelected === undefined) {
      alert("Debe seleccinar una categoría");
      return;
    }
    if (label_subcategory === undefined || label_subcategory.trim() === "") {
      alert("Debe ingresar al menos un Motivo de Atención")
      return;
    }

    var sc_typeAttention = "";
    if (channelSelected === "whatsapp-out") {
      if (operacion === 'C') {
        sc_typeAttention = removeAccents(data.labelAttention);
      }
      if (operacion === 'U') {
        sc_typeAttention = "" + attentionTypeSelected;
      }
    }

    var labelSubCats = label_subcategory.split("\n");
    var resVal = await validarValueSubCats(labelSubCats, sc_typeAttention);
    if(!resVal) {
      return;
    }

    labelSubCats.map((label:any) => {
      if (!(label.trim() === "")) {
        var value_subcategory = removeAccents(label);

          session.forms.inbound.subcategory.options.push(
            {
              label: label,
              value: value_subcategory,
              category: categorySelected,
              type_attention: sc_typeAttention,
            }
          );
          setOpenFrmSubcategory(false);
      }
    });
    window.localStorage.setItem("sess_campaignmnt_type_attention", sc_typeAttention);
    window.localStorage.setItem("sess_campaignmnt_canal_temp", channelSelected);
    setValue("channel", "otro", true);
  }

  const registrado = watch("registrado")
  if (registrado === "1") return <Redirect to="/dashboard/inbound/campaignMnt" />;

  return (
    <motion.div initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} >
        <div className={classes.contentGeneral}>
          <div className={classes.content}>
            <div>
              <Typography variant="h4" align="center" style={{ marginBottom: 24 }}> Formulario Para Creación de Campaña </Typography>
            </div>

            <div className={classes.channelSelector}>
              <div>
                <InputContainer label="Nombre:">

                  <TextField name="labelAttention"
                    placeholder="Ingresa nombre de Campaña"
                    defaultValue={getLabelTypeAttention()}
                    inputRef={register({ required: true })}
                  />
                </InputContainer>
              </div>
            </div>

            <div className={classes.channelSelector}>
              <div>
                <Typography variant="h6">Motivos de Atención:</Typography>
              </div>
            </div>

            <div className={classes.header}>
              <div></div>
              <div>
                <Button
                  size="large"
                  id="submit_login"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenFrmSubcategory(true);
                  }}
                >
                  Agregar Motivo
                      </Button>
              </div>
              <div>
                <Button
                  size="large"
                  id="submit_login"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    fn_eliminar();
                  }}
                >
                  Eliminar
                      </Button>
              </div>
            </div>

            <div className={classes.table}>
              <div>
                {(subcategory_options.length > 0) && renderTable(tableProps)}
                {!(subcategory_options.length > 0) &&
                  (
                    <div className={classes.academic_alert}>
                      <Typography variant="subtitle1">
                        La campaña no tiene motivos de atención agregados
                                      </Typography>
                      <img src={request_alert} alt="financial alert" />
                    </div>
                  )
                }
              </div>
            </div>

            <div className={classes.wrapper}>
              <Button
                size="large"
                id="submit_login"
                variant="contained"
                color="primary"
                type="submit"
                >
                Registrar
              </Button>
            </div>
          </div>
        </div>

      </form>
      <TextField type="hidden" name="registrado" placeholder="" inputRef={register({ required: true })} value="0" />
      <TextField type="hidden" name="channel" placeholder="" inputRef={register({ required: true })} value={channelSelected} />
      

      <Dialog
        open={openFrmSubcategory}
        onClose={() => {
          setOpenFrmSubcategory(false);
        }}
        maxWidth="md"
      >
        <form className={classes.form} onSubmit={handleSubmit(onSubmitSubcategory)} >
          <div className={classes.content}>
            <div>
              <Typography variant="h4" align="center" style={{ marginBottom: 24 }}> Formulario Motivo de Atención </Typography>
            </div>

            <div>
              <InputContainer label="Tipo de Contacto:">
                <Controller
                  as={
                    <Select>
                      {renderOptions(categories, "Categoria")}
                    </Select>
                  }
                  error={errors.attention_type}
                  name="cbx_category"
                  rules={{ required: false }}
                  fullWidth
                  variant="outlined"
                  control={control}
                />
              </InputContainer>
            </div>
            <div>
              <InputContainer label="Motivo:">
                <TextField name="txt_nameSubcategory" multiline rows={10}
                  inputRef={register({ required: true })}
                  >

                </TextField>
              </InputContainer>
            </div>
            <div className={classes.wrapperModal}>
              <Button
                size="large"
                id="submit_login"
                variant="contained"
                color="primary"
                type="submit"
                >
                Aceptar
              </Button>
            </div>
          </div>
        </form>
      </Dialog>

    </motion.div>
  );
}

import CampaignRepository from "./../infrastructure/CampaignRepository";
import CampaignChannel from "./../entities/CampaignChannel";

export default class SaveChannelUseCase {

    campaignRepository: CampaignRepository;

    constructor(campaignRepository: CampaignRepository) {
        this.campaignRepository = campaignRepository;
    }

    async execute(data: CampaignChannel) {
        const channel = await this.campaignRepository.saveChannel(data);
        return channel;
    }
}
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { motion } from "framer-motion";

export type DataCell = string | undefined | number | null;

export type TableProps = {
  headers: Headers;
  data: Array<{ [key: string]: DataCell }>;
  maxHeight?: number;
};

type Headers = Array<{
  label: string;
  accessor?: string;
  renderCell?: (index: number, value: DataCell) => JSX.Element;
}>;

const variantsBody = {
  hidden: {},
  show: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.1,
    },
  },
};

export default function Table(props: TableProps) {
  const classes = useStyles();
  const { headers, data, maxHeight, ...rest } = props;
  return (
    <div
      {...rest}
      style={{ overflow: "auto", maxHeight: maxHeight || "inherit" }}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.label}>
                <Typography variant="h6">{header.label}</Typography>
              </th>
            ))}
          </tr>
        </thead>
        <motion.tbody initial="hidden" animate="show" variants={variantsBody}>
          {data.map((row, indexRow) => (
            <Row
              indexRow={indexRow}
              headers={headers}
              row={row}
              key={row.key || indexRow}
            />
          ))}
        </motion.tbody>
      </table>
    </div>
  );
}

function Row(props: {
  row: { [key: string]: DataCell };
  headers: Headers;
  indexRow: number;
}) {
  const { row, headers, indexRow } = props;
  return (
    <tr>
      {headers.map((header, index) => (
        <td key={index}>
          {header.renderCell ? (
            header.renderCell(indexRow, header.accessor && row[header.accessor])
          ) : (
            <Typography variant="body2">
              {header.accessor && (row[header.accessor] || "-")}
            </Typography>
          )}
        </td>
      ))}
    </tr>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    borderSpacing: 0,
    borderCollapse: "separate",
    width: "100%",
    "& thead": {
      "& tr": {},
      "& th:first-child": {
        textAlign: "left",
      },
      "& th": {
        backgroundColor: "#f9fbff",
        position: "sticky",
        top: 0,
        borderBottom: "1px solid #ccd6dc",
        padding: "16px 24px",
        zIndex: 2,
      },
    },
    "& tbody": {},
    "& tr": {
      "& td:first-child": {
        textAlign: "left",
      },
      "& td": {
        padding: "12px 24px",
        textAlign: "center",
        border: "none",
        maxWidth: 400,
      },
    },
  },
}));

import React, { ReactNode } from "react";
import Typography from "@material-ui/core/Typography";

export default function InputContainer(props: {
  children: ReactNode;
  label: string;
}) {
  return (
    <div style={{ margin: "10px 0 15px 0" }}>
      <Typography variant="h6" style={{ marginBottom: 5 }}>
        {props.label}
      </Typography>
      {props.children}
    </div>
  );
}

import StudentRepository from "../infrastructure/StudentRepository";

export default class GetTypifications {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(id: string, user_id:number) {
    const typifications = await this.studentRepository.getTypifications(id,user_id);
    return typifications;
  }
}

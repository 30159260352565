import StudentRepository from "./StudentRepository";
import fetcher from "_domain/_shared/fetcher";
import Student, { FinancialPeriodData, RequestItem } from "../entities/Student";
import { TypificationForm } from "../entities/TypificationForm";
import Typification from "../entities/Typification";
import { TypificationFormAnonymous } from "../entities/TypificationFormAnonymous";
import FinancialItem from "../entities/FinancialItem";
import Course from "../entities/Course";
import DetailPeriod from "../entities/DetailPeriod";

export default class HTTPStudentRepository implements StudentRepository {
  TRACKING_NAME = "carmen-tracking";

  async getStudent(id: string) {
    const response = await fetcher(`students/${id}/`);
    if (!response.ok) throw new Error("No se pudo obtener al estudiante");

    let data = await response.json();
    data = data.object;
    const tracking = this.getTracking(id);

    function mapStatus(value: string) {
      switch (value) {
        case "ACTIVO":
          return "activo";
        case "BAJA":
          return "baja";
        case "Cancelado":
          return "cancelado";
        default:
          return undefined;
      }
    }

    const student = new Student({
      id,
      code: data.code_utp,
      tracking,
      first_name: data.person.name,
      last_name: data.person.lastname,
      photo: data.photo,
      email:
        data.person.emails.find((email: any) => email.principal === 1)?.value ||
        data.person.emails[0]?.value ||
        null,
      phone:
        data.person.telephones.find((email: any) => email.principal === 1)
          ?.value ||
        data.person.telephones[0]?.value ||
        null,
      alter_id: data.person.emplid,
      campus: {
        value: data.academic.campus.code,
        label: data.academic.campus.name,
      },
      grade: {
        value: data.academic.acad_career.code,
        label: data.academic.acad_career.name,
      },
      carrer: data.academic.specialty.name,
      period: data.academic.acad_cycle,
      periods: data.academic.periods,
      identity_document: data.person.document_number,
      status: mapStatus(data.academic.status),
      privacy: data.privacy,
      alerts:
        data.alerts?.map((alert: any) => ({
          name: alert.name,
          type: alert.alert_type,
          icon: alert.icon,
          message: alert.messages,
        })) || [],
      current_benefit: data.current_benefit,
      lastAcademicInfo: data.categories.academic.academic_year.code
        ? {
            courses: data.categories.academic.items.map(
              (course: any): Course =>
                new Course({
                  catalog_number: course.catalog_nbr,
                  class_number: course.class_nbr,
                  title: course.course_title,
                  average: course.crse_grade_input,
                  enroll_status: course.enroll_status,
                  status: course.status,
                  session_code: course.session_code,
                  turn: course.ssr_shift,
                  times: course.times,
                  credits: course.units_minimum,
                  hours: course.hours,
                  crse_eval_formula: course.crse_eval_formula,
                  attendance: course.attendance,
                  teachers: course.teachers,
                })
            ),
            year_code: data.categories.academic.academic_year.code,
            year_name: data.categories.academic.academic_year.name,
          }
        : null,
      requests: data.categories.solicitude.map(
        (item: any): RequestItem => ({
          id: item.id,
          date: new Date(item.created_at),
          last_updated: new Date(item.updated_at),
          description: item.description,
          state: mapRequestStatus(item.status),
          url:
            item.zendesk === "SAE en Línea"
              ? `https://saeutp.zendesk.com/agent/tickets/${item.id}`
              : `https://admision.zendesk.com/agent/tickets/${item.id}`,
          url_label: item.zendesk,
        })
      ),
      financialInfo: data.categories.financial?.items
        ? {
            lastPeriod: {
              code: data.categories.financial.current_period,
              data: {
                items: data.categories.financial.items.map(
                  (item: any) =>
                    new FinancialItem({
                      amount: item.item_amt,
                      amoutn_to_pay: item.amount,
                      discount: item.is_paid ? null : item.applied_amt,
                      due_date: item.due_date
                        ? new Date(item.due_date)
                        : undefined,
                      upd_date: item.is_paid ? new Date(item.upd_date) : null,
                      description: item.description_item,
                      type: item.item_type,
                      mora: item.mora_amt,
                      total_amount: item.total_amount,
                    })
                ),
                otherItems: data.categories.financial.otheritems.map(
                  (item: any) =>
                    new FinancialItem({
                      amount: item.item_amt,
                      amoutn_to_pay: item.amount,
                      discount: item.is_paid ? null : item.applied_amt,
                      due_date: item.due_date
                        ? new Date(item.due_date)
                        : undefined,
                      upd_date: item.is_paid ? new Date(item.upd_date) : null,
                      description: item.description_item,
                      type: item.item_type,
                      mora: item.mora_amt,
                      total_amount: item.total_amount,
                    })
                ),
              },
            },
            periods: data.categories.financial.periods,
          }
        : null,
    });

    function mapRequestStatus(code: string) {
      switch (code) {
        case "open":
          return { name: "Abierto", code, color: "#ff8389" };
        case "pending":
          return { name: "Pendiente", code, color: "#f4c72e" };
        case "hold":
          return { name: "En espera", code, color: "#f4c72e" };
        case "solved":
          return { name: "Resuelto", code, color: "#43c889" };
        case "closed":
          return { name: "Cerrado", code, color: "#43c889" };
        case "new":
          return { name: "Nuevo", code, color: "#ff8389" };
        default:
          return { name: "Desconocido", code, color: "#4a4a4a" };
      }
    }

    return student;
  }

  async getFinancialData(
    student_id: string,
    period: string
  ): Promise<FinancialPeriodData> {
    const response = await fetcher(
      `students/financial/${student_id}/${period}/`
    );
    if (!response.ok) throw new Error("No se pudo obtener al estudiante");

    const data = await response.json();
    const dataPeriodPlain = data.data;
    const items = dataPeriodPlain.items.map(
      (item: any) =>
        new FinancialItem({
          amount: item.item_amt,
          amoutn_to_pay: item.amount,
          discount: item.is_paid ? null : item.applied_amt,
          due_date: item.due_date ? new Date(item.due_date) : undefined,
          upd_date: item.is_paid ? new Date(item.upd_date) : null,
          description: item.description_item,
          type: item.item_type,
          mora: item.mora_amt,
          total_amount: item.total_amount,
        })
    );

    const otherItems = dataPeriodPlain.otheritems.map(
      (item: any) =>
        new FinancialItem({
          amount: item.item_amt,
          amoutn_to_pay: item.amount,
          discount: item.is_paid ? null : item.applied_amt,
          due_date: item.due_date ? new Date(item.due_date) : undefined,
          upd_date: item.is_paid ? new Date(item.upd_date) : null,
          description: item.description_item,
          type: item.item_type,
          mora: item.mora_amt,
          total_amount: item.total_amount,
        })
    );

    return { items, otherItems };
  }

  async getPeriod(id: string, period: string, grade: string) {
    const response = await fetcher(
      `students/academic/${id}/${period}/${grade}/`
    );
    if (!response.ok) throw new Error("No se pudo obtener al estudiante");

    const data = await response.json();
    const courses_plain = data.data;
    const courses = courses_plain.map(
      (course: any): Course =>
        new Course({
          catalog_number: course.catalog_nbr,
          class_number: course.class_nbr,
          title: course.course_title,
          average: course.crse_grade_input,
          enroll_status: course.enroll_status,
          status: course.status,
          session_code: course.session_code,
          turn: course.ssr_shift,
          times: course.times,
          credits: course.units_minimum,
          hours: course.hours,
          crse_eval_formula: course.crse_eval_formula,
          attendance: course.attendance,
          teachers: course.teachers,
        })
    );
    return courses;
  }

  async searchStudent(code: string) {
    const response = await fetcher(`students/keyword/${code}/`);
    if (!response.ok) throw new Error("No se encontró al estudiante");

    const data = await response.json();
    const tracking = data.tracking;

    if (!data.success) {
      this.saveTracking("anonymous", tracking);
      throw new Error(data.message);
    }
    const studentId = data.student;

    this.saveTracking(studentId, tracking);

    return studentId;
  }

  async getDetailPeriod(
    id: string,
    period: string,
    grade: string,
    class_nbrs: number[]
  ): Promise<DetailPeriod> {
    let query = "";
    class_nbrs.forEach(
      (class_nbr) => (query = query + "section_id=" + class_nbr + "&")
    );

    const [responseEvaluations, responseScheludes] = await Promise.all([
      fetcher(`students/grades/${id}/${period}/`),
      fetcher(`students/schedule/${period}/${grade}?${query}`),
    ]);
    if (!responseScheludes.ok)
      throw new Error("No se pudo obtener los horarios");
    if (!responseEvaluations.ok)
      throw new Error("No pudo obtener las evaluaciones");

    let dataEvaluations = await responseEvaluations.json();
    dataEvaluations = dataEvaluations.data;
    let dataScheludes = await responseScheludes.json();
    dataScheludes = dataScheludes.data;

    return new DetailPeriod({
      evaluations: dataEvaluations.map((ev: any) => ({
        eval_name: ev.eval_name,
        class_nbr: ev.class_nbr,
        student_grade: ev.student_grade,
        order: ev.order,
      })),
      scheludes: dataScheludes.map((sch: any) => ({
        class_nbr: sch.class_nbr,
        summary: sch.summary,
      })),
    });
  }

  async postTypification(student: Student, data: TypificationForm) {
    const requestBody = {
      student: student.id,
      tracking: student.tracking,
      fields: {
        attention: { value: data.typification_type, field: 3 },
        campaign: { value: "inbound", field: 2 },
        category: { value: data.category, field: 4 },
        subcategory: { value: data.subcategory, field: 5 },
        channel: { value: data.channel, field: 7 },
        observation: { value: data.observations, field: 6 },
        resultCall: { value: data.result_call, field: 10 },
      },
    };

    const response = await fetcher(`attentions/save/`, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) throw new Error("No se pudo enviar la solicitud");
  }

  async postTypificationAnonymous(data: TypificationFormAnonymous) {
    const requestBody = {
      student: {
        code: data.student.code,
        doc_number: data.student.doc_number,
      },
      tracking: this.getTracking("anonymous"),
      fields: {
        attention: { value: "consulta", field: 3 },
        campaign: { value: "inbound", field: 2 },
        campus: { value: data.campus, field: 12 },
        category: { value: data.category, field: 4 },
        channel: { value: data.channel, field: 7 },
        grade: { value: data.grade, field: 11 },
        subcategory: { value: data.subcategory, field: 13 },
        observation: { value: data.observations, field: 6 },
        resultCall: { value: data.result_call, field: 10 },
      },
    };

    const response = await fetcher(`attentions/saveStudent/`, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) throw new Error("No se pudo enviar la solicitud");
  }

  async getTypifications(id: string, user_id:number) {
    const requestBody = {
      student: id,
      user_id: user_id
    };
    const response = await fetcher(`attentions/student/`, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) throw new Error("No se pudo obtener las tipificaciones");

    let data = await response.json();

    const typifications: Typification[] = data.map((tipPlain: any) => {
      const typification: Typification = new Typification({
        id: tipPlain.id,
        title: tipPlain.title,
        name: tipPlain.name,
        user: tipPlain.user,
        created_at: new Date(tipPlain.created_at),
        typification_type: tipPlain.fields.attention,
        campaign: tipPlain.fields.campaign,
        category: tipPlain.fields.category,
        typification_reason: tipPlain.fields.subcategory,
        channel: tipPlain.fields.channel,
        observations: tipPlain.fields.observation,
        result_call: tipPlain.fields.resultCall,
      });
      return typification;
    });
    return typifications;
  }

  private getTracking(id: string): string {
    const stringTracking = localStorage.getItem(this.TRACKING_NAME) || "{}";
    const trackingObj = JSON.parse(stringTracking);
    const tracking = trackingObj[id];
    if (!tracking) throw new Error("No se encontró el tracking del estudiante");
    return tracking;
  }

  private saveTracking(id: string, tracking: string) {
    let stringTracking = localStorage.getItem(this.TRACKING_NAME) || "{}";
    const trackingObj = JSON.parse(stringTracking);
    trackingObj[id] = tracking;
    stringTracking = JSON.stringify(trackingObj);
    localStorage.setItem(this.TRACKING_NAME, stringTracking);
  }

  async setPrivacity(id: string, reason: string) {
    const requestBody = {
      student: id,
      reason: reason,
    };

    const response = await fetcher(`students/privacy/`, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) throw new Error("No se pudo asignar la privacidad");
  }

  async deletePrivacy(id: string) {
    const requestBody = {
      student: id,
    };

    const response = await fetcher(`students/privacy/`, {
      method: "DELETE",
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) throw new Error("No se pudo eliminar la privacidad");
  }
}

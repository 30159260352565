import StudentRepository from "../infrastructure/StudentRepository";
import Student from "../entities/Student";
import DocumentRepository from "../infrastructure/DocumentRepository";

export default class GetFiltersUseCase {
  documentRepository: DocumentRepository;

  constructor(documentRepository: DocumentRepository) {
    this.documentRepository = documentRepository;
  }
  async execute() {
    const filters = await this.documentRepository.getFilters();
    return filters;
  }
}

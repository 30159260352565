type DocumetOptions = {
  id: number;
  grade: string;
  url: string;
  campus: string;
  type_name: string;
  type_order: number;
  icon: string;
};

export default class Document {
  id: number;
  grade: string;
  url: string;
  campus: string;
  type_name: string;
  type_order: number;
  icon: string;
  constructor(options: DocumetOptions) {
    this.id = options.id;
    this.grade = options.grade;
    this.url = options.url;
    this.campus = options.campus;
    this.type_name = options.type_name;
    this.type_order = options.type_order;
    this.icon = options.icon;
  }
  
}

import SessionRepository, {
  Credentials,
} from "../infrastructure/SessionRepository";

export default class SignInUseCase {
  sessionRepository: SessionRepository;

  constructor(sessionRepository: SessionRepository) {
    this.sessionRepository = sessionRepository;
  }
  async execute(credentials: Credentials) {
    const newSession = await this.sessionRepository.create(credentials);
    return newSession;
  }
}

import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SquareInfo from "components/SquareInfo";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Student, { RequestItem } from "_domain/student/entities/Student";
import Table, { TableProps } from "components/Table";
import Typography from "@material-ui/core/Typography";
import request_alert from "assets/images/request_alert.png";
import { motion } from "framer-motion";
import { dateToDDMMAAAA } from "_domain/_shared/date";
import Drawer from "../../../../components/Drawer";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto auto",
    gridGap: 8,
  },
  table: {
    backgroundColor: "#f9fbff",
    marginTop: 16,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    overflow: "hidden",
  },
  academic_alert: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#748e98",
    padding: "100px",
    "& img": {
      marginTop: 40,
      height: 80,
    },
  },
  detailContent: {
    display: "grid",
    gridGap: 24,
  },
}));

type RequestsListProps = {
  student: Student;
};

export default function RequestsList(props: RequestsListProps) {
  const classes = useStyles();
  const { student } = props;
  const [requestSelected, setRequestSelected] = useState<null | RequestItem>(
    null
  );
  const [openDetail, setOpenDetail] = useState(false);

  const tableProps: TableProps = {
    data: student.requests.map((requestItem) => ({
      ...requestItem,
      date: dateToDDMMAAAA(requestItem.date),
      last_updated: dateToDDMMAAAA(requestItem.last_updated),
      state: requestItem.state.name,
    })),
    headers: [
      { label: "ID", accessor: "id" },
      {
        label: "Descripción",
        accessor: "description",
        renderCell: (index, value) => {
          return (
            <Link
              variant="body2"
              color="primary"
              align="left"
              onClick={() => {
                setRequestSelected(student.requests[index]);
                setOpenDetail(true);
              }}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "block",
                cursor: "pointer",
              }}
            >
              {value}
            </Link>
          );
        },
      },
      { label: "Fecha", accessor: "date" },
      { label: "Última actualización", accessor: "last_updated" },
      {
        label: "Estado",
        accessor: "state",
        renderCell: (index, value) => {
          const request = student.requests[index];
          return (
            <Typography variant="body2" style={{ color: request.state.color }}>
              {value}
            </Typography>
          );
        },
      },
      {
        label: "",
        accessor: "url",
        renderCell: (index, value) => (
          <Link
            data-cy="go_zendesk"
            href={student.requests[index].url}
            target="_blank"
            style={{ cursor: "pointer" }}
          >
            {student.requests[index].url_label}
          </Link>
        ),
      },
    ],
  };

  const isEmpty = student.requests.length <= 0;

  return (
    <motion.div initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
      <div className={classes.header}>
        <div></div>
        <div>
          {!isEmpty && (
            <SquareInfo
              data-cy="squareinfo_open_tickets"
              label="Tickets abiertos"
              value={student.ticketsOpened.toString()}
            />
          )}
        </div>
        <div>
          {!isEmpty && (
            <SquareInfo
              data-cy="squareinfo_solved_tickets"
              label="Tickets resueltos"
              value={student.ticketsResolved.toString()}
            />
          )}
        </div>
        <Link href="https://saeutp.zendesk.com/agent/dashboard" target="_blank">
          <Button variant="contained" color="primary">
            Ir a Zendesk
          </Button>
        </Link>
      </div>
      <div className={classes.table}>
        {!isEmpty ? (
          <Table data-cy="table_requests_list" {...tableProps} />
        ) : (
          <div className={classes.academic_alert}>
            <Typography variant="subtitle1">
              El alumno no ha realizado ninguna solicitud.
            </Typography>
            <img src={request_alert} alt="financial alert" />
          </div>
        )}
      </div>
      {requestSelected && (
        <Drawer
          open={openDetail}
          data-cy="request_detail_drawer"
          onExited={() => setRequestSelected(null)}
          onClose={() => setOpenDetail(false)}
          title={"Solicitud: ID " + requestSelected.id}
        >
          <div className={classes.detailContent}>
            <Typography
              variant="body2"
              data-cy="drawer_description"
              dangerouslySetInnerHTML={{
                __html: requestSelected.description?.split("\n").join("<br/>"),
              }}
            />
            <Typography variant="body2" data-cy="drawer_date">
              Fecha: {dateToDDMMAAAA(requestSelected.date)}
            </Typography>
            <Typography variant="body2" data-cy="drawer_upd_date">
              Última actualización:{" "}
              {dateToDDMMAAAA(requestSelected.last_updated)}
            </Typography>
            <Typography variant="body2" data-cy="drawer_state">
              Estado:{" "}
              <span style={{ color: requestSelected.state.color }}>
                {requestSelected.state.name}
              </span>
            </Typography>
            <Link
              data-cy="drawer_go_zendesk"
              href={requestSelected.url}
              target="_blank"
            >
              <Button color="primary" variant="contained" fullWidth>
                {requestSelected.url_label}
              </Button>
            </Link>
          </div>
        </Drawer>
      )}
    </motion.div>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

type InfoChipProps = {
  text: string[];
  contained?: boolean;
  color?: string;
  icon?: JSX.Element;
};

const useStyles = makeStyles((theme) => ({
  container: ({ contained, color }: { contained: boolean; color: string }) => ({
    border: contained ? "none" : `1px solid ${color}`,
    borderRadius: 12,
    fontSize: 11,
    padding: "4px 16px",
    display: "inline-flex",
    color: contained ? "white" : color,
    backgroundColor: contained ? color : "none",
    "&>*": {
      marginRight: 8,
      "&:last-child": {
        marginRight: 0,
      },
    },
  }),
}));

export default function InfoChip(props: InfoChipProps) {
  const { contained = false, icon, text, color = "#81a8d2", ...rest } = props;
  const classes = useStyles({ contained, color });
  return (
    <div {...rest} className={classes.container}>
      {icon}
      {text.map((t) => (
        <span>{t}</span>
      ))}
    </div>
  );
}

import FinancialItem, { FinancialItemOptions } from "./FinancialItem";
import Course from "./Course";

export type RequestItem = {
  id: string;
  description: string;
  date: Date;
  last_updated: Date;
  state: { code: string; name: string; color: string };
  url: string;
  url_label: string;
};

export type StudentOptions = {
  id: string;
  code: string;
  tracking: string;
  first_name: string;
  last_name: string;
  photo: string;
  email: string;
  phone: string;
  alter_id: string;
  campus: { value: string; label: string };
  grade: { value: string; label: string };
  carrer: string;
  period: string;
  periods: {
    acad_career: { code: string; name: string };
    period: { code: string; name: string };
    grade_average: string;
  }[];
  identity_document: string;
  status: "activo" | "baja" | "cancelado" | undefined;
  alerts: Array<{
    name: "trica" | "sanction" | "enrollment_turn";
    type: "warning" | "info" | "alert";
    icon: string | null;
    message: Array<{ format: string; text: string }>;
  }>;
  current_benefit?: {
    code: {
      code: string;
      name: string;
    };
    concept: {
      name: string;
    };
    type: {
      name: string;
    };
  };
  lastAcademicInfo: {
    year_code: string;
    year_name: string;
    courses: Course[];
  } | null;
  financialInfo: FinancialInfo | null;

  requests: RequestItem[];
  privacy?: {
    reason: string;
  };
};

export type FinancialInfo = {
  periods: Array<{ code: string; name: string }>;
  lastPeriod: {
    code: string;
    data: FinancialPeriodData;
  };
};

export type FinancialPeriodData = {
  items: Array<FinancialItem>;
  otherItems: Array<FinancialItem>;
};

export default class Student {
  id: string;
  code: string;
  tracking: string;
  first_name: string;
  last_name: string;
  photo: string;
  email: string | null;
  phone: string | null;
  alter_id: string;
  campus: { value: string; label: string };
  grade: { value: string; label: string };
  carrer: string;
  period: string;
  periods: {
    acad_career: { code: string; name: string };
    period: { code: string; name: string };
    grade_average: string;
  }[];
  identity_document: string;
  status: "activo" | "baja" | "cancelado" | undefined;
  alerts: Array<{
    name: "trica" | "sanction" | "enrollment_turn";
    type: "warning" | "info" | "alert";
    icon: string | null;
    message: Array<{ format: string; text: string }>;
  }>;
  current_benefit?: {
    code: {
      code: string;
      name: string;
    };
    concept: {
      name: string;
    };
    type: {
      name: string;
    };
  };
  lastAcademicInfo: {
    year_code: string;
    year_name: string;
    courses: Course[];
  } | null;
  requests: RequestItem[];
  financialInfo: FinancialInfo | null;
  privacy?: {
    reason: string;
  };

  constructor(options: StudentOptions) {
    this.id = options.id;
    this.code = options.code;
    this.tracking = options.tracking;
    this.first_name = options.first_name;
    this.periods = options.periods;
    this.last_name = options.last_name;
    this.photo = options.photo;
    this.email = options.email;
    this.phone = options.phone;
    this.alter_id = options.alter_id;
    this.campus = options.campus;
    this.grade = options.grade;
    this.carrer = options.carrer;
    this.period = options.period;
    this.identity_document = options.identity_document;
    this.status = options.status;
    this.alerts = options.alerts;
    this.lastAcademicInfo = options.lastAcademicInfo;
    this.requests = options.requests;
    this.financialInfo = options.financialInfo;
    this.privacy = options.privacy;
    this.current_benefit = options.current_benefit;
  }

  get hasAlerts() {
    return this.alerts.length > 0;
  }

  get alertHighlight() {
    return (
      this.alerts.find((alert) => alert.name === "sanction") ||
      this.alerts.find((alert) => alert.name === "enrollment_turn")
    );
  }

  get otherAlerts() {
    const alerts = this.alerts.filter(
      (alert) => alert.name !== "enrollment_turn" && alert.name !== "sanction"
    );
    return alerts.length <= 0 ? false : alerts;
  }

  get hasAlertToShow() {
    const hasHighlightAlert = !!this.alertHighlight;
    const hasOtherAlerts = !!this.otherAlerts;
    if (hasHighlightAlert && !hasOtherAlerts) return false;
    if (!this.hasAlerts) return false;
    return true;
  }

  get ticketsResolved() {
    const filteredRequests = this.requests.filter(
      (req) => req.state.code === "solved"
    );
    return filteredRequests.length;
  }

  get ticketsOpened() {
    const filteredRequests = this.requests.filter(
      (req) => req.state.code === "open"
    );
    return filteredRequests.length;
  }
}

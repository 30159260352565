import fetcher from "_domain/_shared/fetcher";
import DashboardHeaders from "../entities/DashboardHeaders";
import Semaphore from "../entities/Semaphore";
import SemaphoreEvolution from "../entities/SemaphoreEvolution";
import AlertRepository from "./AlertRepository";

export default class AlertRepositoryApi implements AlertRepository {
  async getSemaphore(id: string) {
    const response = await fetcher(`dashboard/semaphore_report/${id}/`);
    if (!response.ok) throw new Error("No se pudo obtener los semaforos");
    const data = await response.json();

    const jsonSemaphore = JSON.stringify(data);
    const objSemaphore = new Semaphore(JSON.parse(jsonSemaphore));
    return objSemaphore;
  }

  async getSemaphoreEvolution(id: string, id_semaphore: string) {
    const response = await fetcher(
      `dashboard/semaphore_report_evol/${id}/${id_semaphore}/`
    );
    if (!response.ok)
      throw new Error("No se pudo obtener los semaforos evolución");
    const data = await response.json();

    if (data.success) {
      const jsonSemaphoreEvolution = JSON.stringify(data);
      const objSemaphoreEvolution = new SemaphoreEvolution(
        JSON.parse(jsonSemaphoreEvolution)
      );
      return objSemaphoreEvolution;
    } else {
      const jsonSemaphoreEvolution = JSON.stringify('{"data":[]}');
      const objSemaphoreEvolution = new SemaphoreEvolution(
        JSON.parse(jsonSemaphoreEvolution)
      );
      return objSemaphoreEvolution;
    }
  }

  async getDashboardHeaders(id: string): Promise<DashboardHeaders> {
    const response = await fetcher(`dashboard/headers/${id}/`);
    if (!response.ok)
      throw new Error("No se pudo obtener los headers dashboard");
    const data = await response.json();

    const jsonHeaders = JSON.stringify(data.data);
    const objHeaders = new DashboardHeaders(JSON.parse(jsonHeaders));
    return objHeaders;
  }
}

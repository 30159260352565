import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/images/logo.png";
import IconButton from "@material-ui/core/IconButton";
import {CcIcon} from "@cafeta/components-react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "80px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.08)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sectionLogo: {
    padding: 20,
    "& img": {
      display: "block",
      height: 25,
    },
  },
  sectionUser: {
    padding: "0 20px",
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridGap: 8,
    borderLeft: "1px solid #ebf1f5",
    alignItems: "center",
    height: "100%"
  },
  menuButton: {
    padding: 10,
    borderRadius: 5,
    textAlign: "left",
  },
  avatar: {
    height: 40,
    width: 40,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    marginRight: 10,
    "& img": {
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
    },
  },
  arrow: {
    marginLeft: 10,
    color: theme.palette.primary.main,
  },
}));

type HeaderProps = {
  onLogOut(): void;
  username: string;
  role: string;
};

export default function Header(props: HeaderProps) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionLogo}>
        <img alt="Carmen Logo" src={logo} />
      </div>
      <div className={classes.sectionUser}>
        <div>
          <Typography variant="h4">{props.username}</Typography>
          <Typography variant="body1">{props.role}</Typography>
        </div>
        <IconButton color="primary" onClick={() => props.onLogOut()}>
          <CcIcon name="log-out" size={18} />
        </IconButton>
      </div>
    </div>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {CcIcon} from "@cafeta/components-react";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    margin: "auto 0",
    marginRight: 16,
  },
}));

type DocumentProps = {
  icon: string;
  title: string;
  url: string;
};

export default function Document(props: DocumentProps) {
  const classes = useStyles();
  return (
      <Card elevation={1} style={{backgroundColor: "#f9fbff"}}>
        <CardContent data-cy="document_item" className={classes.content}>
          <div data-cy="document_icon" className={classes.icon}>
            <IconComponent nameIcon={props.icon} />
          </div>
          <div>
            <Typography data-cy="document_title" variant="h4">{props.title}</Typography>
            <Typography data-cy="document_link" variant="body1">
              <Link href={props.url} target="_blank">
                Ver documento
              </Link>
            </Typography>
          </div>
        </CardContent>
      </Card>
  );
}

function IconComponent(props: { nameIcon: string }) {
  switch (props.nameIcon) {
    case "file":
      return <CcIcon data-cy="file_text" name="file-text"/>;
    case "mark":
      return <CcIcon data-cy="flag" name="flag"/>;
    case "idea":
      return <CcIcon data-cy="star" name="star"/>;
    default:
      return <CcIcon data-cy="file" name="file"/>;
  }
}

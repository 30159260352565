import React, {
  useState,
  useContext,
  useEffect,
  Context,
  ReactNode,
} from "react";
import { Credentials } from "../_domain/session/infrastructure/SessionRepository";
import useCases from "../_domain";
import Session from "../_domain/session/entities/SessionEntity";
import LoadingPage from "components/LoadingPage";

type SessionContextType = {
  session: Session | null;
  login(credentials: Credentials): Promise<void>;
  logout(): void;
  refreshToken(): void;
  loading: boolean;
};

const authContext = React.createContext<SessionContextType>({
  session: null,
  login: async (credentials: Credentials) => {},
  logout: () => {},
  refreshToken: () => {},
  loading: true,
});

function useAuth(): SessionContextType {
  // TODO: Validar que session nunca sea null una vez validado (PrivateRoute) que no es null hacia sus hijos!!! MUY URGENTE
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    useCases.recovery_session_use_case
      .execute()
      .then((newSession) => setSession(newSession))
      .catch(() => logout())
      .finally(() => setLoading(false));
  }, []);

  const refreshToken = () => {
    useCases.refreshToken_use_case
      .execute()
      .then((newSession) => setSession(newSession))
      .catch(() => logout());
  };

  const login = async (credentials: Credentials) => {
    const newSession: Session = await useCases.login_use_case.execute(
      credentials
    );
    setSession(newSession);
  };

  const logout = () => {
    const session = useCases.log_out_use_case.execute();
    setSession(session);
  };

  return { session, loading, login, logout, refreshToken };
}

export function useSession() {
  const session = useContext(authContext);
  return session;
}

export function AuthProvider(props: { children: ReactNode }) {
  const auth = useAuth();

  // refresh token logic
  useEffect(() => {
    // Idea: revisar la valides del token cada intervalo, si es invalido entonces cerrar sesión
    setInterval(() => {
      const toRefresh = localStorage.getItem("carmen-refresh-token");
      if (toRefresh) {
        localStorage.removeItem("carmen-refresh-token");
        auth.refreshToken();
      }
    }, 5000);
  }, []);

  return (
    <authContext.Provider value={auth}>
      {auth.loading ? <LoadingPage/> : props.children}
    </authContext.Provider>
  );
}

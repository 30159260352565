import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavbarItem, { NavbarItemProps } from "./NavbarItem";
import { motion, AnimatePresence } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#2a3247",
    display: "flex",
    flexDirection: "column",
    width: "80px",
    height: "100%",
  },
}));

type NavbarItems = Array<NavbarItemProps>;

export default function Navbar(props: {
  items: NavbarItems;
  currentUser: NavbarItemProps | null;
}) {
  const classes = useStyles();
  return (
    <nav className={classes.wrapper}>
      <AnimatePresence>
        {props.currentUser && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <NavbarItem {...props.currentUser} />
          </motion.div>
        )}
      </AnimatePresence>

      {props.items.map((itemProps) => (
        <NavbarItem key={itemProps.label} {...itemProps} />
      ))}
    </nav>
  );
}

type Options = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  groups: Array<string>;
  channel: string;
  campus: string;
  collaborator_code: string;
};

export default class User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  groups: Array<string>;
  channel: string;
  campus: string;
  collaborator_code: string;
  constructor(options: Options) {
    this.first_name = options.first_name;
    this.last_name = options.last_name;
    this.id = options.id;
    this.groups = options.groups;
    this.channel = options.channel;
    this.campus = options.campus;
    this.email = options.email;
    this.collaborator_code = options.collaborator_code;
  }

  get primary_group() {
    return this.groups[0];
  }
}

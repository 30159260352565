import DocumentRepository from "./DocumentRepository";
import Document from "../entities/Document";
import DocumentFilter from "../entities/DocumentFilter";
import fetcher from "_domain/_shared/fetcher";

export default class HTTPDocumentRepository implements DocumentRepository {
  async getDocuments(): Promise<Array<Document>> {
    const response = await fetcher(`documents/`);
    if (!response.ok) throw new Error("No se pudo obtener los documentos");

    let data = await response.json();
    data = data.object;

    const documents = data.documents.map((doc: any, index: number) => {
      return new Document({
        id: index,
        campus: doc.campus,
        grade: doc.grade,
        icon: doc.document_type_icon,
        type_name: doc.document_type_name,
        type_order: doc.document_type_order,
        url: doc.path,
      });
    });
    return documents;
  }
  async getFilters(): Promise<Array<DocumentFilter>> {
    const response = await fetcher(`documents/`);
    if (!response.ok) throw new Error("No se pudo obtener los documentos");

    let data = await response.json();
    data = data.object;

    const filters = [
      new DocumentFilter({
        name: "grades",
        options: data.grades.map((grade: any) => {
          return { value: grade.value, label: grade.text };
        }),
      }),
      new DocumentFilter({
        name: "campus",
        options: data.campus.map((camp: any) => {
          return { value: camp.code, label: camp.name };
        }),
      }),
    ];

    return filters;
  }
}

import AlertRepository from "./../infrastructure/AlertRepository";

export default class GetDashboardHeadersUseCase {
  alertRepository: AlertRepository;

  constructor(alertRepository: AlertRepository) {
    this.alertRepository = alertRepository;
  }

  async execute(id: string) {
    const headers = await this.alertRepository.getDashboardHeaders(id);
    return headers;
  }
}

import React, { ReactNode } from "react";
import { motion } from "framer-motion";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { Divider } from "@material-ui/core";

const drawerWidth = 400;

const drawerVariants = {
  hidden: { x: drawerWidth },
  visible: { x: 0 },
};

const drawerBackgroundVariants = {
  hidden: { opacity: 0, transitionEnd: { display: "none" } },
  visible: { opacity: 1, display: "block" },
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: "white",
    width: drawerWidth,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 21,
    overflow: "auto",
  },
  drawerBackground: {
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "absolute",
    zIndex: 20,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  content: {
    padding: "16px 20px 30px 20px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 20px",
  },
  title: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
  },
  titleIcon: {
    gridRow: "1 / span 2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
  },
}));

type DrawerProps = {
  open: boolean;
  onClose(): void;
  onExited?: Function;
  children: ReactNode;
  title: string;
  titleInfo?: {
    icon: JSX.Element;
    subtitle: string;
  };
};

export default function Drawer(props: DrawerProps) {
  const classes = useStyles();

  return (
    <>
      <motion.div
        variants={drawerVariants}
        animate={props.open ? "visible" : "hidden"}
        initial="hidden"
        onAnimationComplete={() => {
          if (props.open === false && props.onExited) props.onExited();
        }}
        className={classes.drawer}
      >
        <div className={classes.header}>
          {props.titleInfo ? (
            <div className={classes.title}>
              <div className={classes.titleIcon} data-cy={"icon_drawer"}>
                {props.titleInfo.icon}
              </div>
              <Typography variant="h4" data-cy={"title_drawer"}>
                {props.title}
              </Typography>
              <Typography variant="body2" data-cy={"subtitle_drawer"}>
                {props.titleInfo.subtitle}
              </Typography>
            </div>
          ) : (
            <Typography variant="h4">{props.title}</Typography>
          )}
          <IconButton
            data-cy="close_drawer"
            onClick={() => props.onClose()}
            color="primary"
          >
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.content} data-cy="content_drawer">
          {props.children}
        </div>
      </motion.div>
      <motion.div
        data-cy="overlay_drawer"
        variants={drawerBackgroundVariants}
        animate={props.open ? "visible" : "hidden"}
        initial="hidden"
        onClick={() => props.onClose()}
        className={classes.drawerBackground}
      />
    </>
  );
}

import AlertRepository from "../infrastructure/AlertRepository"

export default class GetSemaphoreEvolutionUseCase {

  alertRepository: AlertRepository;

  constructor(alertRepository: AlertRepository) {
    this.alertRepository = alertRepository;
  }

  async execute(id: string, id_semaphore: string) {
    const semaphoreEvolution = await this.alertRepository.getSemaphoreEvolution(id, id_semaphore);
    return semaphoreEvolution;
  }
}
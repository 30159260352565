import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const colors = {
  normal: "#43c889",
  warning: "#f4c72e",
  alert: "#ff8389",
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "inline-flex",
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontSize: 12,
    fontWeight: "bold",
  },
}));

export default function Times(props: { number?: number | null | string }) {
  const { number } = props;
  const color = useMemo(() => {
    if (number === undefined || number === null) return colors.normal;
    if (number <= 1) return colors.normal;
    if (number <= 2) return colors.warning;
    return colors.alert;
  }, [number]);
  const classes = useStyles();

  return (
    <div
      className={classes.wrapper}
      style={{ backgroundColor: fade(color, 0.15), color: color }}
    >
      <span>{props.number === null ? "-" : props.number}</span>
    </div>
  );
}

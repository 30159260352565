import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import React, { useState } from "react";

import Spinner from "components/Spinner";
import background from "../../assets/images/background.jpg";
import logo from "../../assets/images/logo.png";
import { useSession } from "../../hooks/use-auth";
import routes from "app/routes";
import InputContainer from "components/InputContainer";
import {CcIcon} from "@cafeta/components-react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "gray",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    "& .MuiCardContent-root": {
      padding: theme.spacing(4),
    },
  },
  form: {
    width: "300px",
  },
  logo: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    textAlign: "center",
    "& img": {
      maxWidth: 250,
    },
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}));

export default function LoginLayout() {
  const { session, login } = useSession();
  const [showPwd, setShowPwd] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
    reset,
    clearError,
    setValue,
  } = useForm({
    mode: "onChange",
  });
  const [errorMessage, setErrorMessage] = useState(null);

  async function onSubmit(data: any) {
    setErrorMessage(null);
    try {
      await login({ username: data.username, password: data.password });
    } catch (e) {
      setError("username", "alert");
      setError("password", "alert");
      setErrorMessage(e.message);
    }
  }

  function clearInput(input: string) {
    clearError();
    setValue(input, "");
    setErrorMessage(null);
  }

  function toggleShowPassword() {
    setShowPwd((v) => !v);
  }

  if (session) return <Redirect to={routes.dashboard} />;

  return (
    <div className={classes.wrapper}>
      <Card>
        <CardContent>
          <div className={classes.logo}>
            <img src={logo} alt="Carmen Logo" />
          </div>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} onChange={() => {
            clearError()
            setErrorMessage(null);
          }}>
            <InputContainer label="USUARIO">
              <TextField
                placeholder="Ingresa tu usuario"
                inputRef={register({ required: true })}
                disabled={formState.isSubmitting}
                error={!!errors.username}
                InputProps={{
                  endAdornment: !!errors.username ? (
                    <InputAdornment position="end">
                      <IconButton
                        id={"clear_username"}
                        size="small"
                        onClick={() => clearInput("username")}
                      >
                        <CcIcon name="x"/>
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <CcIcon name="mail"/>
                    </InputAdornment>
                  ),
                }}
                name="username"
              />
            </InputContainer>

            <InputContainer label="CONTRASEÑA">
              <TextField
                placeholder="Ingresa tu contraseña"
                variant="outlined"
                type={showPwd ? "text" : "password"}
                name="password"
                inputRef={register({ required: true })}
                disabled={formState.isSubmitting}
                error={!!errors.password}
                InputProps={{
                  endAdornment: !!errors.password ? (
                    <InputAdornment position="end">
                      <IconButton
                        id={"clear_password"}
                        size="small"
                        onClick={() => clearInput("password")}
                      >
                        <CcIcon name="x"/>
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <IconButton
                        id={"toggle_password"}
                        size="small"
                        onClick={() => toggleShowPassword()}
                      >
                        {showPwd ? <CcIcon name="eye"/> : <CcIcon name="eye-off"/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputContainer>
            {errorMessage && (
              <Typography
                variant="body2"
                color="error"
                className={classes.error}
              >
                <CcIcon name="alert-triangle" size={14}/> {errorMessage}
              </Typography>
            )}
            <Button
              fullWidth
              startIcon={formState.isSubmitting ? <Spinner color="disabled"/> : <CcIcon name="arrow-right"/>}
              size="large"
              id="submit_login"
              variant="contained"
              color="primary"
              type="submit"
              disabled={formState.isSubmitting || !formState.isValid}
            >
              Ingresar
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import useCases from "_domain";

import Student from "_domain/student/entities/Student";
import Button from "components/Button";
import Spinner from "components/Spinner";
import Modal from "@material-ui/core/Modal";
import Semaphore from "_domain/alert_dashboard/entities/Semaphore";
import SemaphoreEvolution, {
  SemaphoreEvolutionAlert,
} from "_domain/alert_dashboard/entities/SemaphoreEvolution";
import DashboardHeaders from "_domain/alert_dashboard/entities/DashboardHeaders";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    alignItems: "flex-end",
    gridGap: 8,
  },
  header2: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    alignItems: "flex-end",
    gridGap: 3,
    //rowGap: 10,
    //columnGap: 30,
    borderSpacing: 10,
  },
  header3: {
    display: "grid",
    gridTemplateColumns: "auto auto auto 2fr",
    alignItems: "center",
    gridGap: 8,
  },
  header4: {
    display: "grid",
    gridTemplateColumns: "20% 80%",
    alignItems: "center",
    gridGap: 8,
    columnGap: 30,
  },
  indicador: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    columnGap: 10,
    alignItems: "center",
  },
  indicador2: {
    display: "grid",
    gridTemplateRows: "auto auto",
    alignItems: "center",
    border: "1px solid gray",
    borderRadius: "9px",
    height: "auto",
    width: "100%",
    padding: "10px",
  },
  subtitulo: {
    textAlign: "center",
  },
  indicadorLabel: {
    fontWeight: "bold",
    textAlign: "right",
  },
  indicadorModalidad: {
    fontWeight: "bold",
    textAlign: "center",
  },
  indicadorValueWhite: {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
    verticalAlign: "top",
    color: "black",
    border: "1px solid #BFBFBF",
    borderRadius: "3px",
    backgroundColor: "white",
    width: 80,
    height: 17,
  },
  indicadorValueColor: {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
    verticalAlign: "top",
    color: "black",
    border: "1px solid #BFBFBF",
    borderRadius: "3px",
    width: 80,
    height: 17,
  },

  tabInferior: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignContent: "left",
    alignItems: "left",
    gridGap: 1,
    columnGap: 35,
  },
  tabInferiorIzq: {
    display: "grid",
    gridTemplateRows: "auto auto auto auto",
    alignItems: "flex-end",
    gridGap: 3,
  },
  indicadorValueWhite2: {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
    color: "black",
    border: "1px solid #BFBFBF",
    borderRadius: "7px",
    backgroundColor: "white",
    width: 160,
    height: 50,
  },
  tabEvolucion: {
    display: "grid",
    gridTemplateRows: "auto auto",
    alignItems: "left",
    gridGap: 1,
    verticalAlign: "top",
  },
  tabEvolucionInt: {
    display: "grid",
    gridTemplateRows: "auto auto auto auto",
    alignItems: "flex-end",
    gridGap: 15,
    verticalAlign: "top",
    borderLeft: "1px solid #5B9BD5",
    borderBottom: "1px solid #5B9BD5",
  },
  tabEvolucionWeek: {
    display: "grid",
    gridTemplateColumns:
      "auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto ",
    alignItems: "left",
    gridGap: 1,
    columnGap: 1,
  },
  indicadorEvol: {
    textAlign: "center",
    verticalAlign: "top",
    color: "black",
    border: "1px solid #BFBFBF",
    borderRadius: "3px",
    width: 25,
    height: 15,
  },
  indicadorEvolEnum: {
    textAlign: "center",
    color: "#1F4E79",
    width: 25,
    height: 15,
  },

  divlink: {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
    verticalAlign: "middle",
    color: "black",
    border: "1px solid #BFBFBF",
    borderRadius: "3px",
    backgroundColor: "white",
    width: 80,
    height: 35,
  },

  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    alignContent: "center",
  },
  academic_alert: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#748e98",
    padding: "100px",
    "& img": {
      marginTop: 40,
      height: 80,
    },
  },
}));

export default function ReporteDesercion(props: {
  student: Student;
  dashboard_headers: DashboardHeaders;
}) {
  const classes = useStyles();
  const { student, dashboard_headers } = props;
  const [toggleModal, setToggleModal] = useState(false);
  const [loadEnd, setLoadEnd] = useState(false);
  const [loadEvolEnd, setLoadEvolEnd] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const closeModal = () => {
    setToggleModal(false);
  };
  const openModal = () => {
    setToggleModal(true);
  };

  function getSemaphore() {
    var semaphore: Semaphore[] = [];
    useCases.get_semaphore.execute(student.id).then((obj: Semaphore) => {
      semaphore.push(obj);
      setLoadEnd(true);
    });
    return semaphore;
  }
  const semaphore = useMemo(() => {
    const data = getSemaphore();
    return data;
  }, []);
  function getSemaphoreColor(indicador: string) {
    if (semaphore.length > 0 && !(semaphore[0] === undefined)) {
      return semaphore[0].data.find((al) => {
        return al.indicator === indicador;
      })?.colour;
    }
  }
  function getSemaphoreValue(indicador: string) {
    if (semaphore.length > 0 && !(semaphore[0] === undefined)) {
      return semaphore[0].data.find((al) => {
        return al.indicator === indicador;
      })?.value;
    }
  }
  function validaSemaphore() {
    try {
      return semaphore[0].data.length > 0;
    } catch (e) {
      return false;
    }
  }

  function getSemaphoreEvolution() {
    var semaphoreEvolution: SemaphoreEvolutionAlert[] = [];
    useCases.get_semaphoreEvolution
      .execute(student.id, "7")
      .then((obj: SemaphoreEvolution) => {
        try {
          obj.data.map((evol) => {
            semaphoreEvolution.push(evol);
          });
          setLoadEvolEnd(true);
        } catch (e) {
          return semaphoreEvolution;
        }
      });
    return semaphoreEvolution;
  }
  const semaphoreEvolution = useMemo(() => {
    const data = getSemaphoreEvolution();
    return data;
  }, []);

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  return (
    <div>
      {!loadEnd ? (
        <div className={classes.header3}>
          <b>Cargando Dashboard</b>
          <Spinner color="disabled" size={30} />
        </div>
      ) : !validaSemaphore() ? (
        <div className={classes.header}>
          <b>El alumno, actualmente, no cuenta información de dashboard.</b>
        </div>
      ) : (
        <div>
          <div className={classes.header3}>
            <div>Probabilidad de deserción</div>
            <div
              className={classes.indicadorValueColor}
              style={{
                backgroundColor: getSemaphoreColor("DESERCION"),
                color: "white",
              }}
            >
              {getSemaphoreValue("DESERCION")}
            </div>
            <Link data-cy="go_student_portal">
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  openModal();
                }}
              >
                Ver Detalle
              </Button>
            </Link>
            {/* <div className={classes.indicadorValueWhite2}>Reinscrito - Aplazado xx xxxxxxx</div>
              <div className={classes.indicadorValueWhite2}>Axxxx</div> */}
          </div>

          <hr></hr>

          <div className={classes.header}>
            <div>
              <b>Indicadores de Riesgo</b>
            </div>
          </div>

          <div className={classes.header4}>
            <div>
              <div className={classes.indicador2}>
                <div className={classes.indicadorModalidad}>
                  Modalidad: {dashboard_headers.modalidad}
                </div>
              </div>
            </div>

            <div className={classes.header2}>
              <div className={classes.subtitulo}>
                {" "}
                <u>Rendimiento Académico</u>{" "}
              </div>
              <div className={classes.subtitulo}>
                {" "}
                <u>Asistencia / Deuda</u>{" "}
              </div>
              <div className={classes.subtitulo}>
                {" "}
                <u>Uso Canvas (LMS)</u>{" "}
              </div>

              <div>
                <div className={classes.indicador}>
                  <div className={classes.indicadorLabel}>Promedio notas</div>
                  {/*<div className={classes.indicadorValueColor} style={{ backgroundColor: !(alerta[0] === undefined) ? alerta[0].promedioNotasColor: "" }} >*/}
                  {/*<div className={classes.indicadorValueColor} style={{ backgroundColor: !(alerta[0] === undefined) ? getColorA(alerta[0].promedioNotasCalif): "" }} >*/}
                  <div
                    className={classes.indicadorValueColor}
                    style={{
                      backgroundColor: getSemaphoreColor("PROMEDIO NOTAS"),
                      color: "white",
                    }}
                  >
                    {getSemaphoreValue("PROMEDIO NOTAS")}
                  </div>
                </div>
              </div>
              <div>
                <div className={classes.indicador}>
                  <div className={classes.indicadorLabel}>Asistencia</div>
                  <div
                    className={classes.indicadorValueColor}
                    style={{
                      backgroundColor: getSemaphoreColor("ASISTENCIA"),
                      color: "white",
                    }}
                  >
                    {getSemaphoreValue("ASISTENCIA")}
                  </div>
                </div>
              </div>
              <div>
                <div className={classes.indicador}>
                  <div className={classes.indicadorLabel}>Tiempo conexión</div>
                  <div
                    className={classes.indicadorValueColor}
                    style={{
                      backgroundColor: getSemaphoreColor("TIEMPO CONEXION"),
                      color: "white",
                    }}
                  >
                    {getSemaphoreValue("TIEMPO CONEXION")}
                  </div>
                </div>
              </div>

              <div>
                <div className={classes.indicador}>
                  <div className={classes.indicadorLabel}>Cursos por 3ra</div>
                  <div
                    className={classes.indicadorValueColor}
                    style={{
                      backgroundColor: getSemaphoreColor("CURSOS POR 3RA"),
                      color: "white",
                    }}
                  >
                    {getSemaphoreValue("CURSOS POR 3RA")}
                  </div>
                </div>
              </div>
              <div>
                <div className={classes.indicador}>
                  <div className={classes.indicadorLabel}>Deuda</div>
                  <div
                    className={classes.indicadorValueColor}
                    style={{
                      backgroundColor: getSemaphoreColor("DEUDA"),
                      color: "white",
                    }}
                  >
                    {getSemaphoreValue("DEUDA")}
                  </div>
                </div>
              </div>
              <div>
                {/*<div className={classes.indicador}>
                                <div className={classes.indicadorLabel}>Participación foros</div>
                                <div className={classes.indicadorValueColor} style={ { backgroundColor: getSemaphoreColor(""), color: "white" } } >
                                    { getSemaphoreValue("") }
                                </div>   
                            </div>*/}
              </div>

              <div>
                <div className={classes.indicador}>
                  <div className={classes.indicadorLabel}>Retadores</div>
                  <div
                    className={classes.indicadorValueColor}
                    style={{
                      backgroundColor: getSemaphoreColor("RETADORES"),
                      color: "white",
                    }}
                  >
                    {getSemaphoreValue("RETADORES")}
                  </div>
                </div>
              </div>
              <div>
                <div className={classes.indicador}>
                  <div className={classes.indicadorLabel}># Cuotas</div>
                  <div
                    className={classes.indicadorValueColor}
                    style={{
                      backgroundColor: getSemaphoreColor("CUOTAS"),
                      color: "white",
                    }}
                  >
                    {getSemaphoreValue("CUOTAS")}
                  </div>
                </div>
              </div>
              <div>
                <div className={classes.indicador}>
                  <div className={classes.indicadorLabel}>Entrega Tareas</div>
                  <div
                    className={classes.indicadorValueColor}
                    style={{
                      backgroundColor: getSemaphoreColor("ENTREGA TAREAS"),
                      color: "white",
                    }}
                  >
                    {getSemaphoreValue("ENTREGA TAREAS")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal open={toggleModal} onClose={closeModal}>
            <div style={modalStyle} className={classes.paper}>
              {!loadEvolEnd ? (
                <div className={classes.header3}>
                  <b>Cargando Evolución semanal</b>
                  <Spinner color="disabled" size={30} />
                </div>
              ) : (
                <div className={classes.tabInferior}>
                  <div className={classes.tabEvolucion}>
                    <div className={classes.indicadorLabel}>
                      Evolución semanal – probabilidad de deserción
                    </div>
                    <div className={classes.tabEvolucionInt}>
                      <div className={classes.tabEvolucionWeek}>
                        <div> </div>{" "}
                        {semaphoreEvolution.map((evol) =>
                          evol.value.toUpperCase() === "ALTO" ? (
                            <div
                              className={classes.indicadorEvol}
                              style={{ backgroundColor: evol.colour }}
                            ></div>
                          ) : (
                            <div className={classes.indicadorEvolEnum}></div>
                          )
                        )}{" "}
                      </div>
                      <div className={classes.tabEvolucionWeek}>
                        <div> </div>{" "}
                        {semaphoreEvolution.map((evol) =>
                          evol.value.toUpperCase() === "MEDIO" ? (
                            <div
                              className={classes.indicadorEvol}
                              style={{ backgroundColor: evol.colour }}
                            ></div>
                          ) : (
                            <div className={classes.indicadorEvolEnum}></div>
                          )
                        )}{" "}
                      </div>
                      <div className={classes.tabEvolucionWeek}>
                        <div> </div>{" "}
                        {semaphoreEvolution.map((evol) =>
                          evol.value.toUpperCase() === "BAJO" ? (
                            <div
                              className={classes.indicadorEvol}
                              style={{ backgroundColor: evol.colour }}
                            ></div>
                          ) : (
                            <div className={classes.indicadorEvolEnum}></div>
                          )
                        )}{" "}
                      </div>
                    </div>
                    <div className={classes.tabEvolucionWeek}>
                      <div> </div>{" "}
                      {semaphoreEvolution.map((evol, index) => (
                        <div className={classes.indicadorEvolEnum}>
                          <b>{evol.week}</b>
                        </div>
                      ))}{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

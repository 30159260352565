import StudentRepository from "../infrastructure/StudentRepository";
import Student from "../entities/Student";
import DocumentRepository from "../infrastructure/DocumentRepository";

export default class GetDocumentsUseCase {
  documentRepository: DocumentRepository;

  constructor(documentRepository: DocumentRepository) {
    this.documentRepository = documentRepository;
  }
  async execute() {
    const documents = await this.documentRepository.getDocuments();
    return documents;
  }
}

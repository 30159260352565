import HTTPStudentRepository from "../infrastructure/HTTPStudentRepository";
import RecoveryStudentUseCase from "./GetStudentUseCase";
import SearchStudentUseCase from "./SearchStudentUseCase";
import SendTypification from "./SendTypification";
import GetFiltersUseCase from "./GetFiltersUseCase";
import HTTPDocumentRepository from "../infrastructure/HTTPSDocumentRepository";
import GetDocumentsUseCase from "./GetDocumentsUseCase";
import GetTypifications from "./GetTypifications";
import SendTypificationAnonymous from "./SendTypificationAnonymous";
import GetCoursesByPeriodUseCase from "./GetCoursesByPeriodUseCase";
import GetDetailPeriodUseCase from "./GetDetailPeriodUseCase";
import GetFinancialPeriodDataUseCase from "./GetFinancialPeriodDataUseCase";
import UpdatePrivacityUseCase from "./UpdatePrivacityUseCase";

const httpStudentRepository = new HTTPStudentRepository();
const httpDocumentRepository = new HTTPDocumentRepository();

const studentUseCases = {
  getStudentUseCase: () => new RecoveryStudentUseCase(httpStudentRepository),
  updatePrivacity: () => new UpdatePrivacityUseCase(httpStudentRepository),
  getDetailPeriod: () => new GetDetailPeriodUseCase(httpStudentRepository),
  getCoursesByPeriod: () =>
    new GetCoursesByPeriodUseCase(httpStudentRepository),
  getFinancialData: () =>
    new GetFinancialPeriodDataUseCase(httpStudentRepository),
  searchStudentUseCase: () => new SearchStudentUseCase(httpStudentRepository),
  sendTypificationUseCase: () => new SendTypification(httpStudentRepository),
  sendTypificationAnonymousUseCase: () =>
    new SendTypificationAnonymous(httpStudentRepository),
  getTypificationsUseCase: () => new GetTypifications(httpStudentRepository),
  getFiltersUseCase: () => new GetFiltersUseCase(httpDocumentRepository),
  getDocumentsUseCase: () => new GetDocumentsUseCase(httpDocumentRepository),
};

export default studentUseCases;

type SemaphoreEvolutionOptions = {
    data: Array<SemaphoreEvolutionAlert>;
  };
  
  export type SemaphoreEvolutionAlert = {
    id_indicator: number;
    indicator: string;
    colour: string;
    value: string;
    week: number;
  }
  
  export default class SemaphoreEvolution {
    data: Array<SemaphoreEvolutionAlert>;
    constructor(data: SemaphoreEvolutionOptions) {
      this.data = data.data;
    }
  }
import React, { useEffect, useState, useMemo } from "react";
import InputContainer from "components/InputContainer";
import DocumentComponent from "components/Document";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useForm } from "react-hook-form";
import DocumentFilterEntity from "_domain/student/entities/DocumentFilter";
import DocumentEntity from "_domain/student/entities/Document";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  filters: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "20px",
  },
  documents: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "20px",
  },
}));

function renderOptions(options: Array<{ label: string; value: string }>) {
  return options.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));
}

type DocumentsProps = {
  documents: Array<DocumentEntity>;
  filters: Array<DocumentFilterEntity>;
  defaultCampus: string;
  defaultGrade: string;
};

export default function Documents(props: DocumentsProps) {
  const classes = useStyles();
  const { documents, filters } = props;
  const { control, watch } = useForm<{ grade: string; campus: string }>({
    mode: "onChange",
    defaultValues: {
      grade: props.defaultGrade,
      campus: props.defaultCampus,
    },
  });

  const gradeSelected = watch("grade");
  const campusSelected = watch("campus");

  const spring = {
    type: "spring",
    damping: 20,
    stiffness: 300,
  };

  const documentsFiltered = useMemo(filterDocuments, [
    gradeSelected,
    campusSelected,
  ]);

  function filterDocuments() {
    return (
      documents.filter((document) => {
        return (
          document.campus === campusSelected && document.grade === gradeSelected
        );
      }) || []
    );
  }

  return (
    <div>
      <div className={classes.filters}>
        <InputContainer label="DOCUMENTOS DE">
          <Controller
            as={
              <Select data-cy="filter_1">
                {renderOptions(filters[0].options)}
              </Select>
            }
            name="grade"
            rules={{ required: true }}
            fullWidth
            variant="outlined"
            control={control}
          />
        </InputContainer>
        <InputContainer label="SEDE">
          <Controller
            as={
              <Select data-cy="filter_2">
                {renderOptions(filters[1].options)}
              </Select>
            }
            name="campus"
            rules={{ required: true }}
            fullWidth
            variant="outlined"
            control={control}
          />
        </InputContainer>
      </div>

      <div className={classes.documents} data-cy="documents_list">
        {documentsFiltered.map((doc) => (
          <motion.div
            key={doc.id}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -25 }}
          >
            <DocumentComponent
              icon={doc.icon}
              title={doc.type_name}
              url={doc.url}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AcademicStatus from "./AcademicStatus";
import Student from "_domain/student/entities/Student";
import AccountStatus from "./AccountStatus";
import RequestsList from "./RequestsList";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "24px 0",
  },
}));

export default function DetailedInfo(props: { student: Student }) {
  const classes = useStyles();
  const menu = [
    {
      label: "Estado de Cuenta",
      component: <AccountStatus student={props.student} />,
    },
    {
      label: "Estado Académico",
      component: <AcademicStatus student={props.student} />,
    },
    {
      label: "Solicitudes",
      component: <RequestsList student={props.student} />,
    },
  ];

  const labels = menu.map((item) => item.label);

  const [tabSelected, setTabSelected] = useState(1);

  return (
    <div>
      <Tabs
        labels={labels}
        value={tabSelected}
        onChange={(newValue) => setTabSelected(newValue)}
      />
      <div className={classes.content} data-cy="info_container">
        {menu[tabSelected].component}
      </div>
    </div>
  );
}

type TabsProps = {
  labels: string[];
  value: number;
  onChange(newValue: number): void;
};

const useStylesTabs = makeStyles((theme) => ({
  tab: {
    padding: "20px 30px",
  },
}));

function Tabs(props: TabsProps) {
  const classes = useStylesTabs();
  return (
    <div data-cy="tabs_container">
      {props.labels.map((label, index) => {
        const isSelected = index === props.value;
        return (
          <ButtonBase
            key={label}
            data-cy="tabs_item"
            onClick={() => props.onChange(index)}
            className={classes.tab}
            style={{ borderBottom: isSelected ? "2px solid #4a4a4a" : "none" }}
          >
            <Typography variant="h4">{label}</Typography>
          </ButtonBase>
        );
      })}
    </div>
  );
}

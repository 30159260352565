import React, { ReactNode, useEffect, useState, useMemo } from "react";
import Session from "_domain/session/entities/SessionEntity";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table, { TableProps } from "components/Table";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useRouteMatch, Redirect } from "react-router-dom";
import InputContainer from "components/InputContainer";
import { motion } from "framer-motion";
import request_alert from "assets/images/request_alert.png";
import useCases from "_domain";
import CampaignChannel from "_domain/campaign/entities/CampaignChannel";
import CampaignTypeAttention from "_domain/campaign/entities/CampaignTypeAttention";
import CampaignSubCategory from "_domain/campaign/entities/CampaignSubCategory";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: "25px 100px",
    },
    channelSelector: {
        display: "grid",
        gridTemplateColumns: "200px auto",
        gridGap: 8,
        alignItems: "center",
    },
    header: {
        display: "grid",
        gridTemplateColumns: "1fr auto auto auto auto auto",
        alignItems: "flex-end",
        gridGap: 8,
    },
    table: {
        backgroundColor: "#f9fbff",
        marginTop: 16,
        borderRadius: 8,
        boxShadow: theme.shadows[1],
        overflow: "hidden",
    },
    academic_alert: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#748e98",
        padding: "100px",
        "& img": {
            marginTop: 40,
            height: 80,
        },
    },
}));

export default function CampaignMnt(props: { session: Session; }) {
    const match = useRouteMatch();
    const session = props.session;
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        errors,
        setError,
        formState,
        reset,
        clearError,
        setValue,
        watch,
        control,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            channel: "whatsapp-out",
        },
    });

    const [errorMessage, setErrorMessage] = useState(null);

    function filterTypeOptions(channelValue: string) {
        const channel = session.forms.inbound.channel.options.find(
            (c) => c.value === channelValue
        );
        if (!channel) return [];
        return session.forms.inbound.attention_type.options.filter(
            (typeAttention) => {
                return !!channel.type_attention.find((o) => o === typeAttention.value);
            }
        );
    }

    const channelSelected = watch("channel");
    //const channelSelected = "whatsapp-out";

    var attention_type_options = useMemo(() => {
        const options = filterTypeOptions(channelSelected);
        return options;
    }, [channelSelected]);

    function renderOptions(
        options: Array<{ label: string; value: string }>,
        placeholder?: string
    ) {
        return options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ));
    }

    function fn_editar(item: { label: string; value: string; filter_subcategory: boolean }) {
        window.localStorage.setItem("sess_campaignmnt_canal", channelSelected);
        window.localStorage.setItem("sess_campaignmnt_type_attention", item.value);
        window.localStorage.setItem("sess_campaignmnt_operacion", "U");
        setValue("registrado", "1", true);
    }

    async function fn_save_desahabilitar(item: { label: string; value: string; filter_subcategory: boolean }) {
        session.forms.inbound.channel.options.find(
            (ch) => {
                if (ch.value === channelSelected) {
                    ch.type_attention.splice(ch.type_attention.indexOf(item.value), 1);
                }
            }
        );
        const jsonChannel = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.channel.options) + ',"multiple": false,"searchable": false}}';
        const objChannel = new CampaignChannel(JSON.parse(jsonChannel));
        const resp = await saveChannel(objChannel);
        return resp;
    }

    async function fn_desahabilitar(item: { label: string; value: string; filter_subcategory: boolean }) {
        var resp = window.confirm("está seguro que quiere desahabilitar esta campaña");

        if (resp) {
            fn_save_desahabilitar(item);
        }
        window.localStorage.setItem("sess_campaignmnt_canal_temp", channelSelected);
        setValue("channel", "", true);
       
    }

    if (!(window.localStorage.getItem("sess_campaignmnt_canal_temp") === null)) {
        setValue("channel", window.localStorage.getItem("sess_campaignmnt_canal_temp") || "", true);
        window.localStorage.removeItem("sess_campaignmnt_canal_temp");
    }

    function recarga(chsel: string) {
        setValue("channel",chsel,true);
    }

    async function fn_save_eliminar(item: { label: string; value: string; filter_subcategory: boolean }) {
        //-- Eliminar Subcategory
        var motivoEncontrado = session.forms.inbound.subcategory.options.findIndex(elemento => elemento.type_attention === item.value);
        while (motivoEncontrado >= 0) {
            session.forms.inbound.subcategory.options.splice(motivoEncontrado, 1);
            motivoEncontrado = session.forms.inbound.subcategory.options.findIndex(elemento => elemento.type_attention === item.value);
        }
        const jsonSubcategory = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.subcategory.options) + ',"multiple": false,"searchable": true}}';
        const objSubcategory = new CampaignSubCategory( JSON.parse(jsonSubcategory) );
        saveSubcategory(objSubcategory);

        //-- Eliminar Type Attention
        session.forms.inbound.attention_type.options.splice(session.forms.inbound.attention_type.options.indexOf(item),1);
        const jsonTypeAttention = '{ "properties": { "options":' + JSON.stringify(session.forms.inbound.attention_type.options) + ',"multiple": false,"searchable": false}}';
        const objTypeAttention = new CampaignTypeAttention( JSON.parse(jsonTypeAttention) );
        const resp = saveTypeAttention(objTypeAttention);
        return resp;
    }

    async function fn_eliminar(item: { label: string; value: string; filter_subcategory: boolean }) {

        var resp = window.confirm("está seguro que quiere eliminar esta campaña");

        if (resp) {
            var resp_save = fn_save_eliminar(item);
            if (!resp_save) {
                alert("No se pudo eliminar la campaña");
            } else {
                resp_save = fn_save_desahabilitar(item);
                if (!resp_save) {
                    alert("No se pudo eliminar la campaña");
                }
            }
            window.localStorage.setItem("sess_campaignmnt_canal_temp", channelSelected);
            setValue("channel", "", true);
        }
    }

    async function saveChannel(channel: CampaignChannel) {
        let resultado = await useCases.save_channel.execute(channel);
        if (!resultado) {
            alert("Error registrando Channel");
        }
        return resultado;
    }

    async function saveTypeAttention(typeAttention: CampaignTypeAttention) {
        let resultado = await useCases.save_typeAttention.execute(typeAttention);
        if (!resultado) {
            alert("Error registrando TypeAttention");
        }
        return resultado;
    }

    async function saveSubcategory(subcategory: CampaignSubCategory) {
        let resultado = await useCases.save_subcategory.execute(subcategory);
        if (!resultado) {
            alert("Error registrando Subcategory");
        }
        return resultado;
    }

    const tableProps: TableProps = {
        /*data: [
            {"label":"Inasistencias","opt_edit":"<edit>","opt_deshab":"<deshab>","opt_delete":"<del>"},
            {"label":"Poca Frecuencia","opt_edit":"<edit>","opt_deshab":"<deshab>","opt_delete":"<del>"}
        ],*/
        data: attention_type_options.map((option) => ({ "label": option.label })),
        headers: [
            { label: "Nombre Campaña", accessor: "label" },
            {
                label: "Editar",
                renderCell: (index) => {
                    return (
                        <Button
                            color="primary"
                            onClick={() => {
                                fn_editar(attention_type_options[index]);
                            }}
                            >
                            Editar
                        </Button>
                    );
                },
            },
            {
                label: "Deshabilitar",
                renderCell: (index) => {
                    return (
                        <Button
                            color="primary"
                            onClick={() => {
                                fn_desahabilitar(attention_type_options[index]);
                            }}
                            >
                            Deshabilitar
                        </Button>
                    );
                },
            },
            {
                label: "Eliminar",
                renderCell: (index) => {
                    return (
                        <Button
                            color="primary"
                            onClick={() => {
                                fn_eliminar(attention_type_options[index]);
                            }}
                            >
                            Eliminar
                        </Button>
                    );
                },
            },
        ],
    };

    function sendCrear() {
        if (channelSelected === undefined) {
            alert("Debe seleccionar un canal")
        } else {
            window.localStorage.setItem("sess_campaignmnt_canal", channelSelected);
            window.localStorage.setItem("sess_campaignmnt_operacion", "C");
            window.localStorage.removeItem("sess_campaignmnt_type_attention");
            setValue("registrado", "1", true);
        }
    }

    function sendHabilitar() {
        if (channelSelected === undefined) {
            alert("Debe seleccionar un canal")
        } else {
            window.localStorage.setItem("sess_campaignmnt_canal", channelSelected);
            window.localStorage.removeItem("sess_campaignmnt_type_attention");
            setValue("registrado", "2", true);
        }
    }

    const registrado = watch("registrado")
    if (registrado === "1") return <Redirect to="/dashboard/inbound/campaignMntFormAttention" />;
    if (registrado === "2") return <Redirect to="/dashboard/inbound/campaignMntFormHabilitar" />;

    return (
        <motion.div initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>

            <div className={classes.content}>
                <div>
                    <Typography variant="h4" align="center" style={{ marginBottom: 24 }}> Mantenimiento de Campañas </Typography>
                </div>

                <div className={classes.channelSelector}>
                    <div>
                        <InputContainer label="Canal:">
                            <Controller
                                as={
                                    <Select>
                                        {renderOptions(session.channelOptionsAvailable)}
                                    </Select>
                                }
                                error={errors.channel}
                                name="channel"
                                fullWidth
                                rules={{ required: true }}
                                variant="outlined"
                                control={control}
                            />
                        </InputContainer>
                    </div>
                </div>

                <div className={classes.header}>
                    <div></div>
                    <div>
                        <Button
                            size="large"
                            id="submit_login"
                            variant="contained"
                            color="primary"
                            onClick={sendCrear}
                            >
                            Crear
                        </Button>
                    </div>
                    <div>
                        <Button
                            size="large"
                            id="submit_login"
                            variant="contained"
                            color="primary"
                            onClick={sendHabilitar}
                            >
                            Habilitar
                        </Button>
                    </div>
                </div>
                <div className={classes.table}>
                    <div>
                        {!(channelSelected === undefined) && 
                            <Table data-cy="table_type_attention" {...tableProps} />
                        }
                        {(channelSelected === undefined) &&
                            (
                                <div className={classes.academic_alert}>
                                    <Typography variant="subtitle1">
                                        No ha seleccionado un canal
                                    </Typography>
                                    <img src={request_alert} alt="financial alert" />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <TextField type="hidden" name="registrado" placeholder="" inputRef={register({ required: true })} value="0" />
        </motion.div>
    );
}
import sessionUseCases from "./session/use-cases";
import studentUseCases from "./student/use-cases";
import campaignUseCases from "./campaign/use-cases";
import alertDashboardUseCases from "./alert_dashboard/use-cases";
import alertTagUseCases from "./alerts/use-cases";

const useCases = {
  login_use_case: sessionUseCases.signInUseCase(),
  recovery_session_use_case: sessionUseCases.recoverySessionUseCase(),
  refreshToken_use_case: sessionUseCases.refreshTokenUseCase(),
  log_out_use_case: sessionUseCases.logOutUseCase(),
  change_channel_selected: sessionUseCases.changeChannelSelected(),
  search_student: studentUseCases.searchStudentUseCase(),
  get_detail_period: studentUseCases.getDetailPeriod(),
  get_financial_data: studentUseCases.getFinancialData(),
  get_student: studentUseCases.getStudentUseCase(),
  update_privacity: studentUseCases.updatePrivacity(),
  get_courses_by_period: studentUseCases.getCoursesByPeriod(),
  send_typification: studentUseCases.sendTypificationUseCase(),
  send_typification_anonymous:
    studentUseCases.sendTypificationAnonymousUseCase(),
  get_documents: studentUseCases.getDocumentsUseCase(),
  get_filters: studentUseCases.getFiltersUseCase(),
  get_typifications: studentUseCases.getTypificationsUseCase(),

  save_campaign: campaignUseCases.saveCampaign(),
  save_subcategory: campaignUseCases.saveSubcategory(),
  save_typeAttention: campaignUseCases.saveTypeAttention(),
  save_channel: campaignUseCases.saveChannel(),
  get_category: campaignUseCases.getCategory(),

  get_semaphore: alertDashboardUseCases.getSemaphore(),
  get_semaphoreEvolution: alertDashboardUseCases.getSemaphoreEvolution(),
  get_dashboardHeaders: alertDashboardUseCases.getDashboardHeaders(),

  post_alerts: alertTagUseCases.postAlerts(),
};

export default useCases;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "8px 16px",
    borderRadius: 4,
    border: "1px solid #ccd6dc",
    color: "#748e98",
  },
  value: {
    color: theme.palette.primary.main,
    marginLeft: 16,
  },
}));

type SquareInfoProps = {
  label: string;
  value?: string;
  fullWith?: boolean;
};

export default function SquareInfo(props: SquareInfoProps) {
  const { fullWith, value, label, ...rest } = props;
  const classes = useStyles();
  return (
    <div
      {...rest}
      className={classes.wrapper}
      style={{ display: fullWith ? "block" : "inline-block" }}
    >
      <Typography variant="body2">
        <span>{label}</span>
        {value && <span className={classes.value}>{value}</span>}
      </Typography>
    </div>
  );
}

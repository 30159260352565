import CampaignRepository from "./../infrastructure/CampaignRepository";
import Campaign from "./../entities/Campaign";

export default class SaveCampaignUseCase {

    campaignRepository: CampaignRepository;

    constructor(campaignRepository: CampaignRepository) {
        this.campaignRepository = campaignRepository;
    }

    async execute(data: Campaign) {
        const campaign = await this.campaignRepository.save(data);
        return campaign;
    }
}
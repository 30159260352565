import SessionRepository from "../infrastructure/SessionRepository";
import Session from "../entities/SessionEntity";
export default class RecoverySessionUseCase {
  sessionRepository: SessionRepository;
  constructor(sessionRepository: SessionRepository) {
    this.sessionRepository = sessionRepository;
  }

  async execute() {
    // TODO: Mover al responsitio y eliminar token hardcoded
    const tokenStoraged = window.localStorage.getItem("carmen-token");
    if (tokenStoraged) {
      const sessionRecovered = await this.sessionRepository.recoveryByToken({
        value: tokenStoraged,
      });
      return sessionRecovered;
    }
    return null;
  }
}

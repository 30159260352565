import React from "react";
import cloud_surprised from "assets/images/cloud_surprised.png";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 240,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function EmptyRecords() {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <img src={cloud_surprised} alt="cloud surprised" style={{marginBottom: 32, height: 120}} />
      <Typography variant="h4">
        A la fecha, no se han registrado atenciones
      </Typography>
    </div>
  );
}

import { AuthorizationError } from "./errors";
import jwt_decode from "jwt-decode";

const API_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/";
const UMBRAL_REFRESH: number = parseInt(
  process.env.REACT_APP_UMBRAL_REFRESH || "180"
);

type Options = {
  method?: string;
  headers?: any;
  body?: any;
  cache?: any;
};

export default function fetcher(
  resourcePath: string = "",
  options?: Options
): Promise<Response> {
  // TODO: Fix hardcoded "carmen-token". Enviar al manejo de responsitorio
  const token = localStorage.getItem("carmen-token");

  // Revisa si se necesita refrescar el token
  if (token) {
    const dateNow = new Date();
    const token_decoded = jwt_decode<{ exp: number }>(token);
    const umbral_miliseconds = UMBRAL_REFRESH * 1000;
    if (token_decoded.exp * 1000 - dateNow.getTime() < umbral_miliseconds)
      localStorage.setItem("carmen-refresh-token", "true");
  }

  let header = {
    authorization: `Bearer ${token}`,
    ...(options?.headers?.["Content-Type"]
      ? {}
      : { "Content-Type": "application/json" }),
  };

  return fetch(API_URL + resourcePath, {
    method: options?.method || "GET",
    headers: header,
    cache: options?.cache || undefined,
    body: options?.body,
  })
    .catch(() => {
      throw new Error("Error al conectarse al servidor");
    })
    .then(handleErrors);
}

async function handleErrors(response: Response) {
  let error = null;
  if (response.status === 401) {
    error = await response.json();
    throw new AuthorizationError(
      (error.not_authorized && error.not_authorized[0]) ||
        (error.error_system && error.error_system[0]) ||
        "No se pudo acceder al recurso"
    );
  }
  return response;
}

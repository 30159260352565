import { Route, Redirect, RouteProps } from "react-router-dom";
import React, { ReactNode } from "react";

import { useSession } from "../../hooks/use-auth";

export default function PrivateRoute({
  children,
  ...rest
}: {
  children: ReactNode;
} & RouteProps) {
  const { session } = useSession();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !session ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

type DocumentFilterOptions = {
  options: Array<{ value: string; label: string }>;
  name: string;
};

export default class DocumentFilter {
  options: Array<{ value: string; label: string }>;
  name: string;
  constructor(options: DocumentFilterOptions) {
    this.options = options.options;
    this.name = options.name;
  }
}

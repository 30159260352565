import AlertRepository from "./../infrastructure/AlertRepository"

export default class GetSemaphoreUseCase {

  alertRepository: AlertRepository;

  constructor(alertRepository: AlertRepository) {
    this.alertRepository = alertRepository;
  }

  async execute(id: string) {
    const semaphore = await this.alertRepository.getSemaphore(id);
    return semaphore;
  }
}
import StudentRepository from "../infrastructure/StudentRepository";

export default class SearchStudentUseCase {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(code: string): Promise<string> {
    const studentId = await this.studentRepository.searchStudent(code);
    return studentId;
  }
}

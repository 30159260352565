import { CcIcon } from "@cafeta/components-react";
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { CSVLink } from "react-csv";
import "./styles/style.css";
import useCases from "_domain";
import { Alert, AlertTitle } from "@material-ui/lab";
import Papa from "papaparse";

interface MyProps {}
interface MyState {
  selectedFile: File;
  isSelected: boolean;
  error: boolean;
  errorMsg: string;
  finished: boolean;
  loading: boolean;
}

class AlertsMngmt extends React.Component<MyProps, MyState> {
  state = {
    selectedFile: new File([""], ""),
    isSelected: false,
    error: false,
    errorMsg: "",
    finished: false,
    loading: false,
  };

  csvDataPlantilla = [
    ["CODIGO", "TITULO"],
    ["U20202142", "Alerta discapacidad"],
    ["U18218308", "Beca Peru"],
  ];

  preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  validaFile = (inputFile: File) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = function (e) {
        var text = reader.result;
        //debugger;
        var buf = new Uint8Array(text as any);
        // check for byte order mark
        // 0xef, 0xbb and 0xbf in hex converts to 239, 187 and 191 in decimal
        if (buf[0] === 239 && buf[1] === 187 && buf[2] === 191) {
          reject(false);
        } else {
          //throw new Error("Not found");
          resolve(true);
        }
      };

      reader.readAsArrayBuffer(inputFile);
    });
  };

  validaHeaderFile = (inputFile: File) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      Papa.parse(inputFile, {
        complete: function (results) {
          let firstRow: any = results.data[0];

          if (firstRow[0] == "CODIGO") {
            resolve(true);
          } else {
            reject(false);
          }
        },
      });
    });
  };

  changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      let input = e.target.files[0];
      e.target.value = "";

      try {
        if (await this.validaFile(input)) {
          try {
            if (await this.validaHeaderFile(input)) {
              this.setState({
                error: false,
                selectedFile: input,
                isSelected: true,
                errorMsg: "",
                finished: false,
                loading: false,
              });
            }
          } catch (e) {
            this.setState({
              error: true,
              errorMsg: `El archivo ${input.name} debe tener como cabecera: CODIGO,TITULO`,
            });
          }
        }
      } catch (e) {
        this.setState({
          error: true,
          errorMsg: `El archivo ${input.name} debe tener codificación UTF-8 sin BOM`,
        });
      }
    }
  };

  handleClick = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });
    try {
      await useCases.post_alerts.execute(this.state.selectedFile);

      this.setState({
        selectedFile: new File([""], ""),
        isSelected: false,
        error: false,
        errorMsg: "",
        finished: true,
        loading: false,
      });
    } catch (e) {
      this.setState({
        selectedFile: new File([""], ""),
        isSelected: false,
        error: true,
        errorMsg: e.message,
        finished: false,
        loading: false,
      });
    }
  };

  render() {
    return (
      <motion.div
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <div className="content" id="div-principal">
          <Typography variant="h4" align="center" style={{ marginBottom: 24 }}>
            Carga de Alertas
          </Typography>

          <div>
            <Paper className="paper">
              <div className="tab-content" data-cy="info_container">
                <Collapse in={this.state.finished}>
                  <Alert
                    style={{ marginLeft: 20, marginRight: 20 }}
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            finished: false,
                          });
                        }}
                        color="inherit"
                        size="small"
                      >
                        <CcIcon name="x" />
                      </IconButton>
                    }
                  >
                    Archivo cargado correctamente
                  </Alert>
                </Collapse>
                <Collapse in={this.state.error}>
                  <Alert
                    severity="error"
                    style={{ marginLeft: 20, marginRight: 20 }}
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          this.setState({
                            selectedFile: new File([""], ""),
                            isSelected: false,
                            error: false,
                            errorMsg: "",
                            finished: false,
                            loading: false,
                          });
                        }}
                        color="inherit"
                        size="small"
                      >
                        <CcIcon name="x" />
                      </IconButton>
                    }
                  >
                    <AlertTitle>Error</AlertTitle>
                    {this.state.errorMsg}
                  </Alert>
                </Collapse>

                <div>
                  <div className="content grid">
                    <div className="center">
                      <CSVLink
                        data={this.csvDataPlantilla}
                        filename={"plantilla-carga-alertas.csv"}
                        className="link-csv"
                      >
                        <Button
                          size="small"
                          disabled={this.state.loading}
                          variant="contained"
                          component="label"
                          startIcon={<CcIcon name="download" />}
                        >
                          Plantilla
                        </Button>
                      </CSVLink>
                    </div>

                    <div className="grid-middle">
                      <div className="center">
                        <Button
                          size="small"
                          id="upload_file"
                          disabled={this.state.loading}
                          variant="contained"
                          component="label"
                          startIcon={<CcIcon name="file-plus" />}
                        >
                          Selecciona Archivo
                          <input
                            type="file"
                            accept=".csv"
                            onChange={this.changeHandler}
                            hidden
                          />
                        </Button>
                      </div>
                      <div>
                        {this.state.isSelected ? (
                          <div className="grid-middle">
                            <div>
                              <p>
                                Nombre de archivo:{" "}
                                {this.state.selectedFile.name}
                              </p>
                              <p>
                                Tamaño (bytes): {this.state.selectedFile.size}
                              </p>
                            </div>
                            <div className="center">
                              <Button
                                size="large"
                                id="upload_file"
                                disabled={this.state.loading}
                                variant="contained"
                                color="primary"
                                component="label"
                                onClick={this.handleClick}
                                startIcon={
                                  this.state.loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={30}
                                    />
                                  ) : (
                                    <CcIcon name="check-circle" />
                                  )
                                }
                              >
                                Procesar
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <p>Selecciona un archivo para visualizar detalles</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <p style={{ marginRight: 50, marginLeft: 50 }}>
                    <i>
                      *Verificar que no existan filas duplicadas en el archivo
                      CSV, de lo contrario no se podrán cargar las alertas
                    </i>
                  </p>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default AlertsMngmt;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import cloud_check from "assets/images/cloud_check.svg";
import cloud_surprised from "assets/images/cloud_surprised.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "64px 60px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },
  close: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  content: {
    textAlign: "center",
    width: "80%",
  },
  picture: {
    height: 96,
    marginBottom: 40,
    "& img": {
      height: "100%",
    },
  },
  action: {
    marginTop: 40
  }
}));

type ModalReponseProps = {
  title: string;
  message?: string;
  open: boolean;
  onClose(): void;
  variant: "success" | "error";
  action?: {
    onClick(): void;
    label: string;
  };
};

export default function ModalResponse(props: ModalReponseProps) {
  const classes = useStyles();
  const { action, title, message } = props;
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <div className={classes.wrapper}>
        <div className={classes.close}>
          <IconButton color="primary" onClick={() => props.onClose()}>
            <Close />
          </IconButton>
        </div>
        <picture className={classes.picture}>
          {props.variant === "success" && (
            <img src={cloud_check} alt="cloud-check" />
          )}
          {props.variant === "error" && (
            <img src={cloud_surprised} alt="cloud-surprised" />
          )}
        </picture>
        <div className={classes.content}>
          <Typography variant="h3">{title}</Typography>
          {message && <Typography variant="body1">{message}</Typography>}
        </div>
        {action && (
          <div className={classes.action}>
            <Button
              onClick={() => action.onClick()}
              variant="contained"
              color="primary"
              fullWidth
            >
              {action.label}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

import { dateToDDMMAAAA } from "../../_shared/date";

export type TypificationProps = {
  id: string;
  created_at: Date;
  name: string;
  title: string;
  user: string;
  channel?: { label: string; value: string };
  campaign?: { label: string; value: string };
  typification_type?: { label: string; value: string };
  typification_reason?: { label: string; value: string };
  observations?: { label: string; value: string };
  category?: { label: string; value: string };
  result_call?: { label: string; value: string | null };
};

export default class Typification {
  id: string;
  created_at: Date;
  name: string;
  title: string;
  user: string;
  channel?: { label: string; value: string };
  campaign?: { label: string; value: string };
  typification_type?: { label: string; value: string };
  typification_reason?: { label: string; value: string };
  observations?: { label: string; value: string };
  category?: { label: string; value: string };
  result_call?: { label: string; value: string | null };
  constructor(props: TypificationProps) {
    this.id = props.id;
    this.created_at = props.created_at;
    this.name = props.name;
    this.title = props.title;
    this.user = props.user;
    this.channel = props.channel;
    this.campaign = props.campaign;
    this.typification_type = props.typification_type;
    this.typification_reason = props.typification_reason;
    this.observations = props.observations;
    this.category = props.category;
    this.result_call = props.result_call;
  }
  get object() {
    return {
      id: this.id,
      key: this.id,
      created_at: dateToDDMMAAAA(this.created_at, true),
      name: this.name,
      title: this.title,
      user: this.user,
      channel: this.channel?.value,
      campaign: this.campaign?.value,
      typification_type: this.typification_type?.value,
      typification_reason: this.typification_reason?.value,
      observations: this.observations?.label,
      category: this.category?.label,
      result_call: this.result_call?.label,
    };
  }
}

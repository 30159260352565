import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Lato,sans-serif",
    body1: {
      fontWeight: 300,
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      color: "#748e98",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 900,
      color: "#4a4a4a",
    },
    h4: {
      fontSize: 16,
      fontWeight: 900,
    },
    h5: {
      fontWeight: "bold",
      fontSize: "0.9rem",
    },
    h6: {
      fontWeight: "bold",
      fontSize: "11px",
      textTransform: "uppercase",
    },
    subtitle1: {
      fontSize: 18,
    },
    button: {
      fontSize: 14,
      textTransform: "none",
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: "#24a3ab",
    },
    error: {
      main: "#ff8389",
    },
    text: {
      primary: "#4a4a4a",
      secondary: "#4a4a4a",
    },
    background: {
      paper: "#eef2f6",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          color: "#4a4a4a",
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        //        position: "static"
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "white",
      },
      input: {
        padding: "12px 10px",
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: "16px",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontWeight: "bold",
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: "#219ba3",
        },
        "&:active": {
          backgroundColor: "#1d9198",
        },
      },
      contained: {
        boxShadow:
          "0px 0px 6px 0px rgba(0,0,0,0.04), 0px 8px 16px -6px rgba(0,0,0,0.1)",
        "&:hover": {
          backgroundColor: "#f3f3f3",
          boxShadow:
            "0px 0px 6px 0px rgba(0,0,0,0.04), 0px 6px 8px 0px rgba(0,0,0,0.12)",
        },
        "&:active": {
          backgroundColor: "#e8e8e8",
        },
        backgroundColor: "white",
        color: "#24a3ab",
      },
    },
    MuiSelect: {
      icon: {
        color: "#24a3ab",
      },
    },
    MuiDialog: {},
    MuiDivider: {
      root: {
        backgroundColor: "rgba(204, 214, 220, 0.4)",
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      fullWidth: true,
    },
    MuiButton: {
      disableRipple: true,
    },
  },
  shadows: [
    "none",
    "0px 0px 6px 0px rgba(0,0,0,0.04), 0px 8px 16px -6px rgba(0,0,0,0.1)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
});

export default theme;

import CampaignRepositoryApi from "./../infrastructure/CampaignRepositoryApi";
import SaveCampaignUseCase from "./SaveCampaignUseCase";
import SaveSubcategoryUseCase from "./SaveSubcategoryUseCase";
import SaveTypeAttentionUseCase from "./SaveTypeAttentionUseCase";
import SaveChannelUseCase from "./SaveChannelUseCase";
import GetCategoryUseCase from "./GetCategoryUseCase";

const campaignRepositoryApi = new CampaignRepositoryApi();

const campaignUseCases = {
    saveCampaign: () => new SaveCampaignUseCase(campaignRepositoryApi),
    saveSubcategory: () => new SaveSubcategoryUseCase(campaignRepositoryApi),
    saveTypeAttention: () => new SaveTypeAttentionUseCase(campaignRepositoryApi),
    saveChannel: () => new SaveChannelUseCase(campaignRepositoryApi),
    getCategory: () => new GetCategoryUseCase(campaignRepositoryApi),
};

export default campaignUseCases;
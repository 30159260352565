export function dateToDDMMAAAA(date: Date, addHour?: boolean): string {
  const day = zeroPad(date.getDate(), 2);
  const month = zeroPad(date.getMonth() + 1, 2);
  const year = zeroPad(date.getFullYear(), 2);
  const hour =
    zeroPad(date.getHours(), 2) + ":" + zeroPad(date.getMinutes(), 2);

  const dateString = `${day}/${month}/${year}`;
  if (addHour) return `${dateString} a las ${hour} hrs.`;
  return dateString;
}

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, "0");

import SessionRepository, { Credentials, Token } from "./SessionRepository";
import User from "../../user/entities/User";
import Session from "../../session/entities/SessionEntity";
import fetcher from "../../_shared/fetcher";
import { FormType } from "../entities/FormType";

export default class SessionRepositoyApi implements SessionRepository {
  async create(options: Credentials) {
    const response = await fetcher("auth/login/", {
      method: "POST",
      body: JSON.stringify({
        username: options.username,
        password: options.password,
      }),
    });

    if (!response.ok) throw new Error("Error al iniciar sesión");

    const data = await response.json();

    const userData = data.user;
    const newUser = new User({
      id: userData.id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      groups: userData.groups,
      channel: userData.channel,
      campus: userData.campus,
      email: userData.email,
      collaborator_code: userData.collaborator_code,
    });

    this.storageToken(data.token);

    const forms = await this.getForms(userData.id);

    // Verify channel default selected
    let channelSelected =
      window.localStorage.getItem("carmen-channel-selected") || "";
    const channelSelectedIsValid = !!forms.inbound.channel.options.find(
      (option) => option.value === channelSelected
    );
    if (!channelSelectedIsValid) {
      channelSelected = "";
      window.localStorage.setItem("carmen-channel-selected", channelSelected);
    }

    const newSession = new Session({
      user: newUser,
      token: data.token,
      forms: forms,
      channelSelected: channelSelected,
    });
    return newSession;
  }

  async recoveryByToken(token: Token) {
    const response = await fetcher("user/me/");

    if (!response.ok) throw new Error("Error al recuperar session");

    const data = await response.json();

    const userData = data.user;
    const newUser = new User({
      id: userData.id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      groups: userData.groups,
      channel: userData.channel,
      campus: userData.campus,
      email: userData.email,
      collaborator_code: userData.collaborator_code,
    });

    const forms = await this.getForms(userData.id);

    // Verify channel default selected
    let channelSelected =
      window.localStorage.getItem("carmen-channel-selected") || "";
    const channelSelectedIsValid = !!forms.inbound.channel.options.find(
      (option) => option.value === channelSelected
    );
    if (!channelSelectedIsValid) {
      channelSelected = "";
      window.localStorage.setItem("carmen-channel-selected", channelSelected);
    }

    const newSession = new Session({
      user: newUser,
      token: token.value,
      forms: forms,
      channelSelected: channelSelected,
    });
    return newSession;
  }

  async refreshToken(token: Token) {
    const response = await fetcher("auth/refresh-token/", {
      method: "POST",
      body: JSON.stringify({ token: token.value }),
    });

    if (!response.ok) throw new Error("Error al recuperar session");

    const data = await response.json();

    const userData = data.user;
    const newUser = new User({
      id: userData.id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      groups: userData.groups,
      channel: userData.channel,
      campus: userData.campus,
      email: userData.email,
      collaborator_code: userData.collaborator_code,
    });

    this.storageToken(data.token);

    const forms = await this.getForms(userData.id);

    // Verify channel default selected
    let channelSelected =
      window.localStorage.getItem("carmen-channel-selected") || "";
    const channelSelectedIsValid = !!forms.inbound.channel.options.find(
      (option) => option.value === channelSelected
    );
    if (!channelSelectedIsValid) {
      channelSelected = "";
      window.localStorage.setItem("carmen-channel-selected", channelSelected);
    }

    const newSession = new Session({
      user: newUser,
      token: data.token,
      forms: forms,
      channelSelected: channelSelected,
    });
    return newSession;
  }

  async getForms(id: string): Promise<FormType> {
    const response = await fetcher(`attentions/form/${id}/`);
    let data = await response.json();
    data = data.object;
    if (!response.ok) {
      throw new Error("No se pudo obtener los fomularios");
    }

    const forms: FormType = {
      inbound: {
        channel: {
          options: data.inbound.channel.options,
        },
        subcategory: {
          options: data.inbound.subcategory.options,
        },
        attention_type: {
          options: data.inbound.attention.options,
        },
        result_call: {
          options: data.inbound.resultCall.options,
        },
      },
      inboundnops: {
        campus: {
          label: data.inboundnops.campus.label,
          options: data.inboundnops.campus.options,
        },
        grade: {
          label: data.inboundnops.grade.label,
          options: data.inboundnops.grade.options,
        },
        resultCall: {
          label: data.inboundnops.resultCall.label,
          options: data.inboundnops.resultCall.options,
        },
        subcategory: {
          label: data.inboundnops.subcategory.label,
          options: data.inboundnops.subcategory.options,
        },
      },
      privacy: {
        label: data.privacy.reason.label,
        options: data.privacy.reason.options,
      },
    };

    return forms;
  }

  storageToken(token: string) {
    window.localStorage.setItem("carmen-token", token);
  }

  changeChannelSelected(session: Session, value: string) {
    window.localStorage.setItem("carmen-channel-selected", value);
    session.channelSelected = value;
    return session;
  }
}

import React from "react";
import {motion} from "framer-motion";

type CloudPetProps = {
  state: 'waiting' | 'laugh' | 'smile' | 'poker'
}

export default function CloudPet(props: CloudPetProps) {

  const variants = {
    waiting: {
      fill: "rgb(108,107,113)"
    },
    laugh: {
      fill: "rgb(155,218,214)"
    },
    smile: {
      fill: "rgb(155,218,214)"
    },
    poker: {
      fill: "rgb(155,218,214)"
    }
  }

  const paths = {
    waiting: {
      d: "M33 53c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm15 0c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm18-3c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z",
    },
    laugh: {
      d: "M37 46c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm23 0c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm-1.534 4c0 5.738-3.502 11-10 11s-10-5.262-10-11h20z",
    },
    smile: {
      d: "M37 46c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm23 0c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm-1.534 5.003c0 5.853-4.661 9.997-10 9.997s-10-4.144-10-9.997h2.404c.002 4.916 3.924 7.547 7.596 7.547s7.598-2.631 7.596-7.547c0-.007 2.404 0 2.404 0z",
    },
    poker: {
      d: "M37 46c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm23 0c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm-2 7H39v2.3h19V53z",
    }
  }

  return <div style={{display: "inline-block"}}>
    <motion.svg initial={{y: 0}} animate={{y: [1, -2, 1]}} transition={{loop: Infinity, duration: 3, ease: 'easeInOut'}}
                width="96" height="96" fill="none" viewBox="0 0 96 96">
      <g>
        <path fill="#DAF0EE"
              d="M96 48c0 26.51-21.49 48-48 48-7.425 0-14.455-1.686-20.73-4.695-1.717.693-4.378 1.732-6.413 2.359C17.593 94.669 14.93 95 11 95c-3.931 0-6.24-2.05-4.3-4.675 2.802-3.674 3.568-5.988 3.568-10.59 0-.785-.042-1.549-.091-2.178C3.8 69.409 0 59.148 0 48 0 21.49 21.49 0 48 0s48 21.49 48 48z"
        />
        <mask id="prefix__a" width="96" height="96" x="0" y="0" maskUnits="userSpaceOnUse">
          <path fill="#fff"
                d="M96 48c0 26.51-21.49 48-48 48-7.425 0-14.455-1.686-20.73-4.695-1.717.693-4.378 1.732-6.413 2.359C17.593 94.669 14.93 95 11 95c-3.931 0-6.24-2.05-4.3-4.675 2.802-3.674 3.568-5.988 3.568-10.59 0-.785-.042-1.549-.091-2.178C3.8 69.409 0 59.148 0 48 0 21.49 21.49 0 48 0s48 21.49 48 48z"
          />
        </mask>
        <path fill="#fff"
              d="M114.491 70.615c-6.948 27.866-35.17 44.823-63.036 37.876-7.804-1.946-14.753-5.561-20.56-10.369-1.986.28-5.056.674-7.359.8-3.695.2-6.58-.15-10.712-1.18-4.131-1.03-6.022-3.79-3.294-6.041 3.908-3.127 5.32-5.358 6.526-10.196.206-.826.362-1.639.475-2.314-4.567-10.236-5.873-22.017-2.951-33.736C20.528 17.59 48.75.632 76.615 7.58c27.866 6.948 44.823 35.17 37.876 63.035z"/>
        <path fill="#6C6B71"
              style={{filter: "drop-shadow( 0 2px 1px black )"}}
              d="M11 92c3.735 0 6.082-.313 8.974-1.203 1.686-.52 3.746-1.275 6.17-2.265l1.23-.504 1.2.576C34.584 91.484 41.181 93 48 93c24.853 0 45-20.147 45-45S72.853 3 48 3 3 23.147 3 48c0 10.194 3.395 19.862 9.547 27.718l.56.714.07.903c.06.786.09 1.586.09 2.4 0 5.14-.94 8.073-3.918 12.058.439.131 1.002.207 1.65.207zm16.278-.691c-2.5 1.021-4.64 1.806-6.42 2.355C17.592 94.669 14.93 95 10.998 95c-3.93 0-6.24-2.05-4.3-4.675 2.803-3.674 3.569-5.988 3.569-10.59 0-.737-.028-1.46-.083-2.167C3.804 69.418 0 59.153 0 48 0 21.49 21.49 0 48 0s48 21.49 48 48-21.49 48-48 48c-7.421 0-14.45-1.684-20.722-4.691z"/>
      </g>
      {/* Face */}
      <motion.path initial={'waiting'} variants={variants} d={paths[props.state].d} animate={props.state}/>
    </motion.svg>
  </div>
}
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "components/Icon";
import React, { useState } from "react";
import Typification from "_domain/student/entities/Typification";
import EmptyRecords from "./EmptyRecords";
import { CcIcon } from "@cafeta/components-react";
import Table, { TableProps } from "../../../../components/Table";
import Drawer from "../../../../components/Drawer";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "#f9fbff",
    marginTop: 16,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    overflow: "hidden",
  },
  channel: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: 8,
    alignItems: "center",
  },
  detailContent: {
    display: "grid",
    gridGap: 16,
  },
}));

type RecordsProps = {
  records: Typification[];
};

export default function Records(props: RecordsProps) {
  const { records } = props;
  const classes = useStyles();

  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [recordSelected, setRecordSelected] = useState<null | Typification>(
    null
  );

  if (props.records.length < 1) return <EmptyRecords />;

  const tableProps: TableProps = {
    data: records.map((d) => d.object),
    headers: [
      {
        label: "Canal",
        accessor: "channel",
        renderCell: (index, value) => (
          <div className={classes.channel}>
            <IconStatus record={props.records[index]} />
            <Typography variant="body2">{value}</Typography>
          </div>
        ),
      },
      {
        label: "Fecha y hora",
        accessor: "created_at",
      },
      {
        label: "Tipo de atención",
        accessor: "typification_type",
      },
      {
        label: "Motivo de atención",
        accessor: "typification_reason",
      },
      {
        label: "",
        renderCell: (index, value) => {
          return (
            <Button
              data-cy="attention_open_detail"
              onClick={() => {
                setRecordSelected(props.records[index]);
                setOpenDetail(true);
              }}
              color="primary"
            >
              Ver detalle
            </Button>
          );
        },
      },
    ],
  };

  return (
    <div>
      <div className={classes.table} id="table_container">
        <Table data-cy="attention_table" maxHeight={350} {...tableProps} />
      </div>

      {recordSelected && (
        <Drawer
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          onExited={() => setRecordSelected(null)}
          title={recordSelected.channel?.value || "Sin canal"}
          titleInfo={{
            icon: <IconStatus record={recordSelected} />,
            subtitle: recordSelected.object.created_at,
          }}
        >
          <div className={classes.detailContent}>
            <div>
              <Typography variant="h6">Atendido por:</Typography>
              <Typography data-cy="attention_user" variant="body2">
                {recordSelected.user}
              </Typography>
            </div>
            <div>
              <Typography variant="h6">
                {recordSelected.typification_type?.label}:
              </Typography>
              <Typography variant="body2" data-cy="attention_typification_type">
                {recordSelected.typification_type?.value}
              </Typography>
            </div>
            <div>
              <Typography variant="h6">
                {recordSelected.category?.label}:
              </Typography>
              <Typography variant="body2" data-cy="attention_category">
                {recordSelected.category?.value}
              </Typography>
            </div>
            {recordSelected.typification_reason && (
              <div>
                <Typography variant="h6">
                  {recordSelected.typification_reason.label}:
                </Typography>
                <Typography variant="body2" data-cy="attention_reason">
                  {recordSelected.typification_reason.value}
                </Typography>
              </div>
            )}
            {recordSelected.result_call?.value && (
              <div>
                <Typography variant="h6">
                  {recordSelected.result_call.label}:
                </Typography>
                <Typography variant="body2" data-cy="attention_result_call">
                  {recordSelected.result_call.value}
                </Typography>
              </div>
            )}
            <div>
              <Typography variant="h6">Observaciones:</Typography>
              <Typography variant="body2" data-cy="attention_observations">
                {recordSelected.observations?.value ||
                  "No se registraron observaciones."}
              </Typography>
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
}

function IconStatus(props: { record: Typification }) {
  // TODO: llevar lógica a dominio
  if (props.record.channel?.value === "Presencial")
    return <CcIcon data-cy="user" name={"user"} style={{ color: "#6dd4a4" }} />;
  if (props.record.channel?.value === "Llamada") {
    if (props.record.result_call?.value === "Se brinda información")
      return (
        <CcIcon
          data-cy="arrow_up"
          name={"arrow-up-right"}
          style={{ color: "#6dd4a4" }}
        />
      );
    return (
      <CcIcon
        data-cy="arrow_down"
        name={"arrow-down-left"}
        style={{ color: "#ff8389" }}
      />
    );
  }
  if (props.record.channel?.value === "Whatsapp")
    return (
      <Icon
        data-cy="whastapp_icon"
        name={"whatsapp"}
        style={{ color: "#6dd4a4" }}
      />
    );
  if (props.record.channel?.value === "Correo")
    return <CcIcon data-cy="user" name={"user"} style={{ color: "#6dd4a4" }} />;
  return (
    <CcIcon
      data-cy="arrow_up"
      name={"arrow-up-right"}
      style={{ color: "#6dd4a4" }}
    />
  );
}

type CourseOptions = {
  catalog_number: string;
  class_number: number;
  title: string;
  average: string;
  enroll_status: { code: string; name: string };
  session_code: { code: string; name: string };
  turn: { code: string; name: string };
  status: { code: string; name: string };
  times: number;
  credits: number;
  hours: number;
  crse_eval_formula: string;
  attendance: {
    percentage_attend: number;
    percentage_not_attend: number;
  } | null;
  teachers: { name: string }[] | null;
};

export type CoursePlain = {
  catalog_number: string;
  class_number: number;
  title: string;
  average: string;
  enroll_status: string;
  session_code: string;
  turn: string;
  times: number;
  credits: number;
  hours: number;
  status: string;
  key: string;
};

export default class Course {
  catalog_number: string;
  class_number: number;
  title: string;
  average: string;
  enroll_status: { code: string; name: string };
  status: { code: string; name: string };
  session_code: { code: string; name: string };
  turn: { code: string; name: string };
  times: number;
  crse_eval_formula: string;
  credits: number;
  hours: number;
  attendance: {
    percentage_attend: number;
    percentage_not_attend: number;
  } | null;
  teachers: { name: string }[] | null;
  constructor(options: CourseOptions) {
    this.catalog_number = options.catalog_number;
    this.class_number = options.class_number;
    this.title = options.title;
    this.average = options.average;
    this.enroll_status = options.enroll_status;
    this.session_code = options.session_code;
    this.turn = options.turn;
    this.times = options.times;
    this.credits = options.credits;
    this.hours = options.hours;
    this.status = options.status;
    this.crse_eval_formula = options.crse_eval_formula;
    this.attendance = options.attendance;
    this.teachers = options.teachers;
  }

  get toPlain(): CoursePlain {
    return {
      catalog_number: this.catalog_number,
      class_number: this.class_number,
      title: this.title,
      average: this.average,
      enroll_status: this.enroll_status.name,
      session_code: this.session_code?.code.slice(-1),
      turn: this.turn.name,
      times: this.times,
      credits: this.credits,
      hours: this.hours,
      status: this.status.name,
      key: this.catalog_number,
    };
  }

  get is_convalidated() {
    return this.status.code === "V";
  }
}

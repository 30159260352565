import CampaignRepository from "./../infrastructure/CampaignRepository";
import CampaignSubCategory from "./../entities/CampaignSubCategory";

export default class SaveSubcategoryUseCase {

    campaignRepository: CampaignRepository;

    constructor(campaignRepository: CampaignRepository) {
        this.campaignRepository = campaignRepository;
    }

    async execute(data: CampaignSubCategory) {
        const subcategory = await this.campaignRepository.saveSubcategory(data);
        return subcategory;
    }
}
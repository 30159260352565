import StudentRepository from "../infrastructure/StudentRepository";
import Student from "../entities/Student";

export default class GetStudentUseCase {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(id: string, enablePrivacity: boolean, reason?: string) {
    if (enablePrivacity && reason) {
      return await this.studentRepository.setPrivacity(id, reason);
    } else {
      return await this.studentRepository.deletePrivacy(id);
    }
  }
}

import React, { useEffect, useMemo, useState } from "react";
import SquareInfo from "components/SquareInfo";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/Button";
import Table, { TableProps } from "components/Table";
import Student from "_domain/student/entities/Student";
import Link from "@material-ui/core/Link";
import academic_alert from "assets/images/academic_alert.png";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Times from "components/Times";
import Spinner from "components/Spinner";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import useCases from "_domain";
import Course from "_domain/student/entities/Course";
import { Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Divider from "@material-ui/core/Divider";
import InfoChip from "../../../../components/InfoChip";
import { useSession } from "../../../../hooks/use-auth";
import DetailPeriod from "../../../../_domain/student/entities/DetailPeriod";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto auto auto",
    alignItems: "flex-end",
    gridGap: 8,
  },
  table: {
    backgroundColor: "#f9fbff",
    marginTop: 16,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    overflow: "hidden",
  },
  periodSelector: {
    display: "grid",
    gridTemplateColumns: "200px auto",
    gridGap: 8,
    alignItems: "center",
  },
  academic_alert: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#748e98",
    padding: "100px",
    "& img": {
      marginTop: 40,
      height: 80,
    },
  },
  detailContent: {
    padding: "0 24px",
  },
  detailHeader: {
    padding: 24,
    minWidth: 750,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "flex-start",
  },
  detailEvHeader: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: 16,
    alignItems: "center",
    padding: 16,
  },
  detailEvContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 8,
    padding: 16,
    "&>div": {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      gridGap: 4,
    },
  },
  detailSchHeader: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 8,
    padding: 16,
  },
  detailSchContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 8,
    padding: 16,
  },
}));

type AcademicStatusProps = {
  student: Student;
};

export default function AcademicStatus(props: AcademicStatusProps) {
  const classes = useStyles();
  const { logout } = useSession();
  const { student } = props;
  const [loading, setLoading] = useState(false);
  const lastPeriodCode = student.lastAcademicInfo?.year_code;
  const { control, watch } = useForm<{ period: string }>({
    defaultValues: {
      period: lastPeriodCode,
    },
  });

  const [periodsCached, setPeriodsCached] = useState<
    { periodCode: string; courses: Course[] | null }[]
  >(
    lastPeriodCode
      ? [
          {
            periodCode: lastPeriodCode,
            courses: student.lastAcademicInfo
              ? student.lastAcademicInfo.courses
              : null,
          },
        ]
      : []
  );

  const [courses, setCourses] = useState<Course[] | null>(
    student.lastAcademicInfo?.courses || null
  );
  const watchPeriodSelected = watch("period");

  const [courseToDetail, setCourseToDetail] = useState<Course | null>(null);
  const [openCourseDetail, setOpenCourseDetail] = useState<boolean>(false);

  const [detailPeriodData, setDetailPeriodData] = useState<DetailPeriod | null>(
    null
  );

  function getCoursesCachedByPeriod(
    period_code: string
  ): Course[] | undefined | null {
    return periodsCached.find((period) => period.periodCode === period_code)
      ?.courses;
  }

  const periodData = useMemo(() => {
    return student.periods.find((p) => p.period.code === watchPeriodSelected);
  }, [watchPeriodSelected]);

  const grade = useMemo(() => {
    return periodData?.acad_career.code || "";
  }, [periodData]);

  useEffect(() => {
    if (!lastPeriodCode) return;
    const period = watchPeriodSelected;
    const courses_cached = getCoursesCachedByPeriod(period);
    if (courses_cached === undefined) {
      setLoading(true);
      useCases.get_courses_by_period
        .execute(student.id, period, grade)
        .then((courses) => {
          setCourses(courses);
          setPeriodsCached((p) => [
            ...p,
            { periodCode: period, courses: courses },
          ]);

          const class_numbers: number[] = [];
          courses?.forEach((course) => {
            class_numbers.push(course.class_number);
          });
          useCases.get_detail_period
            .execute(student.id, watchPeriodSelected, grade, class_numbers)
            .then((data) => {
              setDetailPeriodData(data);
              setLoading(false);
            })
            .catch((e) => {
              if (e.name === "AuthorizationError") logout();
              else {
                setDetailPeriodData(null);
                setLoading(false);
              }
            });
        })
        .catch(() => {
          logout();
        })
        .finally(() => {});
    } else {
      setLoading(true);
      setCourses(courses_cached);
      const class_numbers: number[] = [];
      courses_cached?.forEach((course) => {
        class_numbers.push(course.class_number);
      });
      useCases.get_detail_period
        .execute(student.id, watchPeriodSelected, grade, class_numbers)
        .then((data) => {
          setDetailPeriodData(data);
          setLoading(false);
        })
        .catch((e) => {
          if (e.name === "AuthorizationError") logout();
          else {
            setDetailPeriodData(null);
            setLoading(false);
          }
        });
    }
  }, [watchPeriodSelected]);

  const credits = useMemo(() => {
    let crt = 0;
    courses?.forEach((course) => (crt = course.credits + crt));
    return crt;
  }, [courses]);

  const totalHours = useMemo(() => {
    let hours = 0;
    courses?.forEach((course) => (hours = course.hours + hours));
    return hours;
  }, [courses]);

  const tableProps: TableProps = useMemo(
    () =>
      grade === "PPE"
        ? {
            data: courses?.map((c) => c.toPlain) || [],
            headers: [
              { label: "Cursos matriculados", accessor: "title" },
              {
                label: "Intentos",
                accessor: "times",
                renderCell: (index, value) => {
                  return <Times number={value} />;
                },
              },
              { label: "Créditos", accessor: "credits" },
              { label: "Módulo", accessor: "session_code" },
              { label: "Estado", accessor: "status" },
              { label: "Promedio", accessor: "average" },
              { label: "Turno", accessor: "turn" },
              {
                label: "",
                renderCell: (index) => {
                  if (courses && !courses[index].is_convalidated)
                    return (
                      <Button
                        color="primary"
                        disabled={loading || !detailPeriodData}
                        onClick={() => {
                          setCourseToDetail(courses ? courses[index] : null);
                          setOpenCourseDetail(true);
                        }}
                      >
                        Ver detalle
                      </Button>
                    );
                  return <></>;
                },
              },
            ],
          }
        : {
            data: courses?.map((c) => c.toPlain) || [],
            headers: [
              { label: "Cursos matriculados", accessor: "title" },
              {
                label: "Intentos",
                accessor: "times",
                renderCell: (index, value) => {
                  return <Times number={value} />;
                },
              },
              { label: "Créditos", accessor: "credits" },
              { label: "Estado", accessor: "status" },
              { label: "Promedio", accessor: "average" },
              { label: "Turno", accessor: "turn" },
              {
                label: "",
                renderCell: (index) => {
                  if (courses && !courses[index].is_convalidated)
                    return (
                      <Button
                        color="primary"
                        disabled={loading || !detailPeriodData}
                        onClick={() => {
                          setCourseToDetail(courses ? courses[index] : null);
                          setOpenCourseDetail(true);
                        }}
                      >
                        Ver detalle
                      </Button>
                    );
                  return <></>;
                },
              },
            ],
          },
    [courses, loading]
  );

  function renderTable(tableProps: TableProps) {
    return (
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -25 }}
      >
        <div className={classes.table}>
          <Table data-cy="table_academic_status" {...tableProps} />
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
      <div className={classes.header}>
        <div className={classes.periodSelector}>
          {lastPeriodCode && (
            <div>
              <Typography variant="h6">Elegir ciclo</Typography>
              <Controller
                as={
                  <Select
                    disabled={loading}
                    IconComponent={
                      loading
                        ? () => (
                            <div style={{ margin: "0 8px" }}>
                              <Spinner color="disabled" size={14} />
                            </div>
                          )
                        : ArrowDropDown
                    }
                  >
                    {renderOptions(student.periods.map((p) => p.period))}
                  </Select>
                }
                fullWidth
                displayEmpty
                name="period"
                rules={{ required: true }}
                variant="outlined"
                control={control}
              />
            </div>
          )}
        </div>
        <div>
          {lastPeriodCode && (
            <SquareInfo
              data-cy="squareinfo_hours"
              label="Horas académicas"
              value={totalHours.toString()}
            />
          )}
        </div>
        <div>
          {lastPeriodCode && (
            <SquareInfo
              data-cy="squareinfo_credits"
              label="Total de créditos"
              value={credits.toString()}
            />
          )}
        </div>
        <div>
          {lastPeriodCode && (
            <SquareInfo
              data-cy="squareinfo_average"
              label="Promedio ponderado"
              value={periodData?.grade_average || ""}
            />
          )}
        </div>

        <Link
          data-cy="go_student_portal"
          href="https://portalestudiante.utp.edu.pe/SimulacionAcceso/SimularAcceso"
          target="_blank"
        >
          <Button variant="contained" color="primary">
            Ir al portal del estudiante
          </Button>
        </Link>
      </div>
      {lastPeriodCode ? (
        renderTable(tableProps)
      ) : (
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: -25 }}
        >
          <div className={classes.table}>
            <div className={classes.academic_alert}>
              <Typography variant="subtitle1">
                El alumno no está matriculado.
              </Typography>
              <img src={academic_alert} alt="academic alert" />
            </div>
          </div>
        </motion.div>
      )}
      {courseToDetail && detailPeriodData && (
        <Dialog
          open={openCourseDetail}
          onClose={() => {
            setOpenCourseDetail(false);
          }}
          onExited={() => setCourseToDetail(null)}
          maxWidth="md"
        >
          <div>
            <div className={classes.detailHeader}>
              <div>
                <Typography variant="h4">{courseToDetail.title}</Typography>
                <Typography variant="body2">
                  Cód. curso: {courseToDetail.catalog_number} - Nro. clase:{" "}
                  {courseToDetail.class_number}
                </Typography>
                <Typography variant="body2">
                  Prof.{" "}
                  {courseToDetail.teachers?.map((teacher, index) => {
                    if (index === 0) return teacher.name;
                    return " / " + teacher.name;
                  }) || "Por asignar"}
                </Typography>
              </div>
              <IconButton
                data-cy="modal_close"
                size="small"
                onClick={() => {
                  setOpenCourseDetail(false);
                }}
              >
                <Close />
              </IconButton>
            </div>
            <Divider />
            <div
              data-cy="modal_detail_content"
              className={classes.detailContent}
            >
              <div className={classes.detailEvHeader}>
                <Typography variant="h6">Evaluaciones</Typography>
                {courseToDetail.crse_eval_formula && (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <InfoChip
                      data-cy="infochip_formula"
                      text={[
                        "Formula de evaluación ",
                        courseToDetail.crse_eval_formula,
                      ]}
                    />
                  </div>
                )}
              </div>
              <Divider />
              <div
                data-cy="modal_eval_content"
                className={classes.detailEvContent}
              >
                {detailPeriodData.get_evaluations_by_class_nbr(
                  courseToDetail.class_number
                ).length > 0 ? (
                  detailPeriodData
                    .get_evaluations_by_class_nbr(courseToDetail.class_number)
                    .map((ev, index) => (
                      <div key={index}>
                        <Typography
                          variant="body2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {ev.eval_name.toLowerCase()}
                        </Typography>
                        <Typography variant="body2">
                          {ev.student_grade}
                        </Typography>
                      </div>
                    ))
                ) : (
                  <Typography variant="body2" style={{ gridColumn: "1 / -1" }}>
                    No tiene evaluaciones registradas.
                  </Typography>
                )}
              </div>
            </div>
            <Divider />
            <div className={classes.detailContent}>
              <div>
                <div className={classes.detailSchHeader}>
                  <Typography variant="h6">Horario</Typography>
                  <Typography variant="h6">Sede</Typography>
                  <Typography variant="h6">Aula</Typography>
                </div>
                <Divider />
                <div
                  className={classes.detailSchContent}
                  data-cy="modal_scheludes_content"
                >
                  {detailPeriodData.get_summary_scheludes_by_class_nbr(
                    courseToDetail.class_number
                  ).length > 0 ? (
                    detailPeriodData
                      .get_summary_scheludes_by_class_nbr(
                        courseToDetail.class_number
                      )
                      .map((sum) => (
                        <>
                          <Typography variant="body2">
                            {sum.day_name} de {sum.time_start} - {sum.time_end}
                          </Typography>
                          <Typography variant="body2">{sum.address}</Typography>
                          <Typography variant="body2">
                            {sum.facility_id}
                          </Typography>
                        </>
                      ))
                  ) : (
                    <Typography
                      variant="body2"
                      style={{ gridColumn: "1 / -1" }}
                    >
                      No tiene horario registrado.
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div className={classes.detailContent}>
              <div
                className={classes.detailSchHeader}
                data-cy="modal_attendance_content"
              >
                <Typography variant="h6">Asistencia</Typography>
                {courseToDetail.attendance ? (
                  <>
                    <Typography variant="h6" style={{ color: "#43c889" }}>
                      {courseToDetail.attendance.percentage_attend}% asistencias
                    </Typography>
                    <Typography color="error" variant="h6">
                      {courseToDetail.attendance.percentage_not_attend}%
                      inasistencias
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h6" style={{ color: "#43c889" }}>
                      -
                    </Typography>
                    <Typography color="error" variant="h6">
                      -
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </motion.div>
  );
}

function renderOptions(options: Array<{ code: string; name: string }>) {
  return options.map((option) => (
    <MenuItem key={option.code} value={option.code}>
      {option.name}
    </MenuItem>
  ));
}

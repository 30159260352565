import React from "react";
import Drawer from "components/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import InputContainer from "components/InputContainer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Session from "_domain/session/entities/SessionEntity";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useCases from "_domain";
import { useSession } from "hooks/use-auth";
import Spinner from "components/Spinner";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  modal: {
    padding: "20px 60px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  modalContent: {
    width: 300,
    textAlign: "center",
  },
}));

type TypificationDrawerAnonimousProps = {
  open: boolean;
  onClose(): void;
  session: Session;
  onSubmited(status: "error" | "success"): void;
};

export type FormData = {
  student_code: string;
  dni: string;
  grade: string;
  campus: string;
  subcategory: { label: string; value: string; category: string } | null;
  observations: string;
  resultCall: string;
};

export default function TypificationDrawerAnonimous(
  props: TypificationDrawerAnonimousProps
) {
  const { session, onSubmited } = props;
  const { logout } = useSession();
  const classes = useStyles();
  const { handleSubmit, control, formState, reset, errors } = useForm<FormData>(
    {
      mode: "onChange",
      defaultValues: {
        dni: "",
        student_code: "",
        grade: "",
        campus: "",
        subcategory: null,
        resultCall: session.forms.inboundnops.resultCall.options[0]?.value,
        observations: "",
      },
    }
  );

  async function onSubmit(data: FormData) {
    try {
      await useCases.send_typification_anonymous.execute({
        campus: data.campus,
        category: data.subcategory?.category || "",
        channel: session?.channelSelected || "",
        grade: data.grade,
        observations: data.observations,
        result_call: data.resultCall,
        student: { code: data.student_code, doc_number: data.dni },
        subcategory: data.subcategory?.value || "",
      });
      reset();
      onSubmited("success");
    } catch (e) {
      onSubmited("error");
      if (e.name === "AuthorizationError") return logout();
    }
  }

  function renderOptions(
    options: Array<{ label: string; value: string }>,
    placeholder?: string
  ) {
    const renderedOptions = options.map((option) => (
      <MenuItem value={option.value}>{option.label}</MenuItem>
    ));
    if (placeholder)
      renderedOptions.unshift(
        <MenuItem disabled value="">
          <Typography variant="body2" style={{ color: "rgba(74,74,74, 0.5)" }}>
            {placeholder}
          </Typography>
        </MenuItem>
      );
    return renderedOptions;
  }

  const regOnlyChartNumber = /[0-9a-zA-Z]/;
  const regOnlyNumber = /[0-9]/;

  return (
    <Drawer
      title="Registrar atención"
      open={props.open}
      onClose={() => props.onClose()}
    >
      <form className={classes.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6">(*) Son campos obligatorios</Typography>

        <InputContainer label="Código de estudiante (*)">
          <Controller
            placeholder="Ingresa el código del estudiante"
            error={errors.student_code}
            helperText={errors.student_code?.message}
            as={
              <TextField
                inputProps={{
                  maxLength: 8,
                  onKeyPress: (e) => {
                    if (!regOnlyChartNumber.test(e.key)) e.preventDefault();
                  },
                }}
              />
            }
            rules={{
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9]*$/,
                message: "No debe de contener caracteres especiales",
              },
            }}
            name="student_code"
            fullWidth
            variant="outlined"
            control={control}
          />
        </InputContainer>
        <InputContainer label="DNI (*)">
          <Controller
            placeholder="Ingresa el documento del estudiante"
            error={errors.dni}
            helperText={errors.dni?.message}
            as={
              <TextField
                inputProps={{
                  maxLength: 8,
                  onKeyPress: (e) => {
                    if (!regOnlyNumber.test(e.key)) e.preventDefault();
                  },
                }}
              />
            }
            rules={{
              required: true,
              pattern: {
                value: /^[0-9]*$/,
                message: "Solo debe de contener números",
              },
              minLength: 8,
            }}
            name="dni"
            fullWidth
            variant="outlined"
            control={control}
          />
        </InputContainer>
        <InputContainer label={session.forms.inboundnops.grade.label + " (*)"}>
          <Controller
            as={
              <Select>
                {renderOptions(
                  session.forms.inboundnops.grade.options,
                  "Selecciona el grado académico"
                )}
              </Select>
            }
            displayEmpty
            error={errors.grade}
            name="grade"
            rules={{ required: true }}
            fullWidth
            variant="outlined"
            control={control}
          />
        </InputContainer>

        <InputContainer label={session.forms.inboundnops.campus.label + " (*)"}>
          <Controller
            as={
              <Select>
                {renderOptions(
                  session.forms.inboundnops.campus.options,
                  "Selecciona un campus"
                )}
              </Select>
            }
            displayEmpty
            error={errors.campus}
            name="campus"
            rules={{ required: true }}
            fullWidth
            variant="outlined"
            control={control}
          />
        </InputContainer>

        <InputContainer
          label={session.forms.inboundnops.subcategory.label + " (*)"}
        >
          <Controller
            name="subcategory"
            control={control}
            rules={{ required: true }}
            onChange={([event, data]) => {
              return data;
            }}
            as={
              <Autocomplete
                id="subcategory"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Selecciona un motivo"
                    variant="outlined"
                  />
                )}
                options={session.forms.inbound.subcategory.options}
                noOptionsText="No se encontraron elementos"
                getOptionLabel={(option) => option.label}
              />
            }
          />
        </InputContainer>

        {session.channelSelected === "llamada" && (
          <InputContainer
            label={session.forms.inboundnops.resultCall.label + " (*)"}
          >
            <Controller
              as={
                <Select>
                  {renderOptions(session.forms.inboundnops.resultCall.options)}
                </Select>
              }
              error={errors.resultCall}
              name="resultCall"
              rules={{ required: true }}
              fullWidth
              variant="outlined"
              control={control}
            />
          </InputContainer>
        )}

        <InputContainer label="Observaciones">
          <Controller
            multiline
            rows={4}
            placeholder="Ingresa aqui tus comentarios"
            error={errors.observations}
            as={<TextField inputProps={{ maxLength: 1000 }} />}
            rules={{}}
            name="observations"
            fullWidth
            variant="outlined"
            control={control}
          />
        </InputContainer>

        <Button
          data-cy="submit_typification"
          fullWidth
          startIcon={formState.isSubmitting && <Spinner color="disabled" />}
          disabled={formState.isSubmitting || !formState.isValid}
          type="submit"
          color="primary"
          variant="contained"
          size="large"
        >
          Guardar Datos
        </Button>
      </form>
    </Drawer>
  );
}

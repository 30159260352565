import React from "react";
import { motion } from "framer-motion";

export default function Spinner(props: {
  color?: "primary" | "secondary" | "white" | "disabled";
  size?: number
}) {
  function resolveColor() {
    switch (props.color) {
      case "primary":
        return "#24a3ab";
      case "secondary":
        return "blue";
      case "white":
        return "white";
      case "disabled":
        return "#9B9DA0"
      default:
        return "#24a3ab";
    }
  }
  return (
    <svg style={{width: props.size || 24}} width="24" height="24" viewBox="0 0 24 24">
      <motion.path
        animate={{ rotate: [0, 360], pathLength: [0.4, 1, 0.4] }}
        transition={{ loop: Infinity }}
        stroke={resolveColor()}
        strokeWidth={4}
        fill="transparent"
        d="M 22 12 A 10 10 0 1 1 12 2"
      />
    </svg>
  );
}

import GetInitialSessionUseCase from "./GetInitialSessionUseCase";
import LogOutUseCase from "./LogOutUseCase";
import RecoverySessionUseCase from "./RecoverySessionUseCase";
import SessionRepositoyApi from "../infrastructure/SessionRepositoryApi";
import SignInUseCase from "./SignInUseCase";
import ChangeChannelSelectedUseCase from "./ChangeChannelSelected";
import RefreshTokenUseCase from "./RefreshTokenUseCase";

const sessionRepositoryApi = new SessionRepositoyApi();
const sessionUseCases = {
  signInUseCase: () => new SignInUseCase(sessionRepositoryApi),
  getInitialSessionUseCase: () => new GetInitialSessionUseCase(),
  recoverySessionUseCase: () =>
    new RecoverySessionUseCase(sessionRepositoryApi),
  refreshTokenUseCase: () => new RefreshTokenUseCase(sessionRepositoryApi),
  logOutUseCase: () => new LogOutUseCase(),
  changeChannelSelected: () =>
    new ChangeChannelSelectedUseCase(sessionRepositoryApi),
};
export default sessionUseCases;

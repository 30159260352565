import React, { ReactNode } from "react";

export default function Section(props: {
  children: ReactNode;
  first?: Boolean;
  vertialPaddingNone?: Boolean;
}) {
  const style: React.CSSProperties = {
    maxWidth: 1300,
    minWidth: 1024,
    margin: "0 auto",
    padding: props.vertialPaddingNone ? "0 80px" : "30px 80px",
    marginTop: props.first ? 32 : 0,
  };
  return <div style={style}>{props.children}</div>;
}

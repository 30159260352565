import StudentRepository from "../infrastructure/StudentRepository";
import Student from "../entities/Student";
import Course from "../entities/Course";

export default class GetCoursesByPeriodUseCase {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(id: string, period: string, grade: string) {
    const courses: Course[] = await this.studentRepository.getPeriod(id, period, grade);
    return courses;
  }
}

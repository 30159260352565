type CampaignOptions = {
    type_id: string;
    name: string;
    title: string;
    initial_speech: string;
    show_initial_speech: boolean;
    final_speech: string;
    show_final_speech: boolean;
    started_at: string;
    finished_at: string;
    status: string;
    is_template: boolean;
    url: string;
    sections: Array<Section>;
};

type Section = {
    title: string;
    description: string;
    order: number;
    questions: Array<Questions>;
}

type Questions = {
    type_id: number,
    title: string,
    mandatory: boolean,
    order: number,
    question_statement: string,
    show_comment: boolean,
    mandatory_comment: boolean,
    content_json: Content
}

type Content = {
    title: string
}

export default class Campaign {
    type_id: string;
    name: string;
    title: string;
    initial_speech: string;
    show_initial_speech: boolean;
    final_speech: string;
    show_final_speech: boolean;
    started_at: string;
    finished_at: string;
    status: string;
    is_template: boolean;
    url: string;
    sections: Array<Section>;
    constructor(options: CampaignOptions) {
        this.type_id = options.type_id;
        this.name = options.name;
        this.title = options.title;
        this.initial_speech = options.initial_speech;
        this.show_initial_speech = options.show_initial_speech;
        this.final_speech = options.final_speech;
        this.show_final_speech = options.show_final_speech;
        this.started_at = options.started_at;
        this.finished_at = options.finished_at;
        this.status = options.status;
        this.is_template = options.is_template;
        this.url = options.url;
        this.sections = options.sections;
    }
}
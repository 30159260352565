import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import React from "react";

import routes from "./routes";
import LoginLayout from "./login";
import PrivateRoute from "../utils/authentication/PrivateRoute";
import DashboardLayout from "./dashboard";
import MaterialUILayer from "../components/MaterialUILayer";
import { AuthProvider } from "../hooks/use-auth";

function App() {
  return (
    <MaterialUILayer>
      <Router>
        <AuthProvider>
          <Switch>
            <Route path={routes.login} children={<LoginLayout />} />
            <PrivateRoute
              path={routes.dashboard}
              children={<DashboardLayout />}
            />
            <Redirect to={routes.dashboard} />
            {/* <Route render={() => <h1 style={{textAlign: "center"}}> Not found 404 🔎️</h1>}/> */}
          </Switch>
        </AuthProvider>
      </Router>
    </MaterialUILayer>
  );
}

export default App;

import StudentRepository from "../infrastructure/StudentRepository";

export default class GetDocumentsUseCase {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(id: string, period: string, grade: string, class_nbrs: number[]) {
    const detailPeriod = await this.studentRepository.getDetailPeriod(id, period, grade, class_nbrs);
    return detailPeriod;
  }
}

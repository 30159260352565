import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ButtonBase from "@material-ui/core/ButtonBase";
import Student from "_domain/student/entities/Student";
import avatar_default from "assets/images/avatar_default.png";
import { useHistory } from "react-router-dom";
import { CcIcon } from "@cafeta/components-react";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Select,
  Switch,
} from "@material-ui/core";
import InputContainer from "../../../components/InputContainer";
import MenuItem from "@material-ui/core/MenuItem";
import { Controller, useForm } from "react-hook-form";
import Session from "../../../_domain/session/entities/SessionEntity";
import { AnimatePresence, motion, Variants } from "framer-motion";
import InfoChip from "../../../components/InfoChip";
import useCases from "../../../_domain";
import Section from "../../../components/Section";

import DashboardHeaders from "_domain/alert_dashboard/entities/DashboardHeaders";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridTemplateRows: "auto auto",
    gridGap: 20,
  },
  actions: {
    display: "flex",
    "&>div": {
      marginLeft: 20,
    },
  },
  avatar: {
    gridRow: `1 / span 3`,
    margin: "0 20px",
    height: 150,
    position: "relative",
    width: 150,
    "& picture": {
      display: "block",
      height: "100%",
      width: "100%",
      backgroundColor: "gray",
      borderRadius: "50%",
      overflow: "hidden",
      "& img": {
        display: "block",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "top",
      },
    },
  },
  check: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    border: `3px solid #ebf1f5`,
    backgroundColor: "#82d2ad",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 8,
    right: 8,
    color: "#ebf1f5",
  },
  basic_info: {
    gridColumn: "2 / span 1",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  basic_info_title: {
    display: "flex",
    alignItems: "center",
    "&>*": {
      marginRight: 16,
    },
  },
  detail_info: {
    display: "grid",
    gridColumn: "2 / span 1",
    gridGap: 8,
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateAreas:
      '"left right right" "left right right" "left right right"',
  },
  detail_links: {
    display: "grid",
    gridArea: "left",
    gridGap: 8,
    gridTemplateRows: "1fr 1fr 1fr",
  },
  privacy_edit: {
    marginTop: 16,
    gridColumn: "2 / span 1",
  },
  privacy_edit_content: {
    marginTop: 16,
    display: "grid",
    gridGap: 16,
    alignItems: "center",
    gridTemplateColumns: "auto auto",
  },
  privacy_footer: {
    gridColumn: "1 / -1",
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: 16,
    },
  },
  wrapper_min: {
    position: "absolute",
    top: 0,
    right: 8,
    left: 0,
    backgroundColor: "#ebf1f5",
    zIndex: 10,
    boxShadow: theme.shadows[1],
    padding: "16px 0",
  },
  minimizedInfo: {
    display: "grid",
    gridGap: 8,
    gridTemplateColumns: "auto auto auto auto auto",
    gridTemplateRows: "1fr 1fr 1fr",
    gridAutoFlow: "column",
    "& .buttons": {
      gridRow: "1 / span 2",
      marginLeft: 16,
      display: "flex",
      justifyContent: "flex-end",
      "&>div": {
        marginLeft: 8,
      },
    },
  },
  basic_info_wrapper: {
    gridRow: "1 / span 3",
  },
  indicadorValueWhite2: {
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "bold",
    color: "white",
    border: "1px solid #2eaacd",
    borderRadius: "7px",
    backgroundColor: "#2eaacd",
    padding: "5px",
  },
}));

type PrivacyForm = {
  activatePrivacy: boolean;
  reasonPrivacy: string;
};

const privacyFormVariants: Variants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  },
  visible: {
    display: "block",
    opacity: 1,
  },
};

const infoVariants: Variants = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 0,
  },
};

const infoMinimizedVariants: Variants = {
  hidden: {
    y: "-100%",
    transitionEnd: {
      display: "none",
    },
  },
  visible: {
    display: "block",
    y: "0%",
  },
};

type privacyFormState = "hidden" | "visible";
type infoMinimizedTypes = "hidden" | "visible";

export default function PersonalInfo(props: {
  student: Student;
  session: Session;
  dashboard_headers: DashboardHeaders;
  onClickShowAlert(): void;
  onClickOpenTypification(): void;
}) {
  const classes = useStyles();
  const { student, session, dashboard_headers } = props;
  const history = useHistory();
  const [hasPrivacity, setHasPrivacity] = useState(!!student.privacy);
  const countAlerts = student.alerts.length;
  const [privacyFormState, setPrivacyFormState] =
    useState<privacyFormState>("hidden");
  const [infoMinimizedState, setInfoMinimizedState] =
    useState<infoMinimizedTypes>("hidden");
  const {
    control,
    watch,
    handleSubmit,
    formState,
    setValue,
    triggerValidation,
    getValues,
    reset,
  } = useForm<PrivacyForm>({
    defaultValues: {
      activatePrivacy: true,
      reasonPrivacy: student.privacy?.reason || "",
    },
    mode: "onChange",
  });

  const watchActivatePrivacy = watch("activatePrivacy");

  const tipoAlumnoHelpers: { [key: string]: string } = {
    Nuevo: "Nuevo ingresante del periodo en curso",
    "Nuevo - Aplazado": "Nuevo ingresante que realizo reserva de vacante",
    Reincorporado: "Dejo de estudiar al menos un periodo regular",
    "Reincorporado - Aplazado":
      "Dejo de estudiar un periodo regular y aplazó por pandemia",
    Reinscrito: "Mantiene matricula en cada periodo regular",
    "Reinscrito - Aplazado":
      "Mantiene matricula en cada periodo regular y aplazó por pandemia",
  };

  async function submitPrivacy(dataForm: PrivacyForm) {
    try {
      await useCases.update_privacity.execute(
        student.id,
        dataForm.activatePrivacy,
        dataForm.reasonPrivacy
      );
    } catch (e) {
      if (!(e.message === "No se pudo eliminar la privacidad")) return;
    }
    setHasPrivacity(dataForm.activatePrivacy);
    setPrivacyFormState("hidden");
    reset({
      activatePrivacy: true,
      reasonPrivacy: dataForm.reasonPrivacy,
    });
  }

  useEffect(() => {
    const main_section = document.getElementById("main_section");
    if (!main_section) return;
    main_section.addEventListener("scroll", (e) => {
      if (main_section.scrollTop > 83) {
        if (infoMinimizedState === "hidden") setInfoMinimizedState("visible");
      } else {
        if (infoMinimizedState === "visible") setInfoMinimizedState("hidden");
      }
    });
  }, [infoMinimizedState]);

  useEffect(() => {
    if (watchActivatePrivacy === false) setValue("reasonPrivacy", "");

    triggerValidation().then((r) => {});
  }, [watchActivatePrivacy]);

  return (
    <div>
      <motion.div
        className={classes.wrapper_min}
        initial={"hidden"}
        variants={infoMinimizedVariants}
        animate={infoMinimizedState}
      >
        <Section vertialPaddingNone>
          <div className={classes.minimizedInfo}>
            <div className={classes.basic_info_wrapper}>
              <div className={classes.basic_info_title}>
                <Typography
                  variant="h5"
                  data-cy="student_name"
                >{`${student.first_name} ${student.last_name}`}</Typography>
                {hasPrivacity && (
                  <InfoChip
                    data-cy={"privacy_label"}
                    text={[]}
                    color="#ff8389"
                    icon={<CcIcon size={12} name={"lock"} />}
                  />
                )}
              </div>
              <Typography variant="body1" data-cy="student_code">
                {student.code}
              </Typography>
              <div>
                {student.current_benefit && (
                  <InfoChip
                    data-cy={"personal-info_beca"}
                    text={[
                      `${student.current_benefit.type.name} ${student.current_benefit.concept.name}`,
                    ]}
                    color={"#81a8d2"}
                    contained
                  />
                )}
              </div>
            </div>
            <Typography data-cy="email" variant="body1" color="primary">
              <Link href={`mailto:${student.email}`}>{student.email}</Link>
            </Typography>
            <Typography data-cy="phone" variant="body1" color="primary">
              <Link href={`tel:${student.phone}`}>{student.phone}</Link>
            </Typography>
            <div></div>
            <ItemInformation
              data-cy="info_alert_id"
              text={`ID: ${student.alter_id}`}
              Icon={<CcIcon name="user" size={14} />}
            />
            <ItemInformation
              data-cy="info_grade"
              text={student.grade.label}
              Icon={<CcIcon name="edit-3" size={14} />}
            />
            <ItemInformation
              data-cy="info_campus"
              text={student.campus.label}
              Icon={<CcIcon name="map-pin" size={14} />}
            />
            <ItemInformation
              data-cy="info_carrer"
              text={student.carrer}
              Icon={<CcIcon name="book" size={14} />}
            />
            <ItemInformation
              data-cy="info_period"
              text={student.period}
              Icon={<CcIcon name="clock" size={14} />}
            />
            <ItemInformation
              data-cy="info_identify_document"
              text={student.identity_document}
              Icon={<CcIcon name="user-check" size={14} />}
            />
            <div className={"buttons"}>
              {props.student.hasAlertToShow && (
                <ButtonAction
                  disabled={false}
                  Icon={<CcIcon id="show_alerts_icon" name="bell" />}
                  onClick={() => props.onClickShowAlert()}
                  count={countAlerts}
                />
              )}
              <ButtonAction
                disabled={session?.isAgenteInbound}
                Icon={<CcIcon id="menu_icon" name="menu" />}
                onClick={() => props.onClickOpenTypification()}
              />
            </div>
          </div>
        </Section>
      </motion.div>
      <motion.div
        className={classes.wrapper}
        initial={"hidden"}
        variants={infoVariants}
        animate={infoMinimizedState}
      >
        <div className={classes.avatar}>
          <picture>
            <img
              data-cy="student_avatar"
              src={student.photo ? student.photo : avatar_default}
              alt=""
            />
          </picture>
          {student.status === "activo" && (
            <div data-cy="student_active" className={classes.check}>
              <CcIcon name="check" size={16} />
            </div>
          )}
          {student.status === "baja" && (
            <div
              data-cy="student_unsubscribed"
              className={classes.check}
              style={{ backgroundColor: "#2a3247" }}
            >
              <CcIcon name="alert-triangle" size={16} />
            </div>
          )}
          {student.status === "cancelado" && (
            <div
              data-cy="student_canceled"
              className={classes.check}
              style={{ backgroundColor: "#ff8389" }}
            >
              <Close style={{ fontSize: 16 }} />
            </div>
          )}
        </div>
        <div className={classes.basic_info}>
          <div>
            <div className={classes.basic_info_title}>
              <Typography
                variant="h3"
                data-cy="student_name"
              >{`${student.first_name} ${student.last_name}`}</Typography>
              {hasPrivacity && (
                <InfoChip
                  data-cy={"privacy_label"}
                  text={["Privacidad de Datos Personales"]}
                  color="#ff8389"
                  icon={<CcIcon size={14} name={"lock"} />}
                />
              )}

              <div
                title={tipoAlumnoHelpers[`${dashboard_headers.tipo_de_alumno}`]}
                className={classes.indicadorValueWhite2}
              >
                {dashboard_headers.tipo_de_alumno}
              </div>
            </div>
            <Typography variant="body1" data-cy="student_code">
              {student.code}
            </Typography>
          </div>
          <div className={classes.actions}>
            {props.session.isSupervisor && (
              <ButtonAction
                disabled={false}
                Icon={
                  <CcIcon
                    data-cy={"privacy_edit"}
                    id="show_privacy_edit"
                    name="edit-2"
                  />
                }
                onClick={() => setPrivacyFormState("visible")}
              />
            )}
            {props.student.hasAlertToShow && (
              <ButtonAction
                disabled={false}
                Icon={<CcIcon id="show_alerts_icon" name="bell" />}
                onClick={() => props.onClickShowAlert()}
                count={countAlerts}
              />
            )}
            <ButtonAction
              disabled={session?.isAgenteInbound}
              Icon={<CcIcon id="menu_icon" name="menu" />}
              onClick={() => props.onClickOpenTypification()}
            />
          </div>
        </div>
        {student.current_benefit && (
          <div>
            <InfoChip
              data-cy={"personal-info_beca"}
              text={[
                `${student.current_benefit.type.name} ${student.current_benefit.concept.name}`,
              ]}
              color={"#81a8d2"}
              contained
            />
          </div>
        )}
        <div className={classes.detail_info}>
          <div className={classes.detail_links}>
            <Typography data-cy="email" variant="body1" color="primary">
              <Link href={`mailto:${student.email}`}>{student.email}</Link>
            </Typography>
            <Typography data-cy="phone" variant="body1" color="primary">
              <Link href={`tel:${student.phone}`}>{student.phone}</Link>
            </Typography>
          </div>
          <ItemInformation
            data-cy="info_alert_id"
            text={`ID: ${student.alter_id}`}
            Icon={<CcIcon name="user" size={14} />}
          />
          <ItemInformation
            data-cy="info_carrer"
            text={student.carrer}
            Icon={<CcIcon name="book" size={14} />}
          />
          <ItemInformation
            data-cy="info_grade"
            text={student.grade.label}
            Icon={<CcIcon name="edit-3" size={14} />}
          />
          <ItemInformation
            data-cy="info_period"
            text={student.period}
            Icon={<CcIcon name="clock" size={14} />}
          />
          <ItemInformation
            data-cy="info_campus"
            text={student.campus.label}
            Icon={<CcIcon name="map-pin" size={14} />}
          />
          <ItemInformation
            data-cy="info_identify_document"
            text={student.identity_document}
            Icon={<CcIcon name="user-check" size={14} />}
          />
        </div>
        {props.session.isSupervisor && (
          <motion.div
            initial={"hidden"}
            variants={privacyFormVariants}
            animate={privacyFormState}
            className={classes.privacy_edit}
            data-cy={"privacy_container"}
          >
            <Divider />
            <div className={classes.privacy_edit_content}>
              <Controller
                control={control}
                name={"activatePrivacy"}
                as={
                  <FormControlLabel
                    control={
                      <Switch
                        data-cy={"privacy_switch"}
                        name="activatePrivacy"
                        color="primary"
                      />
                    }
                    label="Alumno solicita Privacidad de Datos Personales"
                  />
                }
              />
              <InputContainer label={"Motivo de privacidad"}>
                <Controller
                  name={"reasonPrivacy"}
                  rules={{
                    validate: (value) =>
                      getValues("activatePrivacy") ? !!value : true,
                  }}
                  fullWidth
                  variant="outlined"
                  control={control}
                  as={
                    <Select
                      data-cy={"privacy_options"}
                      displayEmpty
                      disabled={!watchActivatePrivacy}
                    >
                      {renderOptions(session.forms.privacy.options)}
                    </Select>
                  }
                />
              </InputContainer>
              <div className={classes.privacy_footer}>
                <Button
                  disabled={formState.isSubmitting}
                  data-cy={"privacy_cancel"}
                  onClick={() => {
                    setPrivacyFormState("hidden");
                    reset();
                  }}
                  variant={"contained"}
                >
                  Cancelar
                </Button>
                <Button
                  data-cy={"privacy_submit"}
                  onClick={handleSubmit(submitPrivacy)}
                  disabled={formState.isSubmitting || !formState.isValid}
                  color={"primary"}
                  variant={"contained"}
                >
                  Guardar
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}

function renderOptions(
  options: Array<{ label: string; value: string }>,
  placeholder?: string
) {
  return [
    <MenuItem key={"none"} disabled value="">
      Seleccione un motivo
    </MenuItem>,
    ...options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    )),
  ];
}

const useStylesItemInformation = makeStyles((theme) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: 8,

    alignItems: "center",
    color: "#748e98",
  },
}));

function ItemInformation({
  Icon,
  text,
  ...rest
}: {
  text: string;
  Icon: JSX.Element;
}) {
  const classes = useStylesItemInformation();
  return (
    <div {...rest} className={classes.wrapper}>
      {Icon}
      <Typography variant="body1">{text}</Typography>
    </div>
  );
}

const useStylesButtonAction = makeStyles((theme) => ({
  wrapper: {
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 3,
    boxShadow: theme.shadows[1],
    color: theme.palette.primary.main,
  },
  alert: {
    backgroundColor: theme.palette.error.main,
    width: 24,
    height: 24,
    color: "white",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: -12,
    right: -12,
    zIndex: 5,
  },
}));

function ButtonAction(props: {
  Icon: JSX.Element;
  onClick(): void;
  count?: number;
  disabled: boolean;
}) {
  const { count, onClick, Icon, ...rest } = props;
  const classes = useStylesButtonAction();
  return (
    <div {...rest} style={{ position: "relative" }}>
      {props.count && (
        <div className={classes.alert}>
          <span>{props.count < 10 ? props.count : "9+"}</span>
        </div>
      )}
      <ButtonBase disabled={props.disabled} onClick={props.onClick}>
        <div className={classes.wrapper}>{props.Icon}</div>
      </ButtonBase>
    </div>
  );
}

import StudentRepository from "../infrastructure/StudentRepository";

export default class GetFinancialPeriodDataUseCase {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(id: string, period: string) {
    const financialPeriodData = await this.studentRepository.getFinancialData(
      id,
      period
    );
    return financialPeriodData;
  }
}

export type Header = {
  tipo_de_alumno: string;
  modalidad: string;
};

export default class DashboardHeaders {
  tipo_de_alumno: string;
  modalidad: string;
  constructor(header: Header) {
    this.tipo_de_alumno = header.tipo_de_alumno;
    this.modalidad = header.modalidad;
  }
}

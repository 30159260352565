import { makeStyles } from "@material-ui/core/styles";
import React, { useMemo } from "react";
import Navbar from "../../components/Navbar";
import Header from "../../components/Header";
import { useSession } from "hooks/use-auth";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import InboundLayout from "app/inbound";
import { CcIcon } from "@cafeta/components-react";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "grid",
    height: "100vh",
    gridTemplateColumns: "auto 1fr",
    gridTemplateRows: "auto 1fr",
  },
  sidebar: {
    position: "relative",
    zIndex: 50,
    height: "100%",
  },
  sidebarButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80px",
    backgroundColor: "#2a3247",
    color: "white",
  },
  mainContent: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#ebf1f5",
    zIndex: 5,
    "& main": {
      overflow: "auto",
      height: "100%",
    },
  },
  header: {
    gridColumn: "1 / span 2",
    zIndex: 10,
  },
}));

export default function DashboardLayout() {
  const match = useRouteMatch();
  const matchStudentPath = useRouteMatch("/dashboard/inbound/student/:id");

  const { logout, session } = useSession();

  const navbarItems = [
    {
      label: "Busqueda de estudiante",
      icon: <CcIcon name="search" />,
      to: `${match.path}/inbound/search`,
    },
  ];

  const navbarItemsSupervisor = [
    {
      label: "Busqueda de estudiante",
      icon: <CcIcon name="search" />,
      to: `${match.path}/inbound/search`,
    },
    {
      label: "Mantenimiento de Campaña",
      icon: <CcIcon name="book" />,
      to: `${match.path}/inbound/campaignMnt`,
    },
    {
      label: "Carga de Alertas",
      icon: <CcIcon name="bell" />,
      to: `${match.path}/inbound/alerts`,
    },
  ];

  const currentUser = useMemo(() => {
    if (matchStudentPath) {
      return {
        label: "Visor 360",
        icon: <CcIcon name="user" />,
        to: matchStudentPath.url,
      };
    }
    return null;
  }, [matchStudentPath]);

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Header
          onLogOut={() => logout()}
          username={session?.user.first_name || "Sin nombre"}
          role={session?.user.primary_group || "Sin rol"}
        />
      </div>
      <div className={classes.sidebar}>
        {session?.isSupervisor && (
          <Navbar items={navbarItemsSupervisor} currentUser={currentUser} />
        )}

        {!session?.isSupervisor && (
          <Navbar items={navbarItems} currentUser={currentUser} />
        )}
      </div>
      <div className={classes.mainContent}>
        <main id="main_section">
          <Switch>
            <Route
              path={`${match.path}/inbound`}
              children={<InboundLayout />}
            />
            <Redirect to={`${match.path}/inbound`} />
          </Switch>
        </main>
      </div>
    </div>
  );
}

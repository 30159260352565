import CampaignRepository from "./../infrastructure/CampaignRepository";

export default class GetCategoryUseCase {

    campaignRepository: CampaignRepository;

    constructor(campaignRepository: CampaignRepository) {
        this.campaignRepository = campaignRepository;
    }

    async execute() {
        const category = await this.campaignRepository.getCategory();
        return category;
    }
}
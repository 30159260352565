import React, { useEffect, useMemo, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SquareInfo from "components/SquareInfo";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Student, {
  FinancialInfo,
  FinancialPeriodData,
} from "_domain/student/entities/Student";
import Table, { TableProps } from "components/Table";
import { motion } from "framer-motion";
import Typography from "@material-ui/core/Typography";
import financial_alert from "assets/images/financial_alert.png";
import { Controller, useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import Spinner from "../../../../components/Spinner";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import useCases from "../../../../_domain";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto auto",
    alignItems: "flex-end",
    gridGap: 8,
  },
  table: {
    backgroundColor: "#f9fbff",
    marginTop: 16,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    overflow: "hidden",
  },
  tableOthers: {},
  academic_alert: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#748e98",
    padding: "100px",
    "& img": {
      marginTop: 40,
      height: 80,
    },
  },
}));

const PEOPLESOFT_URL =
  "http://campus.inlearning.edu.pe/psp/CS90PRD/EMPLOYEE/HRMS/c/MAINTAIN_CUSTOMERS.ACCOUNT_VW.GBL?PORTALPARAM_PTCNAV=HC_ACCOUNT_VW_GBL4&EOPP.SCNode=HRMS&EOPP.SCPortal=EMPLOYEE&EOPP.SCName=HCCC_MANAGE_STUDENT_FINANCIALS&EOPP.SCLabel=Finanzas%20del%20Alumnado&EOPP.SCPTfname=HCCC_MANAGE_STUDENT_FINANCIALS&FolderPath=PORTAL_ROOT_OBJECT.HCCC_MANAGE_STUDENT_FINANCIALS.HC_ACCOUNT_VW_GBL4&IsFolder=false";

type AccountStatusProps = {
  student: Student;
};

type AccountDetailStatusProps = {
  financial: FinancialInfo;
  student: Student;
};

function AccountStatusDetail(props: AccountDetailStatusProps) {
  const classes = useStyles();
  const { financial, student } = props;

  const [loading, setLoading] = useState(false);
  const [dataPeriodsCached, setDataPeriodsCached] = useState({
    [financial.lastPeriod.code]: financial.lastPeriod.data,
  });

  const [dataPeriod, setDataPeriod] = useState(financial.lastPeriod.data);

  const { control, watch } = useForm<{ period: string }>({
    defaultValues: {
      period: financial.lastPeriod.code,
    },
  });

  const watchPeriod = watch("period");

  useEffect(() => {
    if (dataPeriodsCached[watchPeriod]) {
      setDataPeriod(dataPeriodsCached[watchPeriod]);
    } else {
      setLoading(true);
      useCases.get_financial_data
        .execute(student.id, watchPeriod)
        .then((data) => {
          setLoading(false);
          setDataPeriodsCached((d) => {
            const tmp = { ...d };
            tmp[watchPeriod] = data;
            return tmp;
          });
          setDataPeriod(data);
        });
    }
  }, [watchPeriod]);

  function savePeriodOnCache(period: string, data: FinancialPeriodData) {}

  const tableProps: TableProps = {
    data: dataPeriod.items.map((item) => item.object),
    headers: [
      { label: "Cuotas del ciclo", accessor: "description" },
      { label: "Monto", accessor: "amount" },
      {
        label: "Descuento",
        accessor: "discount",
        renderCell: (index, value) => (
          <Typography variant="body2" style={{ color: "#43c889" }}>
            {value}
          </Typography>
        ),
      },
      {
        label: "Mora",
        accessor: "mora",
        renderCell: (index, value) => (
          <Typography variant="body2" style={{ color: "#ff8389" }}>
            {value}
          </Typography>
        ),
      },
      { label: "A pagar", accessor: "total_amount" },
      { label: "F. de vencimiento", accessor: "due_date" },
      { label: "F. de pago", accessor: "upd_date" },
    ],
  };

  const tablePropsOthers: TableProps = {
    data: dataPeriod.otherItems.map((item) => item.object),
    headers: [
      { label: "Otros", accessor: "description" },
      { label: "Monto", accessor: "amount" },
      {
        label: "Descuento",
        accessor: "discount",
        renderCell: (index, value) => (
          <Typography variant="body2" style={{ color: "#43c889" }}>
            {value}
          </Typography>
        ),
      },
      { label: "A pagar", accessor: "total_amount" },
      { label: "F. de vencimiento", accessor: "due_date" },
      { label: "F. de pago", accessor: "upd_date" },
    ],
  };

  const isEmpty = useMemo(() => dataPeriod.items.length <= 0, [dataPeriod]);
  const isEmptyOther = useMemo(() => dataPeriod.otherItems.length <= 0, [
    dataPeriod,
  ]);

  const totalDebt = useMemo(() => {
    let total = 0;
    dataPeriod.items.forEach((d) => (total = d.total_amount + total));
    dataPeriod.otherItems.forEach((d) => (total = d.total_amount + total));
    return total.toFixed(2);
  }, [dataPeriod]);

  const quantityPays = useMemo(() => {
    return (
      dataPeriod.items.filter((i) => i.is_pending).length +
      dataPeriod.otherItems.filter((i) => i.is_pending).length
    );
  }, [dataPeriod]);

  return (
    <motion.div initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
      <div className={classes.header}>
        <div>
          <Typography variant="h6">Elegir ciclo</Typography>
          <Controller
            as={
              <Select
                disabled={loading}
                autoWidth={false}
                data-cy="selector"
                IconComponent={
                  loading
                    ? () => (
                        <div style={{ margin: "0 8px" }}>
                          <Spinner color="disabled" size={14} />
                        </div>
                      )
                    : ArrowDropDown
                }
              >
                {renderOptions(financial.periods)}
              </Select>
            }
            displayEmpty
            name="period"
            rules={{ required: true }}
            variant="outlined"
            control={control}
          />
        </div>
        <div>
          <SquareInfo
            data-cy="squareinfo_total_debt"
            label="Deuda total"
            value={`S/ ${totalDebt}`}
          />
        </div>
        <div>
          <SquareInfo
            data-cy="squareinfo_financial_length"
            label="Pagos pendientes"
            value={quantityPays.toString()}
          />
        </div>
        <Link data-cy="go_peoplesoft" href={PEOPLESOFT_URL} target="_blank">
          <Button variant="contained" color="primary">
            Ir a Peoplesoft
          </Button>
        </Link>
      </div>
      <div className={classes.table}>
        {!isEmpty && <Table data-cy="table_account_status" {...tableProps} />}
        {!isEmptyOther && (
          <div className={classes.tableOthers}>
            <Table data-cy="table_account_status_other" {...tablePropsOthers} />
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default function AccountStatus(props: AccountStatusProps) {
  const { student } = props;
  const classes = useStyles();

  if (!student.financialInfo)
    return (
      <motion.div
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <div className={classes.header}>
          <div></div>
          <div></div>
          <div></div>
          <Link data-cy="go_peoplesoft" href={PEOPLESOFT_URL} target="_blank">
            <Button variant="contained" color="primary">
              Ir a Peoplesoft
            </Button>
          </Link>
        </div>
        <div className={classes.table}>
          <div className={classes.academic_alert}>
            <Typography variant="subtitle1" data-cy="empty_message">
              El alumno, actualmente, no cuenta con montos pendientes de pago.
            </Typography>
            <img src={financial_alert} alt="financial alert" />
          </div>
        </div>
      </motion.div>
    );

  return (
    <AccountStatusDetail financial={student.financialInfo} student={student} />
  );
}

function renderOptions(options: Array<{ code: string; name: string }>) {
  return options.map((option) => (
    <MenuItem key={option.code} value={option.code}>
      {option.name}
    </MenuItem>
  ));
}

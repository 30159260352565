import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router-dom";
import SearchView from "./SearchView";
import Visor360 from "./visor-360";
import { useSession } from "hooks/use-auth";
import { ErrorBoundary } from "components/ErrorBoundary";
import error_service from "assets/images/error_service.png";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import CampaignMnt from "./campaign";
import FormAttention from "./campaign/FormAttention";
import FormHabilitar from "./campaign/FormHabilitar";
import AlertsMngmt from "./alerts";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 80,
    alignItems: "center",
    "& img": {
      marginBottom: 16,
    },
  },
  content: {
    width: 300,
    textAlign: "center",
  },
  action1: {
    marginBottom: 16,
    marginTop: 16,
  },
  action2: {},
}));

export default function InboundLayout() {
  const match = useRouteMatch();
  const { session } = useSession();

  if (!session) return null;

  if (session.isSupervisor) {
    return (
      <Switch>
        <Route
          path={`${match.path}/student/:id`}
          children={
            <ErrorBoundary fallbackComponent={<ErrorPage />}>
              <Visor360 />
            </ErrorBoundary>
          }
        />
        <Route
          path={`${match.path}/search`}
          children={<SearchView session={session} />}
        />
        <Route
          path={`${match.path}/campaignMnt`}
          children={<CampaignMnt session={session} />}
        />
        <Route
          path={`${match.path}/campaignMntFormAttention`}
          children={<FormAttention session={session} />}
        />
        <Route
          path={`${match.path}/campaignMntFormHabilitar`}
          children={<FormHabilitar session={session} />}
        />
        <Route path={`${match.path}/alerts`} children={<AlertsMngmt />} />
        <Redirect to={`${match.path}/search`} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route
          path={`${match.path}/student/:id`}
          children={
            <ErrorBoundary fallbackComponent={<ErrorPage />}>
              <Visor360 />
            </ErrorBoundary>
          }
        />
        <Route
          path={`${match.path}/search`}
          children={<SearchView session={session} />}
        />
        <Redirect to={`${match.path}/search`} />
      </Switch>
    );
  }
}

export function ErrorPage() {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <img src={error_service} />
      <div className={classes.content}>
        <Typography variant="h3">¡Ooops!</Typography>
        <Typography variant="h3">Ocurrió un problema...</Typography>
        <div className={classes.action1}>
          <Button
            onClick={() => window.location.reload()}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
          >
            Vuelve a intentarlo
          </Button>
        </div>
        <div className={classes.action2}>
          <Button
            fullWidth
            size="large"
            color="primary"
            onClick={() => history.goBack()}
          >
            Buscar otro alumno
          </Button>
        </div>
      </div>
    </div>
  );
}

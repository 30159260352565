import AlertTagRepositoryApi from "../infrastructure/AlertTagRepositoryApi";
import PostAlertUseCase from "./PostAlertUseCase";

const alertRepositoryApi = new AlertTagRepositoryApi();

const alertTagUseCases = {
  postAlerts: () => new PostAlertUseCase(alertRepositoryApi),
};

export default alertTagUseCases;

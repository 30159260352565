import React from "react";
import Section from "./Section";
import { motion } from "framer-motion";

export default function SkeletonVisor() {
  return (
    <motion.div
      animate={{ opacity: [0.4, 1, 0.4] }}
      transition={{ loop: Infinity, ease: "easeInOut", duration: 1 }}
    >
      <Section>
        <PrimaryShape />
      </Section>
      <Section>
        <PrimaryShape />
      </Section>
      <Section>
        <PrimaryShape />
      </Section>
    </motion.div>
  );
}

function PrimaryShape() {
  return (
    <svg viewBox="0 0 992 256">
      <path
        fill="white"
        d="M 4 0 C 1.784 0 0 1.784 0 4 L 0 252 C 0 254.216 1.784 256 4 256 L 988 256 C 990.216 256 992 254.216 992 252 L 992 4 C 992 1.784 990.216 0 988 0 L 4 0 z M 68 32 C 87.944 32 104 48.056 104 68 C 104 87.944 87.944 104 68 104 C 48.056 104 32 87.944 32 68 C 32 48.056 48.056 32 68 32 z M 140 32 L 364 32 C 366.216 32 368 33.784 368 36 L 368 52 C 368 54.216 366.216 56 364 56 L 140 56 C 137.784 56 136 54.216 136 52 L 136 36 C 136 33.784 137.784 32 140 32 z M 140 68 L 292 68 C 294.216 68 296 69.784 296 72 L 296 76 C 296 78.216 294.216 80 292 80 L 140 80 C 137.784 80 136 78.216 136 76 L 136 72 C 136 69.784 137.784 68 140 68 z M 140 92 L 316 92 C 318.216 92 320 93.784 320 96 L 320 100 C 320 102.216 318.216 104 316 104 L 140 104 C 137.784 104 136 102.216 136 100 L 136 96 C 136 93.784 137.784 92 140 92 z M 36 140 L 956 140 C 958.216 140 960 141.784 960 144 L 960 148 C 960 150.216 958.216 152 956 152 L 36 152 C 33.784 152 32 150.216 32 148 L 32 144 C 32 141.784 33.784 140 36 140 z M 36 164 L 956 164 C 958.216 164 960 165.784 960 168 L 960 172 C 960 174.216 958.216 176 956 176 L 36 176 C 33.784 176 32 174.216 32 172 L 32 168 C 32 165.784 33.784 164 36 164 z M 36 188 L 956 188 C 958.216 188 960 189.784 960 192 L 960 196 C 960 198.216 958.216 200 956 200 L 36 200 C 33.784 200 32 198.216 32 196 L 32 192 C 32 189.784 33.784 188 36 188 z M 36 212 L 348 212 C 350.216 212 352 213.784 352 216 L 352 220 C 352 222.216 350.216 224 348 224 L 36 224 C 33.784 224 32 222.216 32 220 L 32 216 C 32 213.784 33.784 212 36 212 z "
        id="rect2"
      />
    </svg>
  );
}

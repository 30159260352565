import StudentRepository from "../infrastructure/StudentRepository";
import Student from "../entities/Student";
import { TypificationForm } from "../entities/TypificationForm";

export default class SendTypification {
  studentRepository: StudentRepository;

  constructor(studentRepository: StudentRepository) {
    this.studentRepository = studentRepository;
  }
  async execute(student: Student, data: TypificationForm): Promise<void> {
    await this.studentRepository.postTypification(student, data);
  }
}

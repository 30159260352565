import React, { useState } from "react";
import Student from "_domain/student/entities/Student";
import { motion } from "framer-motion";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import Close from "@material-ui/icons/Close";
import { CcIcon } from "@cafeta/components-react";

const useStyles = makeStyles((theme) => ({
  alert: {
    display: "grid",
    gridTemplateColumns: "32px 1fr",
    color: "white",
    padding: "10px 40px",
    fontWeight: 900,
    alignItems: "center",
  },
}));

type AlertsProps = {
  student: Student;
  openModal: boolean;
  onCloseModal(): void;
};

export default function Alerts(props: AlertsProps) {
  const { student, openModal, onCloseModal } = props;
  const classes = useStyles();

  return (
    <>
      {student.alertHighlight && (
        <div data-cy={student.alertHighlight.name}>
          <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className={classes.alert}
            style={{
              backgroundColor:
                student.alertHighlight.name === "enrollment_turn"
                  ? "#43c889"
                  : "#ff8389",
            }}
          >
            {renderIcon(student.alertHighlight.icon)}
            <Typography variant="body1">
              <FormatAlert message={student.alertHighlight.message} />
            </Typography>
          </motion.div>
        </div>
      )}
      {student.hasAlertToShow && (
        <ModalAlert
          student={student}
          open={openModal}
          onClose={() => onCloseModal()}
        />
      )}
    </>
  );
}

type ModalAlertProps = {
  open: boolean;
  onClose(): void;
  student: Student;
};

const useStylesModal = makeStyles((theme) => ({
  wrapper: {
    minWidth: 600,
  },
  close: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  content: {
    padding: "22px 32px 12px 32px",
  },
  header: {
    padding: "16px 22px",
    backgroundColor: theme.palette.error.main,
    color: "white",
    display: "grid",
    gridTemplateColumns: "32px auto 1fr 32px",
    gridGap: 8,
    alignItems: "center",
  },
  alertItem: {
    display: "grid",
    gridTemplateColumns: "32px auto",
    gridGap: 16,
    margin: "20px 0",
    alignItems: "center",
  },
}));

function renderIcon(name: string | null | undefined) {
  switch (name) {
    case "lock":
      return <CcIcon id="lock" name="lock" />;
    case "user":
      return <CcIcon id="user" name="user" />;
    case "star":
      return <CcIcon id="star" name="star" />;
    case "exclamation":
      return <CcIcon id="alert-circle" name="alert-circle" />;
    case "info":
      return <CcIcon id="info" name="info" />;
    case "calendar":
      return <CcIcon id="calendar" name="calendar" />;
    default:
      return <CcIcon id="info" name="info" />;
  }
}

function ModalAlert(props: ModalAlertProps) {
  const classes = useStylesModal();
  const alertHighlight = props.student.alertHighlight;
  const otherAlerts = props.student.otherAlerts;

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
      <div className={classes.wrapper}>
        {!alertHighlight && (
          <div className={classes.close}>
            <IconButton
              id="close_alert_dialog"
              size="small"
              onClick={() => props.onClose()}
            >
              <Close color="primary" />
            </IconButton>
          </div>
        )}

        {alertHighlight && (
          <div
            data-cy={`${alertHighlight.name}`}
            className={classes.header}
            style={{
              backgroundColor:
                alertHighlight.name === "enrollment_turn"
                  ? "#43c889"
                  : "#ff8389",
            }}
          >
            {renderIcon(alertHighlight.icon)}
            <Typography id="title_highlight" variant="h4">
              {alertHighlight.message.map((part) =>
                part.format === "datetime" ? "" : part.text + " "
              )}
            </Typography>
            <Typography
              variant="body2"
              style={{ fontSize: 16, color: "white" }}
            >
              {alertHighlight.name === "enrollment_turn" &&
                formatDate(
                  new Date(
                    alertHighlight.message.find(
                      (part) => part.format === "datetime"
                    )?.text || ""
                  )
                )}
            </Typography>
            <IconButton
              id="close_alert_dialog"
              size="small"
              onClick={() => props.onClose()}
            >
              <Close style={{ color: "white" }} />
            </IconButton>
          </div>
        )}
        <div data-cy="other_alerts" className={classes.content}>
          {otherAlerts &&
            otherAlerts.map((alert) => (
              <div
                className={classes.alertItem}
                style={{
                  color: alert.type === "alert" ? "#ff8389" : "#748e98",
                }}
              >
                {renderIcon(alert.icon)}
                <Typography
                  variant="body2"
                  style={{
                    color: alert.type === "alert" ? "#ff8389" : "#748e98",
                  }}
                >
                  <FormatAlert message={alert.message} />
                </Typography>
              </div>
            ))}
        </div>
      </div>
    </Dialog>
  );
}

function FormatAlert(props: {
  message: Array<{ format: string; text: string }>;
}) {
  return (
    <span>
      {props.message.map((part) => {
        if (part.format === "datetime") {
          return formatDate(new Date(part.text));
        }
        if (part.format === "bold") {
          return <b>{part.text + " "}</b>;
        }
        return <span>{part.text + " "}</span>;
      })}
    </span>
  );
}

const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, "0");
const formatDate = (date: Date) => {
  const month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return (
    <span data-cy={"date_formated"}>{`${date.getDate()} de ${
      month[date.getMonth()]
    } de ${date.getFullYear()} a las ${zeroPad(date.getHours(), 2)}:${zeroPad(
      date.getMinutes(),
      2
    )} hrs.`}</span>
  );
};

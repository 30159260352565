import React, { useEffect, useMemo, useState } from "react";
import Drawer from "../../../components/Drawer";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "../../../components/Button";
import { Controller, useForm } from "react-hook-form";
import InputContainer from "../../../components/InputContainer";
import Session from "_domain/session/entities/SessionEntity";
import { useHistory } from "react-router-dom";
import ModalResponse from "components/ModalResponse";
import { useSession } from "hooks/use-auth";
import Spinner from "components/Spinner";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  modal: {
    padding: "20px 60px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  modalContent: {
    width: 300,
    textAlign: "center",
  },
}));

type TipificationDrawerProps = {
  open: boolean;
  onClose(): void;
  onSubmit(data: FormData): Promise<void>;
  tryAgain(): void;
  session: Session;
};

export type FormData = {
  channel: string;
  attention_type: string;
  subcategory: { value: string; label: string; category: string } | null;
  observations: string;
  result_call: string;
};

export default function TipificationDrawer(props: TipificationDrawerProps) {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useSession();
  const session = props.session;
  const [typificationStatus, setTypificationStatus] = useState<
    "success" | "error" | null
  >(null);

  const { handleSubmit, control, formState, reset, errors, watch, setValue } =
    useForm<FormData>({
      defaultValues: {
        channel: session.channelSelected,
        attention_type: filterTypeOptions(session.channelSelected)[0]?.value,
        result_call: session.forms.inbound.result_call.options[0]?.value,
        subcategory: null,
      },
      mode: "onChange",
    });

  function filterTypeOptions(channelValue: string) {
    const channel = session.forms.inbound.channel.options.find(
      (c) => c.value === channelValue
    );
    if (!channel) return [];
    return session.forms.inbound.attention_type.options.filter(
      (typeAttention) => {
        return !!channel.type_attention.find((o) => o === typeAttention.value);
      }
    );
  }

  const channelSelected = watch("channel");

  const attention_type_options = useMemo(() => {
    const options = filterTypeOptions(channelSelected);
    return options;
  }, [channelSelected]);

  useEffect(() => {
    setValue("attention_type", attention_type_options[0]?.value, true);
  }, [attention_type_options]);

  const attentionTypeSelected = watch("attention_type");

  const subcategory_options = useMemo(() => {
    setValue("subcategory", null, true);

    const isFilterSubcategory =
      session.forms.inbound.attention_type.options.find(
        (ta) => ta.value === attentionTypeSelected
      )?.filter_subcategory;
    if (isFilterSubcategory) {
      return session.forms.inbound.subcategory.options.filter(
        (subcat) => subcat.type_attention === attentionTypeSelected
      );
    } else {
      return session.forms.inbound.subcategory.options.filter(
        (subcat) => !subcat.type_attention
      );
    }
  }, [attentionTypeSelected]);

  async function onSubmit(data: FormData): Promise<void> {
    try {
      await props.onSubmit(data);
      setTypificationStatus("success");
      setTimeout(() => {
        setTypificationStatus(null);
        history.push("/dashboard/inbound/search");
      }, 3000);
    } catch (e) {
      props.onClose();
      setTypificationStatus("error");
      if (e.name === "AuthorizationError") return logout();
    }
  }

  function renderOptions(
    options: Array<{ label: string; value: string }>,
    placeholder?: string
  ) {
    return options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }

  const actionOnErrorTypification = {
    label: "Intentar otra vez",
    onClick: () => {
      setTypificationStatus(null);
      props.tryAgain();
    },
  };

  return (
    <div>
      <ModalResponse
        variant="error"
        action={actionOnErrorTypification}
        open={typificationStatus === "error"}
        title="La atención no logró registrarse correctamente."
        onClose={() => setTypificationStatus(null)}
      />
      <ModalResponse
        variant="success"
        open={typificationStatus === "success"}
        title="La atención se registró exitosamente."
        onClose={() => setTypificationStatus(null)}
      />

      <Drawer
        title="Registrar atención"
        open={props.open}
        onClose={() => props.onClose()}
      >
        <form className={classes.wrapper} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h6">(*) Son campos obligatorios</Typography>

          <InputContainer label="CANAL">
            <Controller
              as={
                <Select>
                  {renderOptions(session.channelOptionsAvailable)}
                </Select>
              }
              error={errors.channel}
              name="channel"
              rules={{ required: true }}
              fullWidth
              variant="outlined"
              control={control}
            />
          </InputContainer>

          <InputContainer label="Tipo de atención">
            <Controller
              as={
                <Select>
                  {renderOptions(attention_type_options, "Tipo de atención")}
                </Select>
              }
              error={errors.attention_type}
              name="attention_type"
              rules={{ required: true }}
              fullWidth
              variant="outlined"
              control={control}
            />
          </InputContainer>

          <InputContainer label="Motivo de atención">
            <Controller
              name="subcategory"
              control={control}
              rules={{ validate: (value) => !!value }}
              onChange={([event, data]) => {
                return data;
              }}
              as={
                <Autocomplete
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Ingrese el motivo de atención"
                      variant="outlined"
                    />
                  )}
                  options={subcategory_options}
                  noOptionsText="No se encontraron elementos"
                  getOptionLabel={(option) => option.label}
                />
              }
            />
          </InputContainer>

          {channelSelected === "llamada" && (
            <InputContainer label="Resultado de la llamada">
              <Controller
                as={
                  <Select placeholder="Resultado de la llamada">
                    {renderOptions(session.forms.inbound.result_call.options)}
                  </Select>
                }
                error={errors.result_call}
                rules={{ required: true }}
                name="result_call"
                fullWidth
                variant="outlined"
                control={control}
              />
            </InputContainer>
          )}

          <InputContainer label="OBSERVACIONES">
            <Controller
              multiline
              rows={4}
              placeholder="Ingresa aqui tus comentarios / observaciones"
              error={errors.observations}
              as={<TextField inputProps={{ maxLength: 1000 }} />}
              rules={{ maxLength: 1000 }}
              name="observations"
              fullWidth
              variant="outlined"
              control={control}
            />
          </InputContainer>

          <Button
            data-cy="submit_typification"
            fullWidth
            startIcon={formState.isSubmitting && <Spinner color="disabled" />}
            disabled={formState.isSubmitting || !formState.isValid}
            type="submit"
            color="primary"
            variant="contained"
            size="large"
          >
            Guardar Datos
          </Button>
        </form>
      </Drawer>
    </div>
  );
}
